<template>
    <span>
        <v-badge
            :content="signable_appointments_count === 0 ? '' : signable_appointments_count"
            :color="signable_appointments_count === 0 ? 'green' : 'red'"
            overlap>
            <v-btn small icon @click="toggle_signable_filter" :outlined="signable_filter_active" :elevation="signable_filter_active ? 3 : 0">
                <v-icon>mdi-fountain-pen-tip</v-icon>
            </v-btn>
        </v-badge>

        <v-btn small icon @click="show_student_placements" class="ml-6">
            <v-icon>mdi-account</v-icon>
        </v-btn>

        <v-dialog
            v-model="main_dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="main_dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Bewilligungen</v-toolbar-title>
            </v-toolbar>
            <v-card tile>
                <v-col>
                    <bewilligungsuebersicht></bewilligungsuebersicht>
                </v-col>
            </v-card>
        </v-dialog>
    </span>
</template>

<style lang="scss">
    .v-application {
        .v-toolbar {
            .v-btn {
                position: relative;
                z-index: 20;
            }
        }
    }
</style>

<script>
import EventBus from '../main';

import bewilligungsuebersicht from '../components/bewilligungsuebersicht';

export default {
    components : {
        bewilligungsuebersicht
    },
    data : () => ({
        main_dialog : false,

        signable_appointments_count : 0,
        signable_filter_active : false
    }),
    mounted () {
        EventBus.$on('setSignableAppointments', (signable_appointments_count) => { // catch desired data from an sender after we requested data
            this.signable_appointments_count = signable_appointments_count
        })

        // catch reset-this-component request event
        EventBus.$on('resetFilterSignableAppointments', () => {
            this.signable_filter_active = false
        })

        // if any other places toggle the filter - catch that request and save what state has been set
        EventBus.$on('toggle-signable-filter', (active_state) => {
            this.signable_filter_active = active_state
        })
    },
    methods : {
        toggle_signable_filter () {
            if(this.signable_appointments_count === 0)
                return
            EventBus.$emit('toggle-signable-filter', !this.signable_filter_active)
        },
        show_student_placements () {
            this.main_dialog = true
        },
        validateAndSubmit () {

        }
    },
}
</script>
