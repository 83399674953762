<template>
    <div>
        <generic-filter-nav></generic-filter-nav>
        <parent-extra-nav v-if="isParentMode"></parent-extra-nav>
    </div>
</template>

<script>
import _ from 'lodash'

import generic_filter_nav from './generic-filter-nav'
import parent_filter_nav from './parent-extra-nav'

export default {
    components: {
        'generic-filter-nav' : generic_filter_nav,
        'parent-extra-nav' : parent_filter_nav,
    },
    computed : {
        isParentMode() {
            return _.get(this.$root, 'userInfo.is_family', null)
        }
    }
}
</script>
