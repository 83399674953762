<template>
    <div>
        <v-container v-if="invoice_found !== true">
            <div v-if="invoice_found === null" class="text-center mt-5">
                Lade Daten ...
            </div>
            <v-alert v-else-if="!invoice_found" type="error">
                <b>Diese Rechnung kann nicht zugegriffen werden.</b>
                <br />
                Entweder fehlt Ihnen die entsprechende Berechtigung oder die
                Rechnung existiert nicht.
            </v-alert>
        </v-container>

        <!--<template v-else>-->
        <v-form v-else lazy-validation ref="form">
            <v-container class="mb-2">
                <v-row>
                    <v-col>
                        <h3 class="text-center">Details der Rechnung</h3>
                        <hr />
                        <h4>
                            {{
                                moment()
                                    .month(invoice.month - 1)
                                    .year(invoice.year)
                                    .format('MMM Y')
                            }}
                        </h4>
                        <template v-if="isAppAdmin">
                            {{ invoice.teacher.code }}
                            {{ invoice.teacher.first_name }}
                            {{ invoice.teacher.last_name }}
                        </template>
                    </v-col>
                </v-row>
            </v-container>

            <v-container id="invoice-detail-table">
                <!-- Benzin & Bonus -->
                <v-row>
                    <v-col>
                        <!-- Benzin -->
                        <v-text-field
                            v-if="isAppAdmin"
                            outlined
                            :rules="inputRules.isCurrency"
                            append-icon="mdi-currency-eur"
                            dense
                            hide-details="auto"
                            v-model="invoice.fuel_payout"
                        >
                        </v-text-field>
                        <b v-else-if="parseInt(invoice.fuel_payout || 0) !== 0"
                            >{{ invoice.fuel_payout | formatCurrency }} €</b
                        >
                        <i v-else>-</i>
                    </v-col>
                    <v-col>
                        <!-- Bonus -->
                        <v-text-field
                            v-if="isAppAdmin"
                            :rules="inputRules.isCurrency"
                            append-icon="mdi-currency-eur"
                            hide-details="auto"
                            dense
                            outlined
                            v-model="invoice.bonus"
                        >
                        </v-text-field>
                        <b v-else-if="parseInt(invoice.bonus || 0) !== 0"
                            >{{ invoice.bonus | formatCurrency }} €</b
                        >
                        <i v-else>-</i>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>Benzin</v-col>
                    <v-col>Bonus</v-col>
                </v-row>
                <!-- END: Benzin & Bonus -->

                <!-- Endsumme & Status -->
                <v-row class="mt-5 pt-2" style="background-color: #f5f5f5;">
                    <v-col>
                        <!-- Endsumme -->
                        <b v-if="parseInt(invoice.total || 0) !== 0">
                            {{ invoice.total | formatCurrency }} €
                        </b>
                        <i v-else>-</i>
                    </v-col>
                    <v-col>
                        <b>-</b>
                    </v-col>
                </v-row>
                <v-row class="mb-5 pb-2" style="background-color: #f5f5f5">
                    <v-col>Endsumme</v-col>
                    <v-col>Status</v-col>
                </v-row>
                <!-- END: Endsumme & Status -->

                <!-- Auszahlung & Auszahlungs-Datum -->
                <v-row>
                    <v-col>
                        <!-- Auszahlung -->
                        <v-text-field
                            v-if="isAppAdmin"
                            :rules="inputRules.isCurrency"
                            outlined
                            append-icon="mdi-currency-eur"
                            dense
                            hide-details="auto"
                            v-model="invoice.payout"
                        >
                        </v-text-field>
                        <b v-else-if="parseInt(invoice.payout || 0) !== 0"
                            >{{ invoice.payout | formatCurrency }} €</b
                        >
                        <i v-else>-</i>
                    </v-col>
                    <v-col>
                        <!-- Auszahlungs-Datum -->
                        <v-dialog
                            v-if="isAppAdmin"
                            ref="datePickerDialog"
                            v-model="datePickerDialog"
                            :return-value.sync="invoice.payout_date"
                            persistent
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div class="position-relative">
                                    <v-text-field
                                        append-icon="mdi-calendar-month-outline"
                                        outlined
                                        dense
                                        readonly
                                        hide-details="auto"
                                        v-bind="attrs"
                                        v-on="on"
                                        :value="dateFormatted"
                                        required
                                    >
                                    </v-text-field>
                                    <v-btn
                                        v-if="invoice.payout_date !== null"
                                        color="red"
                                        dark
                                        fab
                                        x-small
                                        style="position: absolute; right: -15px; top: 50%; transform: translateY(-50%); height: 20px; width: 20px;"
                                        @click="invoice.payout_date = null"
                                    >
                                        x
                                    </v-btn>
                                </div>
                            </template>
                            <v-date-picker
                                locale="de-de"
                                v-model="invoice.payout_date"
                                scrollable
                            >
                                <div style="width: 100%">
                                    <div class="d-flex">
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="datePickerDialog = false"
                                        >
                                            Abbrechen
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="
                                                $refs.datePickerDialog.save(
                                                    invoice.payout_date
                                                )
                                            "
                                        >
                                            Speichern
                                        </v-btn>
                                    </div>
                                </div>
                            </v-date-picker>
                        </v-dialog>
                        <b
                            v-else-if="
                                (invoice.payout_date || '').trim() !== '' &&
                                    invoice.year
                            "
                        >
                            {{
                                moment(invoice.payout_date).format(
                                    moment_date_format
                                )
                            }}
                            {{ invoice.year }}
                        </b>
                        <i v-else>-</i>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>Auszahlung</v-col>
                    <v-col>Auszahlungsdatum</v-col>
                </v-row>
                <!-- END: Auszahlung & Auszahlungs-Datum -->
            </v-container>

            <hr class="ml-3 mr-3 mt-5" />
            <!-- 
            ! Ibrahim: disabling the 'invoive-accepted' feacher because it's buggy
            <v-container>
                <div class="d-flex" style="align-items: center">
                    <template v-if="!invoice.accepted">
                        <span>Diese Rechnung jetzt bestätigen:</span>
                        <v-spacer></v-spacer>
                        <v-checkbox dense hide-details v-model="accept_dialog" class="mt-0 pt-0"></v-checkbox>
                    </template>
                    <template v-else>
                        <span>Diese Rechnung wurde bereits bestätigt.</span>
                        
                    </template>
                </div>
            </v-container>

            <v-dialog v-model="accept_dialog" persistent>
                <v-card>
                    <v-card-title class="d-block pb-0" style="line-height: normal; padding-left: 5px; padding-right: 5px">
                        <v-col class="pt-0 pb-0">
                            Rechnung bestätigen
                        </v-col>
                    </v-card-title>
                    <v-card-text class="pt-0">
                        <v-col class="pt-0 pb-0">
                            Hiermit bestätige ich die Vollständigkeit und Richtigkeit
                            meiner Termine für <b>{{moment().month(invoice.month-1).format('MMM')}} {{invoice.year}}</b>.
                            <br>
                            Mir ist bewusst,
                            dass erst nach meiner Bestätigung die Bearbeitung der Rechnung
                            erfolgen kann und das Anlegen weiterer Termine für den o.g. Monat
                            nicht mehr möglich ist.
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red darken-1"
                            text
                            @click="accept_dialog = false">
                            Abbrechen
                        </v-btn>
                        <v-btn
                            color="green lighten-1"
                            text
                            @click="acceptInvoice">
                            Bestätigen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog> -->

            <!--</template>-->
        </v-form>

        <v-footer app>
            <!--<v-btn color="red lighten-2" dark fab small :to="`/lkrechnungen/${invoice.year}-${invoice.month}`"><v-icon>mdi-arrow-left</v-icon></v-btn>-->
            <v-btn color="red lighten-2" dark fab small to="/lkrechnungen"
                ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="green lighten-2" fab small dark @click="save(null)">
                <v-icon>mdi-check</v-icon>
            </v-btn>
        </v-footer>
    </div>
</template>

<style lang="scss">
#invoice-detail-table {
    .col {
        padding-top: 0;
        padding-bottom: 0;
    }
}
</style>

<script>
import axios from 'axios';
import config from '@/config';
import _ from 'lodash';
import moment from 'moment';

export default {
    data() {
        return {
            invoice: null,
            invoice_found: null,

            secure_accept_invoice: false,

            accept_dialog: false,
            datePickerDialog: false,

            inputRules: {
                isCurrency: [
                    value => {
                        // allow empty input or a number format like 123.321 OR 123,321 (while the first number before the delimiter may have one to four digits and the one after may have one to two digits). In addition the delimiter and the digits after are optional
                        const pattern = /^\d{1,4}(?:[.,]{1}\d{1,2})?$/;
                        return (
                            ('' + value).trim() === '' ||
                            value === null ||
                            pattern.test(value) ||
                            'Das ist keine gültige Währungsangabe'
                        );
                    },
                ],
            },
        };
    },
    computed: {
        isAppAdmin() {
            return _.get(this.$root, 'userInfo.is_app_admin', null);
        },
        dateFormatted() {
            return this.invoice.payout_date
                ? moment(this.invoice.payout_date).format(
                      this.moment_date_format
                  )
                : '';
        },
    },
    mounted() {
        this.$root.globalsReady.then(() => {
            axios
                .get(
                    `${config.backend_APP_api_url}/teachers/invoices/` +
                        _.get(
                            this.$router,
                            'currentRoute.params.invoiceId',
                            null
                        )
                )
                .then(res => {
                    this.invoice = res.data;
                    this.invoice_found = true;
                })
                .catch(error => {
                    this.invoice_found = false; //error.response.status !== 404
                });
        });
    },
    methods: {
        acceptInvoice() {
            this.accept_dialog = false;
            this.save(true);
        },
        save(accept = null) {
            if (this.$refs.form.validate()) {
                const data = _.merge(_.clone(this.invoice), {
                    accepted: accept,
                }); // Note: it seems without the clone the original data is manipulated and therefor this view shows the "unaccepted" texts until the server responses
                axios
                    .post(
                        `${config.backend_APP_api_url}/teachers/invoices/` +
                            _.get(
                                this.$router,
                                'currentRoute.params.invoiceId',
                                null
                            ),
                        data
                    )
                    .then(res => {
                        this.showSnackbar(
                            'Die Rechnungsdetails wurden' +
                                (accept ? ' bestätigt und' : '') +
                                ' gespeichert.',
                            2
                        );
                        this.invoice = res.data;
                    });
            } else this.showSnackbar('Bitte überprüfen Sie Ihre Eingaben.', 2);
        },
    },
    filters: {
        formatCurrency(val) {
            return (Math.round(val * 100) / 100).toFixed(2);

            /*
            return (new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
            })).format(this.appointment.teacher_payout)
             */
        },
    },
};
</script>
