import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import {router} from "../main.js"

import config from '@/config'

import Cookies from 'js-cookie'

Vue.use(Vuex)
Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.baseURL = config.backend_APP_api_url;
const access_token = Cookies.get('access_token')
if (access_token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
}

import EventBus from '../main';

export default new Vuex.Store({
    state: {
        status: '',
        msg : '',
        access_token: Cookies.get('access_token', ''),
        user: {}
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, access_token, user) {
            state.status = 'success'
            state.access_token = access_token
            state.user = user
        },
        auth_error(state, msg) {
            state.status = 'error'
            state.msg = msg
        },
        logout(state) {
            state.status = ''
            state.access_token = ''
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios.post('/login', user)
                    .then(resp => {
                        const access_token = resp.data.access_token
                        let expires = new Date(new Date().getTime() + resp.data.expires_in * 60 * 60 * 1000);
                        Cookies.set('access_token', access_token, { expires: expires })
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
                        commit('auth_success', access_token, user)
                        resolve(resp)
                        EventBus.$emit('login-finished')

                    })
                    .catch(error => {
                        commit('auth_error', error.response.data.message)
                        localStorage.removeItem('access_token')
                        reject(error)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve, reject) => {
                commit('logout')
                Cookies.remove('access_token')
                delete axios.defaults.headers.common['Authorization']
                EventBus.$emit('logout-finished')
                resolve()
            })
        }
    },
    getters: {
        isLoggedIn: state => !!state.access_token,
        authStatus: state => state.status,
    }
})
