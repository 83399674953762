<!--
============================================================
THIS COMPONENT IS FOR MULTIPLE THINGS:
 - NEW CREATION OF APPOINTMENTS
 - EDITING EXISTING APPOINTMENTS
 - PARENT & TEACHER SIGNATURES VIA TOKEN
============================================================

Signature Pad: https://github.com/WangShayne/vue-signature#readme (which is a vue wrapper component for https://github.com/szimek/signature_pad)
-->

<template>
    <v-container v-if="isNewAppointmentMode && isFamily">
        <v-alert type="error">
            Sie sind <b>nicht berechtigt</b> neue Termine anzulegen.
        </v-alert>
    </v-container>
    <v-container v-else-if="appointment_found !== true">
        <div
            v-if="signature_expired"
            class="text-center mt-5 text-customer-red"
        >
            <b style="font-size: 2em">
                Der von Ihnen aufgerufene Link für die Unterschrift ist leider
                abgelaufen.
            </b>
        </div>
        <div v-else-if="appointment_found === null" class="text-center mt-5">
            Lade Daten für die Bearbeitung...
        </div>
        <v-alert v-else-if="!hasTeacherSignatures" type="error">
            <b>Die Unterschrift kann momentan nicht gespeichert werden.</b>
            <br />
            Der Status des Termins wurde zwischenzeitlich geändert. Bitte
            erfragen Sie einen neuen Link um Ihre Unterschrift abzuleisten.
        </v-alert>
        <v-alert v-else-if="!appointment_found" type="error">
            <b>Die Bearbeitung dieses Termin ist nicht möglich.</b>
            <br />
            Entweder fehlt Ihnen die entsprechende Berechtigung oder der Termin
            existiert nicht.
        </v-alert>
        <!--<v-alert v-else-if="isParentEditingAppointmentMode" type="error">
            <b>Sie können keine neuen Termine anlegen.</b>
        </v-alert>-->
    </v-container>
    <v-form
        v-else
        v-model="valid"
        lazy-validation
        ref="form"
        :class="{
            isNew: isNewAppointmentMode,
            isEdit: isTeacherEditingAppointmentMode,
            isSignature: isGenericSignatureMode,
        }"
    >
        <v-container class="mt-3 position-relative">
            <div id="status-action-bar">
                <!--<div v-if="isParentSignatureMode || isTeacherEditingAppointmentMode" class="dev-only" style="opacity: 0.5; font-size: 0.8em">
                    *DEV* Termin-ID: {{getAppointmentId()}} | Family-ID: {{getFamily().id}}
                </div>-->
                <template v-if="isTeacherEditingAppointmentMode">
                    {{ getResolvedAppointmentTypeLabel(appointment.type) }} mit
                    Familie {{ getFamily().last_name }}
                </template>
                <template
                    v-else-if="
                        isParentSignatureMode || isParentEditingAppointmentMode
                    "
                >
                    {{ getResolvedAppointmentTypeLabel(appointment.type) }} mit
                    {{ { W: 'Frau', M: 'Herrn' }[appointment.teacher.gender] }}
                    {{ appointment.teacher.last_name }}
                </template>
                <!-- ========================== -->
                <delete-appointment-modal
                    v-if="
                        isTeacherEditingAppointmentMode &&
                            cancellationOrDeletionAllowed
                    "
                    @deletion-done="deletionDone"
                ></delete-appointment-modal>
                <!-- ========================== -->
                <div
                    v-if="
                        (isTeacherEditingAppointmentMode ||
                            isGenericSignatureMode) &&
                            status
                    "
                    class="mb-2"
                    :style="{
                        color: getResolvedAppointmentStatus(status).color,
                    }"
                >
                    Terminstatus:
                    <v-icon style="color: inherit" class="pr-1 float-left">{{
                        getResolvedAppointmentStatus(status).icon
                    }}</v-icon>
                    {{ getResolvedAppointmentStatus(status).label }}
                    <!--<span class="dev-only" style="opacity: 0.5; font-size: 0.8em;">
                        *DEV*: Status-ID: {{status}}
                    </span>-->
                </div>
            </div>
            <!--<div v-if="!isNewAppointmentMode" style="margin-top: 40px;"></div>-->
            <!--<v-alert dense outlined type="error" v-if="isInvoiced && !isParentSignatureMode && !isTeacherSignatureMode">
                Dieser Termin befindet sich in einem bereits <strong>abgerechneten</strong> Monat - eine Verschiebung ist daher <strong>nicht möglich</strong>.
            </v-alert>-->
            <v-card class="mx-auto mb-4 px-2 py-3" outlined>
                <v-select
                    dense
                    label="Rhythmus"
                    hide-details
                    v-model="repetitive_selected"
                    :items="repetitiveItemized"
                    mandatory
                    prepend-icon="mdi-remote-desktop"
                    required
                    :disabled="
                        isTeacherEditingAppointmentMode ||
                            isGenericSignatureMode ||
                            isFamily
                    "
                    :rules="[v => !!v || 'Bitte einen Rhythmus wählen']"
                >
                </v-select>

                <v-dialog
                    ref="dialog0"
                    v-model="dialogs[0]"
                    :return-value.sync="date"
                    persistent
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :disabled="
                                isGenericSignatureMode ||
                                    !dateTimeChangeAllowed ||
                                    isFamily
                            "
                            :label="
                                repetitive_selected === 1 ||
                                repetitive_selected === null
                                    ? 'Datum'
                                    : 'Startdatum der Terminserie'
                            "
                            prepend-icon="mdi-calendar"
                            readonly
                            hide-details="auto"
                            v-bind="attrs"
                            v-on="on"
                            :value="dateFormatted"
                            required
                            :rules="[v => !!v || 'Bitte ein Datum wählen']"
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        :allowed-dates="allowedDates"
                        locale="de-de"
                        v-model="date"
                        scrollable
                    >
                        <div style="width: 100%">
                            <div
                                v-if="currently_selected_month_invoiced"
                                class="centered-floating-box text-center"
                                style="background-color: white; border: 1px solid #ccc; padding-top: 10px;"
                            >
                                <b style="color: red">
                                    Dieser Monat wurde <br />
                                    bereits abgerechnet.
                                </b>
                            </div>
                            <div
                                v-if="isTimeframeOverlappingOtherAppointments()"
                                class="text-center"
                            >
                                <b style="color: red">
                                    Für dieses Datum gibt es angesichts der
                                    gewählten Uhrzeiten
                                    {{ overlapping_appointments.length }}
                                    Kollision(en).
                                </b>
                            </div>
                            <div class="d-flex">
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="closeDialog(0)"
                                >
                                    Abbrechen
                                </v-btn>
                                <!-- :disabled="moment(date).isBefore(moment().subtract(1, 'days'))" -->
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dialog0.save(date)"
                                >
                                    Speichern
                                </v-btn>
                            </div>
                        </div>
                    </v-date-picker>
                </v-dialog>
                <template
                    v-if="
                        !(
                            repetitive_selected === 1 ||
                            repetitive_selected === null
                        )
                    "
                >
                    <small class="muted repetitive-appointment-note">
                        <i v-if="placements_selected[0] === undefined">
                            Das End-Datum der Serie erscheint hier nachdem Sie
                            einen Teilnehmer gewählt haben.
                        </i>
                        <span v-else-if="!isParentSignatureMode">
                            Ende der Termin-Serie:
                            <u>{{
                                moment(
                                    placements[placements_selected[0]].approval
                                        .end_date
                                ).format(moment_date_format + ' YYYY')
                            }}</u>
                        </span>
                    </small>
                </template>

                <v-row>
                    <v-col>
                        <v-dialog
                            ref="dialog1"
                            v-model="dialogs[1]"
                            :return-value.sync="time_start"
                            persistent
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :disabled="
                                        isGenericSignatureMode ||
                                            !dateTimeChangeAllowed ||
                                            isFamily
                                    "
                                    hide-details="auto"
                                    v-model="time_start"
                                    label="Beginn"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    required
                                    :rules="[
                                        v =>
                                            !!v ||
                                            'Bitte wählen Sie die Startzeit',
                                    ]"
                                >
                                </v-text-field>
                            </template>
                            <v-time-picker
                                v-if="dialogs[1]"
                                v-model="time_start"
                                full-width
                                format="24hr"
                                :allowed-minutes="
                                    timepickerAllowedMinutes({
                                        interval: 5,
                                        after: null,
                                        is: 'start',
                                    })
                                "
                            >
                                <div style="width: 100%">
                                    <!--<div class="text-right" v-if="moment(time_start, moment_time_format_HM).isBefore(moment())">
                                        <b style="color: red;">
                                            Die gewählte Start-Zeit liegt vor der gegenwärtigen Zeit ({{moment().format(moment_time_format_HM)}} Uhr)
                                        </b>
                                    </div>-->
                                    <div
                                        class="text-right"
                                        v-if="time_end !== null"
                                    >
                                        <b style="color: red;">
                                            Bei Änderung wird die Endzeit
                                            zurückgesetzt.
                                        </b>
                                    </div>
                                    <div class="d-flex">
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="closeDialog(1)"
                                        >
                                            Abbrechen
                                        </v-btn>
                                        <!-- :disabled="moment(time_start, moment_time_format_HM).isBefore(moment())" -->
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="
                                                $refs.dialog1.save(time_start);
                                                time_end = null;
                                            "
                                        >
                                            Speichern
                                        </v-btn>
                                    </div>
                                </div>
                            </v-time-picker>
                        </v-dialog>
                    </v-col>
                    <v-col>
                        <v-dialog
                            ref="dialog2"
                            v-model="dialogs[2]"
                            :return-value.sync="time_end"
                            persistent
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :disabled="
                                        isGenericSignatureMode ||
                                            !dateTimeChangeAllowed ||
                                            isFamily
                                    "
                                    hide-details="auto"
                                    v-model="time_end"
                                    label="Ende"
                                    prepend-icon="mdi-history"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    required
                                    :rules="[
                                        v =>
                                            !!v ||
                                            'Bitte wählen Sie die Startzeit',
                                    ]"
                                >
                                </v-text-field>
                            </template>
                            <v-time-picker
                                v-if="dialogs[2]"
                                v-model="time_end"
                                full-width
                                format="24hr"
                                :allowed-hours="timepickerAllowedHours()"
                                :allowed-minutes="
                                    timepickerAllowedMinutes({
                                        interval: 15,
                                        after: time_start,
                                        is: 'end',
                                    })
                                "
                            >
                                <div style="width: 100%">
                                    <div
                                        class="text-right"
                                        v-if="
                                            moment(
                                                time_start,
                                                moment_time_format_HM
                                            ).isAfter(
                                                moment(
                                                    time_end,
                                                    moment_time_format_HM
                                                )
                                            )
                                        "
                                    >
                                        <b style="color: red;">
                                            Die gewählte End-Zeit liegt vor der
                                            Startzeit ({{
                                                moment(
                                                    time_start,
                                                    moment_time_format_HM
                                                ).format(moment_time_format_HM)
                                            }}
                                            Uhr)
                                        </b>
                                    </div>
                                    <div
                                        class="text-right"
                                        v-if="
                                            moment(
                                                time_start,
                                                moment_time_format_HM
                                            ).isSame(
                                                moment(
                                                    time_end,
                                                    moment_time_format_HM
                                                )
                                            )
                                        "
                                    >
                                        <b style="color: red;">
                                            Die End-Zeit kann nicht gleich der
                                            Startzeit sein.
                                        </b>
                                    </div>
                                    <div
                                        v-if="
                                            isTimeframeOverlappingOtherAppointments()
                                        "
                                        class="text-center"
                                    >
                                        <b style="color: red">
                                            Durch das gerade gewählte
                                            Zeitfenster entstehen/entsteht
                                            {{
                                                overlapping_appointments.length
                                            }}
                                            Kollision(en).
                                        </b>
                                    </div>
                                    <div class="d-flex">
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="closeDialog(2)"
                                        >
                                            Abbrechen
                                        </v-btn>
                                        <v-btn
                                            :disabled="
                                                moment(
                                                    time_start,
                                                    moment_time_format_HM
                                                ).isAfter(
                                                    moment(
                                                        time_end,
                                                        moment_time_format_HM
                                                    )
                                                ) ||
                                                    moment(
                                                        time_start,
                                                        moment_time_format_HM
                                                    ).isSame(
                                                        moment(
                                                            time_end,
                                                            moment_time_format_HM
                                                        )
                                                    )
                                            "
                                            text
                                            color="primary"
                                            @click="
                                                $refs.dialog2.save(time_end)
                                            "
                                        >
                                            Speichern
                                        </v-btn>
                                    </div>
                                </div>
                            </v-time-picker>
                        </v-dialog>
                    </v-col>
                </v-row>
                <v-row
                    v-if="isTimeframeOverlappingOtherAppointments()"
                    class="mb-5"
                >
                    <v-col cols="auto" style="width: 32px;">
                        <v-icon style="color: red">mdi-alert-outline</v-icon>
                    </v-col>
                    <v-col cols="auto" style="color: red">
                        <b
                            >Dieser Termin überlagert
                            {{ overlapping_appointments.length }}
                            {{
                                overlapping_appointments.length === 1
                                    ? 'Termin'
                                    : 'Termine'
                            }}:</b
                        >
                        <small>
                            <ul>
                                <li
                                    v-for="appointment in overlapping_appointments"
                                    :key="appointment.id"
                                >
                                    {{
                                        moment(
                                            appointment.start,
                                            'h:mm'
                                        ).format(moment_time_format_HM)
                                    }}
                                    &mdash;
                                    {{
                                        moment(appointment.end, 'h:mm').format(
                                            moment_time_format_HM
                                        )
                                    }}
                                    Uhr
                                </li>
                            </ul>
                        </small>
                    </v-col>
                </v-row>

                <v-select
                    class="mt-5"
                    dense
                    label="Form"
                    hide-details="auto"
                    v-model="form_selected"
                    :items="formsItemized"
                    prepend-icon="mdi-remote-desktop"
                    required
                    :disabled="
                        isGenericSignatureMode ||
                            !presenceChangeAllowed ||
                            isFamily
                    "
                    :rules="[
                        v =>
                            v === null ? 'Bitte Unterrichtsform wählen' : true,
                    ]"
                >
                </v-select>

                <v-radio-group
                    v-if="isNewAppointmentMode"
                    v-model="location_src_select"
                    mandatory
                    hide-details
                    label="Ort / Adresse"
                >
                    <v-radio
                        label="Zuhause beim Schüler"
                        :value="locationSourceEnums['at_students_home']"
                    >
                    </v-radio>
                    <div
                        class="mb-2"
                        v-if="
                            location_src_select ===
                                locationSourceEnums['at_students_home']
                        "
                    >
                        <span v-if="studentLocation">
                            <v-icon>mdi-map-marker</v-icon>
                            {{ studentLocation }}
                        </span>
                        <small
                            v-else
                            class="muted d-block mb-1"
                            style="line-height: normal"
                        >
                            <i
                                >Die Adresse erscheint hier sobald Sie eine
                                Vermittlung gewählt haben.</i
                            >
                        </small>
                    </div>

                    <v-radio
                        label="Freitext Adresse"
                        :value="locationSourceEnums['freetext']"
                    >
                    </v-radio>
                    <div
                        v-if="
                            location_src_select ===
                                locationSourceEnums['freetext']
                        "
                    >
                        <v-text-field
                            v-model="location_free_text"
                            solo
                            label="Präzise Adresse"
                            clearable
                            hide-detailsrequired
                            :rules="[
                                v => !!v || 'Bitte hier die Adresse angeben.',
                            ]"
                        >
                        </v-text-field>
                    </div>
                </v-radio-group>
                <div v-else>
                    <v-textarea
                        hide-details
                        rows="1"
                        class="no-min-height"
                        auto-grow
                        full-width
                        label="Ort / Adresse"
                        :prepend-icon="
                            getResolvedAppointmentLocation(location_src_select)
                                .icon
                        "
                        disabled
                        :value="getAppointmentLocation()"
                    >
                    </v-textarea>
                </div>

                <small
                    v-if="isTeacherEditingAppointmentMode"
                    class="pl-8"
                    v-html="
                        getLocation(appointment, '&raquo; Auf GMaps anzeigen')
                    "
                ></small>
            </v-card>

            <span class="v-card-caption">
                Teilnehmer
                <i v-if="placements_selected.length > 1">(Gruppenunterricht)</i>
                <i v-else-if="placements_selected.length === 1"
                    >(Einzelunterricht)</i
                >
            </span>
            <v-card class="mx-auto py-5 px-2" outlined>
                <ul style="list-style: none; padding-left: 10px" class="mb-2">
                    <li
                        v-for="placement_id in placements_selected"
                        :key="'list-' + placement_id"
                    >
                        <div class="row" style="align-items: center">
                            <div
                                class="col"
                                style="padding-left: 2px; padding-right: 2px; font-weight: bold"
                            >
                                <span
                                    style="position: absolute; margin-top: -12px; opacity: 0.5; font-size: 0.6em"
                                    class="dev-only"
                                    v-if="
                                        placement_id && placements[placement_id]
                                    "
                                >
                                    *DEV* Placement ID
                                    {{ placement_id || '?' }} | Student ID
                                    {{ placements[placement_id].student.id }} |
                                    Subject ID
                                    {{ subjects_selected[placement_id] || '-' }}
                                </span>
                                {{
                                    placements[placement_id].student.first_name
                                }},
                                {{
                                    placements[placement_id].application_type
                                        .title
                                }}:
                                <br />
                                <span v-html="hoursLeft(placement_id)"></span>
                                <div
                                    style="color:red; font-weight: bold"
                                    v-if="
                                        isPlacementOverlapping(
                                            placements[placement_id]
                                        )
                                    "
                                >
                                    BELEGT
                                </div>
                            </div>
                            <div
                                class="col-6 position-relative"
                                v-if="
                                    isTeacherEditingAppointmentMode ||
                                        isGenericSignatureMode ||
                                        isParentEditingAppointmentMode
                                "
                            >
                                <v-select
                                    v-model="subjects_selected[placement_id]"
                                    :items="subjectsItemized(placement_id)"
                                    dense
                                    :class="{ 'required-marker': !isPending }"
                                    hide-details="auto"
                                    :required="!isPending"
                                    :rules="[
                                        v =>
                                            isPending
                                                ? true
                                                : !!v ||
                                                  'Bitte wählen Sie ein Fach.',
                                    ]"
                                    :disabled="
                                        isGenericSignatureMode ||
                                            !subjectsChangeAllowed ||
                                            isParentEditingAppointmentMode
                                    "
                                >
                                </v-select>
                                <v-btn
                                    v-if="
                                        subjects_selected[placement_id] !==
                                            null &&
                                            !isGenericSignatureMode &&
                                            subjectsChangeAllowed &&
                                            !isParentEditingAppointmentMode
                                    "
                                    color="red"
                                    dark
                                    fab
                                    x-small
                                    style="position: absolute; right: -8px; top: 50%; transform: translateY(-50%); height: 20px; width: 20px;"
                                    @click="
                                        subjects_selected[placement_id] = null
                                    "
                                >
                                    x
                                </v-btn>
                            </div>
                        </div>
                    </li>
                </ul>

                <v-select
                    v-if="
                        placements !== null &&
                            !isGenericSignatureMode &&
                            !isParentEditingAppointmentMode
                    "
                    v-model="placements_selected"
                    :items="placementsItemizedFiltered"
                    :disabled="!participantsChangeAllowed"
                    dense
                    multiple
                    hide-details="auto"
                    required
                    no-data-text="Keine Vermittlungen gefunden"
                    :rules="[
                        v =>
                            v.length > 0
                                ? true
                                : 'Bitte wählen Sie eine Vermittlung.',
                    ]"
                >
                    <template v-slot:selection="{ item, index }">
                        <div class="d-flex">
                            <span v-if="index === 0">
                                {{ item.student_name }},
                                <small class="muted">{{
                                    item.school_subject
                                }}</small>
                            </span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption pl-2"
                                style="float: right"
                            >
                                (+{{ placements_selected.length - 1 }} weitere)
                            </span>
                        </div>
                    </template>
                    <template v-slot:item="{ active, item, attrs, on }">
                        <v-list-item
                            v-on="on"
                            v-bind="attrs"
                            #default="{ active }"
                        >
                            <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="d-flex align-center">
                                    <span
                                        :class="{
                                            'muted text-decoration-line-through':
                                                item.mark_disabled,
                                        }"
                                        >{{ item.text }}</span
                                    >
                                    <v-spacer></v-spacer>
                                    <!--<v-icon small v-if="item.mark_disabled">mdi-account-lock-outline</v-icon>-->
                                    <i
                                        style="padding-right: 4px; color: red"
                                        v-if="item.mark_disabled"
                                    >
                                        BELEGT
                                    </i>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-select>
                <!--<v-alert dense outlined type="error" v-if="getOverlappingPlacements().length > 0">
                    Es wurde(n) {{getOverlappingPlacements().length}} Teilnehmer gewählte für die/den Termin-Überlappungen festgestellt wurden. Sie können diesen Termin daher <strong>nicht speichern</strong>.
                    Sie müssen die mit "BELEGT" markierten Teilnehmer deselektieren um den Termin speichern zu können.
                </v-alert>-->
            </v-card>

            <!--
            -- SIGNATURES
            -- 1. TEACHER Signature
            -- 2. PARENT Signature
            -->
            <template
                v-if="
                    (isTeacherEditingAppointmentMode ||
                        isTeacherSignatureMode) &&
                        placements_selected.length > 0
                "
            >
                <div class="mt-4"></div>
                <span class="v-card-caption">Unterschriften</span>
                <v-card class="mx-auto pt-2 pb-3 px-2" outlined>
                    <div
                        v-for="(placement_id, index) in placements_selected"
                        :key="placement_id"
                        class="position-relative teacher-signatures-container"
                    >
                        <template
                            v-if="
                                getSignatureSource() === null ||
                                    placements[placement_id]
                                        ._appointments_placement[
                                        getAppointmentId()
                                    ].signature_request_token ===
                                        getSignatureTokenFromRequest()
                            "
                        >
                            <span class="v-card-caption"
                                >LK-Unterschrift für
                                {{
                                    placements[placement_id].student.first_name
                                }}</span
                            >
                            <v-card
                                class="mx-auto pt-3"
                                outlined
                                :class="{
                                    'signature-disabled': !teacherSignatureAllowed,
                                }"
                            >
                                <v-icon
                                    class="signature-disabled-indicator"
                                    v-if="!teacherSignatureAllowed"
                                    >mdi-marker-cancel</v-icon
                                >

                                <!--
                                -- Note on the following double component:
                                -- as soon as the defaultUrl is set (even if its set with null/false/empty string) the plugin won't return isEmpty()->true anymore even if it actually is (guess this is a bug).
                                -- So we need to hack around and render two different implementations of the plugin in order to keep track of the state (weather a signature is set or not)
                                -->
                                <vueSignature
                                    v-if="getSignature(placement_id) !== null"
                                    ref="signaturePads"
                                    w="100%"
                                    h="110px"
                                    :disabled="
                                        !teacherSignatureAllowed ||
                                            (!hasTeacherSignatureRequest &&
                                                isTeacherSignatureMode)
                                    "
                                    :defaultUrl="getSignature(placement_id)"
                                >
                                </vueSignature>
                                <vueSignature
                                    v-else
                                    ref="signaturePads"
                                    w="100%"
                                    h="110px"
                                    :disabled="
                                        !teacherSignatureAllowed ||
                                            (!hasTeacherSignatureRequest &&
                                                isTeacherSignatureMode)
                                    "
                                >
                                </vueSignature>
                            </v-card>
                            <!--<v-icon @click="clearSignature(index)" v-if="!(!teacherSignatureAllowed || (!hasTeacherSignatureRequest && isTeacherSignatureMode))" class="undo-refresh-icon">-->
                            <v-icon
                                @click="clearSignature(index)"
                                v-if="
                                    teacherSignatureAllowed ||
                                        (hasTeacherSignatureRequest &&
                                            isTeacherSignatureMode)
                                "
                                class="undo-refresh-icon"
                            >
                                mdi-refresh
                            </v-icon>
                        </template>
                        <div v-else ref="signaturePads">
                            <!-- Hack so clearing signature pads by index still works even if signature pads a spared out through the conditions -->
                        </div>
                    </div>
                    <v-alert
                        class="mt-5"
                        dense
                        outlined
                        type="warning"
                        v-if="teacherSignatureAllowed"
                    >
                        <ul>
                            <li>
                                Bitte achten Sie drauf bei Ihrer Unterschrift
                                nicht über den Rand des Unterschriftenfelds
                                hinaus zu zeichnen
                            </li>
                            <li>
                                Bitte füllen Sie möglichst das gesamte
                                Unterschriftenfeld mit Ihrer Unterschrift
                            </li>
                        </ul>
                    </v-alert>
                    <template v-if="sendingLinkAllowed">
                        <!-- version with phone number selection:
                        https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/cd76fc1de335c106a72dfc3286fbcdde9ed2d11a/src/pages/termin.vue#L413 -->
                        <v-btn
                            class="text-btn"
                            text
                            :disabled="!allSignaturesSet()"
                            href="#"
                            @click.prevent="sendLink"
                            :color="colors.blue"
                        >
                            <v-icon class="pr-3">mdi-email-send-outline</v-icon>
                            Link zur Elternunterschrift generieren
                        </v-btn>
                        <small
                            style="font-style: italic"
                            class="d-flex muted"
                            v-if="!allSignaturesSet()"
                        >
                            <v-icon class="pr-3"
                                >mdi-information-outline</v-icon
                            >
                            <span
                                class="d-inline-block"
                                style="line-height: normal"
                            >
                                * Versenden des Links ist erst möglich nachdem
                                für alle Schüler unterschrieben worden ist.
                            </span>
                        </small>
                        <small
                            style="font-style: italic"
                            class="d-flex muted"
                            v-if="signature_request_time !== null"
                            :class="{ 'mt-3': !allSignaturesSet() }"
                        >
                            <v-icon class="pr-3"
                                >mdi-information-outline</v-icon
                            >
                            <span
                                class="d-inline-block"
                                style="line-height: normal"
                            >
                                Es wurde bereits ein Link am
                                <u>{{
                                    moment(signature_request_time).format(
                                        moment_date_format +
                                            ' ' +
                                            moment_time_format_HM
                                    )
                                }}</u>
                                versendet.
                                <br />
                                <span
                                    v-if="
                                        moment(signature_valid_until).isAfter()
                                    "
                                >
                                    Dieser Link ist noch gültig bis:
                                </span>
                                <span style="color: red" v-else>
                                    Dieser Link war gültig bis:
                                </span>
                                <u>{{
                                    moment(signature_valid_until).format(
                                        moment_date_format +
                                            ' ' +
                                            moment_time_format_HM
                                    )
                                }}</u
                                >.
                            </span>
                        </small>
                        <div
                            class="d-block mt-2 muted"
                            v-if="
                                appointment.signature_request_token && isAdmin
                            "
                        >
                            <div class="d-flex">
                                Link für Elternunterschrift:
                                <v-spacer></v-spacer>
                                <v-btn
                                    x-small
                                    @click="
                                        toClipboard(
                                            `${window_location.origin}/termin/elternunterschrift/${appointment.signature_request_token}`
                                        );
                                        showSnackbar(
                                            'Der Link wurde in die Zwischenablage kopiert.',
                                            2
                                        );
                                    "
                                >
                                    kopieren
                                </v-btn>
                            </div>
                            <v-text-field
                                class="signature-link-preview-box"
                                readonly
                                outlined
                                :value="
                                    `${window_location.origin}/termin/elternunterschrift/${appointment.signature_request_token}`
                                "
                                hide-details
                                dense
                            >
                            </v-text-field>
                        </div>
                    </template>
                </v-card>
            </template>
            <!--
            -- END TEACHER Signature
            -- START PARENT Signature
            -->
            <template
                v-if="
                    (isParentSignatureMode &&
                        parentSignatureAllowedThroughStatus &&
                        getSignatureSource() === 'parent') ||
                        isParentEditingAppointmentMode
                "
            >
                <div class="mt-4"></div>
                <span class="v-card-caption">Unterschrift</span>
                <v-card class="mx-auto pt-4 pb-3 px-2" outlined>
                    <v-alert
                        dense
                        dark
                        :color="hasTeacherSignatures ? 'green' : 'red'"
                        class="lighten-2 mb-5"
                    >
                        {{
                            hasTeacherSignatures
                                ? 'Lehrerunterschriften vorhanden.'
                                : 'Lehrerunterschriften fehlen.'
                        }}
                    </v-alert>
                    <v-select
                        v-if="hasTeacherSignatures"
                        class="mt-5"
                        dense
                        :label="
                            hasParentSignature &&
                            !(getSignatureSource() === 'parent')
                                ? 'Es wurde unterschrieben für:'
                                : 'Wer sind Sie?'
                        "
                        hide-details="auto"
                        :disabled="
                            (hasParentSignature &&
                                isParentEditingAppointmentMode) ||
                                (!hasParentSignatureRequestToken &&
                                    isParentSignatureMode)
                        "
                        v-model="phone_number_selected"
                        :items="phoneNumbersItemized"
                    >
                    </v-select>
                    <template v-if="phone_number_selected">
                        <div class="position-relative mt-5">
                            <span class="v-card-caption">{{
                                hasParentSignature
                                    ? 'Ihre vorherige Unterschrift'
                                    : 'Bitte hier unterzeichnen'
                            }}</span>
                            <v-card class="mx-auto pt-3" outlined>
                                <!-- why two? => see description @ teacher signature pads -->
                                <vueSignature
                                    v-if="appointment.signature"
                                    ref="signaturePads"
                                    w="100%"
                                    h="110px"
                                    :disabled="
                                        !parentSignatureAllowedThroughStatus
                                    "
                                    :defaultUrl="appointment.signature"
                                >
                                </vueSignature>
                                <vueSignature
                                    v-else
                                    ref="signaturePads"
                                    w="100%"
                                    h="110px"
                                    :disabled="
                                        !parentSignatureAllowedThroughStatus
                                    "
                                >
                                </vueSignature>
                            </v-card>
                            <v-icon
                                @click="clearSignature()"
                                class="undo-refresh-icon"
                                v-if="
                                    !(
                                        (hasParentSignature &&
                                            isParentEditingAppointmentMode) ||
                                        (!hasParentSignatureRequestToken &&
                                            isParentSignatureMode)
                                    )
                                "
                            >
                                mdi-refresh
                            </v-icon>
                        </div>
                        <v-alert
                            class="mt-2"
                            dense
                            outlined
                            type="warning"
                            v-if="!hasParentSignature"
                        >
                            <ul>
                                <li>
                                    Bitte achten Sie drauf bei Ihrer
                                    Unterschrift nicht über den Rand des
                                    Unterschriftenfelds hinaus zu zeichnen
                                </li>
                                <li>
                                    Bitte füllen Sie möglichst das gesamte
                                    Unterschriftenfeld mit Ihrer Unterschrift
                                </li>
                            </ul>
                        </v-alert>
                        <template
                            v-if="
                                (!hasStandardSignature &&
                                    !hasParentSignature) ||
                                    (isParentSignatureMode &&
                                        !hasStandardSignature)
                            "
                        >
                            <v-checkbox
                                v-model="use_as_std_signature"
                                hide-details
                            >
                                <template v-slot:label>
                                    <small
                                        class="d-block"
                                        style="color: red; line-height: normal;"
                                    >
                                        Da diese Ihre erste Unterschrift ist,
                                        wird sie zum Abgleichzwecken als
                                        Standardunterschrift gespeichert.
                                        <br />
                                        <b
                                            >Bitte bestätigen Sie die
                                            Richtigkeit der Unterschrift.</b
                                        >
                                    </small>
                                </template>
                            </v-checkbox>
                        </template>
                    </template>
                </v-card>
            </template>

            <div
                class="mt-5"
                v-if="
                    isTeacherEditingAppointmentMode ||
                        isNewAppointmentMode ||
                        (isParentSignatureMode && !hasParentSignature)
                "
            >
                <span class="v-card-caption">Kommentar / Anmerkung</span>
                <v-card class="mx-auto pt-2 pb-3 px-2" outlined>
                    <v-textarea
                        placeholder="Anmerkung hier eingeben (optional)"
                        v-model="comment"
                        hide-details
                        :disabled="isAccepted"
                    ></v-textarea>
                </v-card>
            </div>

            <!--
                !Ibrahim 26.10.2022: the teacher payment should be commented out, until it's tested & implemented right und implemented
                <template v-if="showTeacherPay">
                <div class="mt-4"></div>
                <span class="v-card-caption">Vergütung</span>
                <v-card class="mx-auto pt-5 pb-2 px-2" outlined>
                    Sie werden für diesen Termin vergütet mit:
                    <h1 style="color: green">{{ teacherPayout }}</h1>
                </v-card>
            </template> -->

            <template
                v-if="isParentSignatureMode || isParentEditingAppointmentMode"
            >
                <v-alert
                    dense
                    dark
                    color="red"
                    class="lighten-2 mt-5"
                    v-if="
                        !parentSignatureAllowedThroughStatus &&
                            !hasParentSignature
                    "
                >
                    <template v-if="!hasTeacherSignatures">
                        Es kann momentan keine Unterschrift geleistet werden, da
                        die Lehrkraft-Unterschrift für den Termin fehlt.
                    </template>
                    <template v-else>
                        Es kann momentan keine Unterschrift geleistet werden, da
                        sich der Status des Termins geändert hat.
                    </template>
                </v-alert>
                <v-alert
                    dense
                    dark
                    color="green"
                    class="lighten-2 mt-5"
                    v-if="hasParentSignature && !hasParentSignatureRequestToken"
                >
                    Vielen Dank für Ihre Unterschrift, der Termin wurde
                    bestätigt.
                </v-alert>
            </template>

            <template v-if="revokeCancellationAllowed">
                <v-btn
                    class="d-block mt-5"
                    style="width: 100%"
                    dark
                    color="orange"
                    @click="revokeCancellation"
                >
                    Absage zurücknehmen
                </v-btn>
            </template>
        </v-container>

        <v-footer app>
            <v-btn color="red lighten-2" dark fab small to="/terminuebersicht"
                ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
            <!--<small style="color: #949494" class="pl-2 muted">Abbrechen</small>-->
            <v-spacer></v-spacer>
            <!--<small style="color: #949494" class="pr-2 muted">Speichern</small>-->
            <v-btn
                v-if="!isAccepted || isGenericSignatureMode"
                :disabled="!canSave()"
                :dark="canSave()"
                color="green lighten-2"
                fab
                small
                @click="validate"
            >
                <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn
                v-if="isAccepted && !isGenericSignatureMode && !isFamily"
                color="green"
                dark
                fab
                small
                to="/termin/neu"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-footer>
    </v-form>
</template>

<style lang="scss">
/*
     * styling for the student "more info" accordion
     */
#student-detail-info {
    .v-list-item__action:first-child,
    .v-list-item__icon:first-child {
        margin-right: 12px !important;
    }

    .v-list-item {
        padding: 0;
    }

    .v-list-item__action:last-of-type:not(:only-child),
    .v-list-item__avatar:last-of-type:not(:only-child),
    .v-list-item__icon:last-of-type:not(:only-child) {
        margin-left: 0 !important;
    }

    .v-list-group
        .v-list-group__header
        .v-list-item__icon.v-list-group__header__append-icon {
        min-width: auto;
    }
}

.signature-link-preview-box {
    font-size: 10px;
    input {
        padding: 0;
    }
    .v-input__slot {
        min-height: 24px !important;
    }
}

.repetitive-appointment-note {
    padding-left: 33px;
    display: block;
    line-height: 12px;
    margin-top: 5px;
    font-size: 0.7em;
}

.v-form.isSignature {
    .v-select.v-input--is-disabled {
        // .v-select--chips ... .theme--light
        .v-input__append-inner {
            display: none;
        }
        .theme--light.v-chip:not(.v-chip--active) {
            background-color: transparent;
        }
    }
}

.teacher-signatures-container {
    .signature-disabled .signature-disabled-indicator {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-50%);
        color: red;
        font-size: 1.8em;
    }
}

// Mobile devices (landscape, portrait)
@media (pointer: coarse) and (max-width: 575.98px) {
    #status-action-bar {
        //position: fixed;
        position: sticky;
        z-index: 4;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(5px);
        top: 56px;
        //padding-top: 8px;
        left: 0;
        right: 0;
        //padding-left: 15px;
        //padding-right: 15px;
    }

    #app {
        &.elternunterschrift,
        &.lkunterschrift {
            #status-action-bar {
                top: 12px;
                position: static;
                /*+div { // hide the spacer
                        display:  none;
                    }*/
            }
        }
    }
}
// end small mobile device defs
</style>

<script>
import config from '@/config';
import colors from '@/misc/colors';
import axios from 'axios';
import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

import Vue from 'vue';

import vueSignature from 'vue-signature';

import delete_appointment_modal from '@/components/partials/delete-appointment-modal';

// prefill fields for quicker testing
const PREFILL_REQUIRED_FIELDS = window.location.host === 'localhost:8080';

export default {
    name: 'termin',
    components: {
        'delete-appointment-modal': delete_appointment_modal,
        vueSignature,
    },
    data: () =>
        _.extend(
            {
                valid: true,

                appointment_found: null,
                signature_expired: false,

                mode: 0, // 0 => neuer termin ;;; 1 => termin bearbeiten

                ENABLE_OVERLAP_CHECKS: false,

                /*dialogs : { // why no simple array? -> https://vuejs.org/v2/guide/reactivity.html#For-Arrays
            '0' : false
        },*/
                dialog_id: 0,
                dialogs: [],

                placements: null,
                placements_selected: [],

                date: null,
                time_start: null,
                time_end: null,
                form_selected: null,

                type_selected: 1,
                repetitive_selected: null,

                location_src_select: null, // for the radio groups that gives us information on which location source to use (student's home, free-text location)
                location_free_text: null,

                status: null,

                subjects_selected: {},
                //subjects_selected_internal : {},

                phone_numbers: [],
                phone_number_selected: null,

                appointment: null, // for the delete sub component

                signature_request_time: null,
                signature_valid_until: null,

                use_as_std_signature: false,

                isParentSignatureEmpty: true,
                isTeacherSignatureEmpty: true,

                parent_comment: null,
                teacher_comment: null,

                invoiced: {},
                currently_selected_month_invoiced: false,

                disable_save: false, // this prop can just be set from anywhere in the script in order to explicitly disable saving

                overlapping_appointments: [],
            },
            PREFILL_REQUIRED_FIELDS
                ? {
                      // when testing: set prefill testing data
                      date: moment().format('YYYY-MM-DD'),
                      time_start: moment()
                          .minutes(15)
                          .format(config.moment_time_format_HM),
                      time_end: moment()
                          .minutes(30)
                          .add(1, 'hours')
                          .format(config.moment_time_format_HM),
                      form_selected: 1,
                      repetitive_selected: 1,
                      //subject_selected : 3
                  }
                : {}
        ),
    mounted() {
        const is_id_req = this.getAppointmentIdFromRequest() !== null;
        const is_token_req = this.getSignatureTokenFromRequest() !== null;

        let appointmentsPromise = false; // if the promise-var is false Promise.all(<falsy var>) will ignore / evaluate it as fullfilled

        let placementsPromiseResolver = null;
        let placementsPromise = new Promise(function(resolve, reject) {
            placementsPromiseResolver = resolve;
        });

        if (is_id_req || is_token_req) {
            // if at least an ID or token comes through the request uri

            // if we have a token/id in the request we need to load the matching appointment for this id/token; so lets create a "on-demand" promise for it
            appointmentsPromise = new Promise((resolve, reject) => {
                this.$root.globalsReady.then(() => {
                    const is_teacher = this.$root.userInfo.is_teacher;
                    const is_family = this.$root.userInfo.is_family;
                    const is_app_admin = this.$root.userInfo.is_app_admin;

                    // 0 = new ; 1 = edit ; 2 = parent/teacher
                    if (is_teacher || is_app_admin) this.mode = 1;
                    // edit mode (teacher only)
                    else if (is_token_req) this.mode = 2;
                    // parent/teacher signature mode
                    else if (is_family) this.mode = 3; // parent view mode (when parents are logged in)

                    appointmentsPromise = axios
                        .get(
                            `${config.backend_APP_api_url}/appointments/` +
                                (this.getAppointmentIdFromRequest() ??
                                    this.getSignatureTokenFromRequest())
                        )
                        .then(res => {
                            /*if(res.data.appointments.length > 0)
                                resolve(res)
                            else
                                reject({'error' : 'appointment could not be found found', 'reason' : 'in-existing or not accessible by requesting user'})*/
                            resolve(res);
                        })
                        .catch(() => {
                            reject({
                                error: 'appointment could not be found',
                                reason:
                                    'in-existing or not accessible by requesting user',
                            });
                        });
                });
            });
        }

        if (!appointmentsPromise) {
            // we don't have a appointment promise (this means we are not within any editing view)
            axios
                .get(`${config.backend_APP_api_url}/appointments/placements`)
                .then(res => {
                    placementsPromiseResolver(res);
                });
        } else {
            // we got an appointment promise
            appointmentsPromise
                .then(res => {
                    //const appointment_id = this.getAppointmentIdFromRequest()
                    const appointment_id = res.data.appointments[0].id;
                    axios
                        .get(
                            `${config.backend_APP_api_url}/appointments/placements/${appointment_id}`
                        )
                        .then(res => {
                            placementsPromiseResolver(res);
                        });
                })
                .catch(() => {
                    // triggered if the appointment request returns no appointments
                    appointmentsPromise = false; // so the ".all" promise collection won't run
                    this.appointment_found = false;
                });
        }

        axios
            .get(`${config.backend_APP_api_url}/teachers/invoice-timeframes`)
            .then(res => {
                this.invoiced = res.data;
            });

        Promise.all([
            appointmentsPromise,
            placementsPromise,
            this.$root.globalsReady,
        ])
            .then(promises => {
                let placements = promises[1].data;
                this.placements = placements;

                this.location_src_select = this.locationSourceEnums[
                    'at_students_home'
                ];

                if (appointmentsPromise !== false) {
                    // if the promise exists (=> is <> false) ... (it will be false if we are creating a new appointment rather then editing an old one)
                    let appointment = promises[0].data.appointments[0] ?? false;
                    this.appointment = appointment;
                    this.appointment_found = appointment !== false;

                    this.date = appointment.date;
                    this.time_start = moment(
                        appointment.start,
                        this.moment_time_format_full
                    ).format(this.moment_time_format_HM);
                    this.time_end = moment(
                        appointment.end,
                        this.moment_time_format_full
                    ).format(this.moment_time_format_HM);
                    this.form_selected = appointment.presence;
                    this.repetitive_selected = appointment.repetitivity;
                    this.status = appointment.status;
                    this.location_src_select = appointment.location_type;
                    this.location_free_text = appointment.location_freetext;
                    this.signature_request_time =
                        appointment.signature_request_time;
                    this.signature_valid_until =
                        appointment.signature_valid_until;
                    this.teacher_comment = appointment.teacher_comment;
                    this.parent_comment = appointment.parent_comment;

                    this.placements_selected = _.map(
                        appointment.placements,
                        'id'
                    );

                    // restore selection for the target person
                    this.phone_number_selected = _.get(
                        _.find(
                            this.phoneNumbersItemized,
                            phoneNumItemEntry =>
                                phoneNumItemEntry.value.selected === true
                        ),
                        'value',
                        null
                    );

                    // restore subject selection from placement (old version: https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/7a3c4ded244d4f5c5dec502a9e17ac82f02aa37f/src/views/termin.vue#L780)
                    _.each(this.getSelectedPlacements(), placement => {
                        // NOTE: setting data the first way the var "this.subjects_selected" becomes none-reactive! Why ever...
                        //this.subjects_selected[placement.id] = placement.getSelectedSubject().schoolsubject_id
                        this.$set(
                            this.subjects_selected,
                            placement.id,
                            placement.getSelectedSubject().placementsubject_id
                        );
                    });

                    // restore signatures for other/alternative signature pad plugin: https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/7a3c4ded244d4f5c5dec502a9e17ac82f02aa37f/src/views/termin.vue#L794

                    // hack because the signature pad won't tell us if its empty/filled state changes
                    if (this.isParentSignatureMode) {
                        //  && !this.hasParentSignature
                        setInterval(() => {
                            if (_.get(this.$refs, 'signaturePads', false)) {
                                // Note that hidden signature pads (like when the user did not choose a phone number) won't be referenceable! But they become ref. as they appear
                                if (!this.$refs.signaturePads.isEmpty) return;
                                this.isParentSignatureEmpty = this.$refs.signaturePads.isEmpty();
                            }
                        }, 100);
                    }
                    if (this.isTeacherSignatureMode) {
                        // TODO combine with part directly above for parent signature mode
                        setInterval(() => {
                            let sp = _.get(this.$refs, 'signaturePads', false);
                            if (sp) {
                                sp = _.find(
                                    this.$refs.signaturePads,
                                    elem => elem._isVue
                                ); // through a hack (where a signaturePad is replaced with an empty div) we need to search the first signature pad for the teacher signature
                                //if(typeof sp.isEmpty !== 'function')
                                //return
                                this.isTeacherSignatureEmpty = sp.isEmpty();
                            }
                        }, 100);
                    }
                } else {
                    this.appointment_found = true;
                }
            })
            .catch(error => {
                if (_.get(error, 'response.status', true)) console.error(error);
                else {
                    // laravel will return 410 (Gone) when the signature token is expired...
                    if (error.response.status === 410)
                        this.signature_expired = true;
                }
            });
    },
    watch: {
        date() {
            this.debounceOverlapCheck(this);
        },
        time_start() {
            this.debounceOverlapCheck(this);
        },
        time_end() {
            this.debounceOverlapCheck(this);
        },
        placements_selected(old, newVal) {
            /*
             * update the subjects_selected as the placement selection changes (throwing out subject selections for de-selected placements)
             * without this the subjects_selected would keep possibly deselected placement references which causes problems when working with subjects_selected
             */
            this.$nextTick(() => {
                let selected_placements = this.getSelectedPlacements();
                this.subjects_selected = _.pickBy(
                    this.subjects_selected,
                    (subject_id, placement_id) => {
                        return _.has(selected_placements, placement_id);
                    }
                );
            });
        },
    },
    computed: {
        hasParentSignatureRequestToken() {
            // check if the current appointment has ...
            return this.appointment.signature_request_token !== null;
        },
        placementToSign() {
            // if we are in a signature mode the placements under the current appointment are appended the field 'is_placement_to_sign' (through backend) - so we are able to pull out the desired placement out of the full list of placements
            return _.find(this.appointment.placements, {
                is_placement_to_sign: true,
            });
        },
        hasTeacherSignatureRequest() {
            return !_.isUndefined(this.placementToSign);
        },
        isInvoiced() {
            if (this.isNewAppointmentMode) return false;
            else {
                const appointment_date = moment(this.appointment.date).format(
                    'Y-M'
                );
                return (
                    _.find(this.invoiced, { asKey: appointment_date }) !==
                    undefined
                );
            }
        },
        teacherPayout() {
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
            }).format(this.appointment.teacher_payout);
        },
        comment: {
            get() {
                return this.isParentSignatureMode
                    ? this.parent_comment
                    : this.teacher_comment;
            },
            set(newVal) {
                this.isParentSignatureMode
                    ? (this.parent_comment = newVal)
                    : (this.teacher_comment = newVal);
            },
        },
        colors: () => colors.colors,
        students() {
            // only those of the selected placements
            return _.keyBy(
                _.map(this.getSelectedPlacements(), 'student'),
                'id'
            );
        },
        showTeacherPay() {
            // show "Vergütung" (teacher)?
            return (
                [
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.internally_accepted',
                        false
                    ),
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.accepted',
                        false
                    ),
                ].indexOf(this.status) !== -1 &&
                (this.isTeacher || this.isAdmin)
            );
        },
        revokeCancellationAllowed() {
            /*
            allowed @ status:
            - "Lehrerabsage"
            - "Elternabsage ausstehend"
            - "Elternabsage abgelehnt"
            - "Elternabsage akzeptiert"
             */
            return (
                [
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.cancellation_through_teacher',
                        false
                    ), // "Lehrerabsage"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.cancellation_through_parents_pending',
                        false
                    ), // "Elternabsage ausstehend"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.cancellation_through_parents_declined',
                        false
                    ), // "Elternabsage abgelehnt"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.cancellation_through_parents_accepted',
                        false
                    ), // "Elternabsage akzeptiert"
                ].indexOf(this.status) !== -1
            );
        },
        parentSignatureAllowedThroughStatus() {
            /*
            allowed @ status:
            - "eltern u. fehlt"
            - "eltern u. abgelehnt abzurechnen"
            - "eltern u. abgelehnt nicht abzurechnen"
             */
            return (
                [
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.signature_missing',
                        false
                    ), // "Eltern(/Schüler 16+)-Unterschrift fehlt"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.signature_declined_billable',
                        false
                    ), // ...
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.signature_declined_not_billable',
                        false
                    ), // ...
                ].indexOf(this.status) !== -1 ||
                (_.get(
                    this.$root,
                    'enums.byKey.appointment_statuses.accepted',
                    false
                ) && // NEW (so parents can overwrite their signature) ...
                    this.appointment.signature_request_token)
            );
        },
        teacherSignatureAllowed() {
            /*
            allowed @ status:
            - "LK. U. Fehlt" ("fällig")
            - "eltern u. fehlt"
             */
            return (
                this.isTeacherSignatureMode ||
                ([
                    // signature is only allowed if the appointment status are one of these (like listed above in the comment)
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.teacher_signature_missing',
                        false
                    ), // "LK. U. Fehlt"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.signature_missing',
                        false
                    ), // "eltern u. fehlt"
                ].indexOf(this.status) !== -1 &&
                    moment(this.date + ' ' + this.time_start).isBefore())
            ); // signature is only allowed if the appointment is in the past
        },
        sendingLinkAllowed() {
            /*
            allowed @ status:
            - "Eltern U. abgelehnt"
            - "Eltern U. fehlt"
             */
            return (
                [
                    // signature is only allowed if the appointment status are one of these (like listed above in the comment)
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.signature_declined',
                        false
                    ), // "Eltern U. abgelehnt"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.signature_missing',
                        false
                    ), // "eltern u. fehlt"
                ].indexOf(this.status) !== -1 && this.isAdmin
            );
        },
        dateTimeChangeAllowed() {
            // "Verschiebung"
            /*
            allowed @ status:
            - "ausstehend"
            - "LK. U. Fehlt"
            - "Eltern U. fehlt"
             */
            return (
                [
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.pending',
                        false
                    ), // "ausstehend"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.teacher_signature_missing',
                        false
                    ), // "LK. U. Fehlt"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.signature_missing',
                        false
                    ), // "Eltern U. fehlt"
                ].indexOf(this.status) !== -1 || this.isNewAppointmentMode
            );
        },
        participantsChangeAllowed() {
            // "Teilnehmer ändern"
            /*
            allowed @ status:
            - "ausstehend"
            - "LK. U. Fehlt"
            - "Eltern U fehlt"
             */
            return (
                [
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.pending',
                        false
                    ), // "ausstehend"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.teacher_signature_missing',
                        false
                    ), // "LK. U. Fehlt"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.signature_missing',
                        false
                    ), // "Eltern U. fehlt"
                ].indexOf(this.status) !== -1 || this.isNewAppointmentMode
            );
        },
        presenceChangeAllowed() {
            // "Form" (Präsenz / Distanz)
            /*
            allowed @ status:
            - "ausstehend"
            - "LK. U. Fehlt"
            - "Eltern U fehlt"
             */
            return (
                [
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.pending',
                        false
                    ), // "ausstehend"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.teacher_signature_missing',
                        false
                    ), // "LK. U. Fehlt"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.signature_missing',
                        false
                    ), // "Eltern U. fehlt"
                ].indexOf(this.status) !== -1 || this.isNewAppointmentMode
            );
        },
        subjectsChangeAllowed() {
            // "Fächerwahl"
            /*
            allowed @ status:
            - "ausstehend"
            - "LK. U. Fehlt"
            - "Eltern U fehlt"
             */
            return (
                [
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.pending',
                        false
                    ), // "ausstehend"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.teacher_signature_missing',
                        false
                    ), // "LK. U. Fehlt"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.signature_missing',
                        false
                    ), // "Eltern U. fehlt"
                ].indexOf(this.status) !== -1
            );
        },
        cancellationOrDeletionAllowed() {
            // "Löschen" / "Absagen"
            /*
            allowed @ status:
            - "ausstehend"
            - "LK. U. Fehlt"
            - "Eltern U fehlt"
             */
            return (
                [
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.pending',
                        false
                    ), // "ausstehend"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.teacher_signature_missing',
                        false
                    ), // "LK. U. Fehlt"
                    _.get(
                        this.$root,
                        'enums.byKey.appointment_statuses.signature_missing',
                        false
                    ), // "Eltern U. fehlt"
                ].indexOf(this.status) !== -1
            );
        },
        isAdmin() {
            return this.$root.userInfo.is_app_admin;
        },
        /*
         * Note on
         * >> && !this.isGenericSignatureMode <<
         * in the isParent, isFamily, isTeacher functions:
         * -> we do this because signature modes are supposed to be used without any auth and therefor without any user role
         */
        isParent() {
            return (
                this.$root.userInfo.is_family && !this.isGenericSignatureMode
            );
        },
        isFamily() {
            return this.isParent && !this.isGenericSignatureMode;
        },
        isTeacher() {
            return (
                this.$root.userInfo.is_teacher && !this.isGenericSignatureMode
            );
        },
        isAccepted() {
            // is Appointment Accepted (status)
            return (
                this.status ===
                _.get(
                    this.$root,
                    'enums.byKey.appointment_statuses.accepted',
                    false
                )
            );
        },
        isPending() {
            // is Appointment ...
            return (
                this.status ===
                _.get(
                    this.$root,
                    'enums.byKey.appointment_statuses.pending',
                    false
                )
            );
        },
        isNewAppointmentMode() {
            return this.mode === 0;
        },
        isTeacherEditingAppointmentMode() {
            return this.mode === 1;
        },
        isParentEditingAppointmentMode() {
            return this.mode === 3;
        },
        isGenericSignatureMode() {
            // true @ parent & teacher signature mode
            return this.mode === 2;
        },
        /*
         * NOTE: 'signature mode' refers to SIGNATURE ONLY 'editing' mode
         */
        isParentSignatureMode() {
            //return ( this.isGenericSignatureMode && this.isFamily ) || this.getSignatureSource() === 'parent'
            return (
                this.isGenericSignatureMode &&
                this.getSignatureSource() === 'parent'
            );
        },
        isTeacherSignatureMode() {
            //return ( this.isGenericSignatureMode && this.isTeacher ) || this.getSignatureSource() === 'teacher'
            return (
                this.isGenericSignatureMode &&
                this.getSignatureSource() === 'teacher'
            );
        },
        hasTeacherSignatures() {
            var allTeacherSignaturesSet = true;
            _.forEach(_.get(this.appointment, 'placements', {}), placement => {
                const pivot_data =
                    placement._appointments_placement[this.appointment.id];
                if (
                    pivot_data.teacher_signature === null ||
                    pivot_data.teacher_signature.trim() === ''
                )
                    allTeacherSignaturesSet = false;
            });
            return allTeacherSignaturesSet;
        },
        hasParentSignature() {
            return _.get(this.appointment, 'signature', null) !== null;
        },
        hasStandardSignature() {
            const parent_types_by_val = _.get(
                this.$root,
                'enums.byVal.parent_types',
                {}
            );

            let signature_exits = false;
            if (this.phone_number_selected.source === 'family') {
                const parent_type_selected = this.phone_number_selected
                    .parent_type; // can be: -1 (father) ; -2 (mother)
                signature_exits =
                    this.getFamily()[
                        parent_types_by_val[parent_type_selected] +
                            '_std_signature'
                    ] !== null;
            } else if (this.phone_number_selected.source === 'student') {
                // TODO I think I removed the students data while cleaning up stuff an improving performance -> check this!
                signature_exits =
                    this.students[this.phone_number_selected.student_id][
                        'signature'
                    ] !== null;
            }

            return signature_exits;
        },
        studentLocation() {
            if (_.get(this.placements_selected, '[0]', false)) {
                return _.get(
                    this.placements,
                    '[' + this.placements_selected[0] + '].family.address',
                    false
                );
            }
            return false;
        },
        placementsItemizedFiltered() {
            let aPlacements = [];

            _.forEach(this.placements, (placement, placement_id) => {
                /*
                 * Old version which also respects the "application_type"
                 * https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/737cf43d00fd69c5ed7dcb01ec5ea5174a87de77/src/views/termin.vue#L1060
                 *
                 * Old Version that was in a long time but did not respect all further placement selections but the first:
                 * https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/7353562b5a038da6b4df77d5753d2ac1db7e003c/src/pages/termin.vue#L1016
                 */

                let omit = false;
                _.forEach(this.placements_selected, placement_id => {
                    const current_placement = this.placements[placement_id];

                    //  omit entry if the family of the selected main entity differs from that one of the current iteration
                    
                    
                    // Ibrahim: 26.10.2022: this if-statment is used to limit the students to one family
                    // if (placement.family.id !== current_placement.family.id)
                    //     omit = true;

                    // also omit the same student (as already selected) if he exists multiple time (that is absolutely possible through the fact that students can have multiple placements with different school subjects)
                    if (
                        placement.student.id === current_placement.student.id &&
                        placement.id !== current_placement.id
                    )
                        omit = true;
                });

                if (!omit) {
                    aPlacements.push({
                        text: `${placement.student.first_name} ${placement.family.last_name}, ${placement.application_type.title}`,
                        student_name: `${placement.student.first_name} ${placement.family.last_name}`,
                        school_subject: `${placement.application_type.title}`,
                        value: placement.id,
                        mark_disabled: this.isPlacementOverlapping(placement), // note: using 'disabled' will physically disable the select option
                    });
                }
            });

            return aPlacements;
        },
        typesItemized() {
            let aTypes = [];
            if (
                _.get(this.$root, 'enums.byKey.appointment_types', false) !==
                false
            ) {
                Object.entries(
                    this.$root.enums.byKey.appointment_types
                ).forEach(
                    function(a, b, c) {
                        aTypes.push({
                            text: this.getResolvedAppointmentTypeLabel(a[1]),
                            value: a[1],
                        });
                    }.bind(this)
                );
            }
            return aTypes;
        },
        formsItemized() {
            let aForms = [];
            if (
                _.get(
                    this.$root,
                    'enums.byKey.appointment_presence_types',
                    false
                ) !== false
            ) {
                Object.entries(
                    this.$root.enums.byKey.appointment_presence_types
                ).forEach(
                    function(a, b, c) {
                        aForms.push({
                            text: this.getResolvedAppointmentPresence(a[1])
                                .label,
                            value: a[1],
                        });
                    }.bind(this)
                );
            }
            return aForms;
        },
        phoneNumbersItemized() {
            // old version: https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/d838ddb7c3f7369ff9db18f80962377455ffeb6c/src/views/termin.vue#L786
            let aPN = [];

            const is_family = this.$root.userInfo.is_family;

            _.foreach(this.getPhoneNumbers(), phone_data => {
                const entity_is =
                    phone_data.parent_type_label || 'Schüler über 16';
                let text_label = null;
                if (is_family) {
                    // if the currently logged in user is an "family" user
                    if (phone_data.name)
                        text_label = `${phone_data.name} (${entity_is})`;
                    else text_label = entity_is;
                } // if not an family account
                else
                    text_label = _.get(phone_data, 'name', false)
                        ? `${phone_data.name} (${entity_is})` // => parent name exists
                        : `${entity_is} (${phone_data.number})`; // => parent name does not exist

                aPN.push({
                    text: text_label,
                    value: phone_data,
                });
            });
            return aPN;
        },
        repetitiveItemized() {
            let aRep = [];
            if (
                _.get(
                    this.$root,
                    'enums.byKey.appointment_repetitivities',
                    false
                ) !== false
            ) {
                Object.entries(
                    this.$root.enums.byKey.appointment_repetitivities
                ).forEach(
                    function(a, b, c) {
                        aRep.push({
                            text: this.getResolvedAppointmentRepetition(a[1])
                                .label,
                            value: a[1],
                        });
                    }.bind(this)
                );
            }
            return aRep;
        },
        dateFormatted() {
            //return this.date ? moment(this.date).format('dddd, Do MMMM YYYY') : ''
            return this.date
                ? moment(this.date).format(this.moment_date_format)
                : '';
        },
    },
    methods: {
        canSave() {
            // DON'T MAKE A COMPUTED PROPERTY OUT OF THIS! Computed properties are cached which makes detection on changes to "subjects_selected" impossible

            // some conditions that have been removed: https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/f1072db0719676ac86ebf495e944c2c1705c6e78/src/pages/termin.vue#L1319

            let cc_debug = []; // allows us to print the chain until the first condition chunk that evals to false

            const ccr =
                (cc_debug[cc_debug.length] =
                    // chunk 1
                    !this.hasOverlappingPlacements() &&
                    !this.isTimeframeOverlappingOtherAppointments() &&
                    !this.disable_save) && // disable_save is just a plain var that may be set from somewher in the code to just force that saving is disabled at all costs
                (cc_debug[cc_debug.length] =
                    // chunk 2
                    (this.isNewAppointmentMode && !this.appointment) ||
                    !this.isNewAppointmentMode) && // this part returns false if the appointment (in the NEW-APPO. view) has been saved to the DB (and therefor this.appointment exists)
                (cc_debug[cc_debug.length] =
                    // chunk 3
                    this.teacherSignatureAllowed ||
                    this.dateTimeChangeAllowed ||
                    this.participantsChangeAllowed ||
                    this.presenceChangeAllowed ||
                    this.subjectsChangeAllowed ||
                    this.parentSignatureAllowedThroughStatus) &&
                (cc_debug[cc_debug.length] =
                    // chunk 4
                    (this.isParentSignatureMode &&
                        !this.isParentSignatureEmpty &&
                        (this.use_as_std_signature !== false ||
                            this.hasStandardSignature)) || // ... so this also works if there is already a std signature and we can't tick the "make it standard!" checkbox
                    !this.isParentSignatureMode) && // render the above condition unaffecting if we are not in parent signature mode
                (cc_debug[cc_debug.length] =
                    // chunk 5
                    // when editing mode: make sure every placement has a subject selected
                    _.size(this.getSelectedPlacements()) ===
                        _.filter(this.subjects_selected).length ||
                    !this.isTeacherEditingAppointmentMode ||
                    this.isPending) &&
                (cc_debug[cc_debug.length] =
                    // chunk 6
                    // make sure that all signatures are set in the teacher signature mode before allowing to save
                    !this.isTeacherSignatureEmpty ||
                    !this.isTeacherSignatureMode) &&
                (cc_debug[cc_debug.length] =
                    // chunk 7
                    // make sure the TEACHER cannot save multiple times in signature mode if he already did and therefor the request fields in the db were reseted
                    !this.isTeacherSignatureMode ||
                    this.hasTeacherSignatureRequest);

            // please just keep that line if commented in...
            //console.info(cc_debug, '> see chunk ' + cc_debug.length)

            return ccr; // evaled (bool) res of the condition chain
        },

        // determine whether a given placement is used within another appointment at the currently configured appointment timeframe causing overlapping
        isPlacementOverlapping(placement) {
            if (!this.ENABLE_OVERLAP_CHECKS) return false;

            return (
                _.find(placement.student.appointments, appointment => {
                    const start = moment(this.time_start, 'hh:mm'); // < the currently selected start time of the appointment as moment object
                    const end = moment(this.time_end, 'hh:mm'); // " for the end time

                    const id_match =
                        appointment.id ===
                        _.get(this.appointment, 'id', appointment.id); // prevent that the placement gets locked by the same appointment we are currently editing
                    const date_match = appointment.date === this.date;

                    const range1 = moment.range(start, end); // range object for the currently selected timeframe
                    const range2 = moment.range(
                        moment(appointment.start, 'hh:mm:ss'),
                        moment(appointment.end, 'hh:mm:ss')
                    ); // range object for the currently processed appointment timeframe
                    const range_overlaps = range1.overlaps(range2);

                    return id_match && date_match && range_overlaps;
                }) !== undefined
            );
        },

        // check if a new appointment at the currently selected timeframe would create an overlapping appointment
        // this function checks a variable that is generated by the debounced 'checkForTimeframeOverlaps' function (which updates as time/date is changed by the user)
        isTimeframeOverlappingOtherAppointments() {
            if (!this.ENABLE_OVERLAP_CHECKS) return false;

            return this.overlapping_appointments.length !== 0; // @see checkForTimeframeOverlaps
        },
        // 'debounceOverlapCheck' is invoked by (watchers) that observe changes to the variables 'date', 'time_start' & 'time_end'
        // so this function is called when the date/time changes
        debounceOverlapCheck: _.debounce(vm => {
            vm.checkForTimeframeOverlaps();
        }, 350), //     ^
        //              |
        checkForTimeframeOverlaps() {
            // just collect the currently configured timeframe and ask the server if it overlaps with any other appointment

            if (!this.ENABLE_OVERLAP_CHECKS) return;

            if (
                _.size(
                    _.pickBy(
                        _.pick(this.$data, ['date', 'time_start', 'time_end']),
                        _.identity
                    )
                ) !== 3 ||
                this.isParentSignatureMode ||
                this.isTeacherSignatureMode
            )
                // if not all three fields are filled or if we are not in a regular edit mode (but within a simple signature mode)
                this.overlapping_appointments = [];
            else {
                const base_data = _.pick(this.$data, [
                    'date',
                    'time_start',
                    'time_end',
                ]);
                let data = null;
                if (this.isTeacherEditingAppointmentMode)
                    // when editing: append the ID of the appointment we are editing so the backend knows which appointment to ignore for the check
                    data = _.merge({ id: this.appointment.id }, base_data);
                else if (this.isNewAppointmentMode) data = base_data;
                axios
                    .post(
                        `${config.backend_APP_api_url}/appointments/overlapping`,
                        data
                    )
                    .then(res => {
                        this.overlapping_appointments = res.data;
                    });
            }

            /*
             * de-select placements that have been selected before the appointment date was changed to an date that would cause a placement overlapping
             * Example:
             * - you select an placement before setting date / time
             * - now you set the time to an frame that would overlap the previously selected placement
             * -> the placement would be disabled now (coming through the itemized method) but would still be selected. This way you are able to select an blocked placement...
             * So this little part throws out previous selections if necessary
             */
            /*this.$nextTick(() => {
                _.each(this.getSelectedPlacements(), (placement, str_placement_id) => {
                    //if(placement.isOverlapping())
                    if(this.isPlacemmentOverlapping(placement))
                        this.placements_selected = _.without(this.placements_selected, placement.id)
                })
            })*/
            // --> perhaps we can use this feature later - but for now there are some placements that were already created with overlappings... so enabling this feature could cause an old appointment to have no selected placements left
        },
        getSelectedPlacements() {
            let placements = {};

            let instance_data = this.$data; // because using 'this.' within the methods below references the current placement and not the data instance of this component

            _.each(this.placements_selected, placement_id => {
                const placement = this.placements[placement_id];
                const appointment = this.appointment;
                const ENABLE_OVERLAP_CHECKS = this.ENABLE_OVERLAP_CHECKS;
                const isPlacementOverlapping = this.isPlacementOverlapping; // so that we are able to use this function within isOverlaping() @ the placement object
                _.merge(placement, {
                    // lets add some helpful utility functions to the placement object
                    getPossibleSubjects() {
                        return this.placementsubjects;
                    },
                    getSelectedSubject() {
                        // note that this function NEEDS the appointment ID! Otherwise we can not know what placement to refer to
                        return _.merge(
                            // merge the subject object into the pivot data
                            this._appointments_placement[appointment.id],
                            _.find(this.getPossibleSubjects(), subject => {
                                return (
                                    subject.id ===
                                    this._appointments_placement[appointment.id]
                                        .placementsubject_id
                                );
                            })
                        );
                    },
                    isOverlapping() {
                        // ... does any of this placement's appointments overlap the current configured timeframe?

                        if (!ENABLE_OVERLAP_CHECKS) return false;

                        const placement = this;
                        return isPlacementOverlapping(placement);

                        // previous implementation: https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/7d08c2af2664e930827e17e0aba12287600f3c42/src/pages/termin.vue#L1527
                    },
                    // -- END Utility functions @ placement object
                });
                placements[placement_id] = placement;
            });

            return placements;
        },
        getOverlappingPlacements() {
            if (!this.ENABLE_OVERLAP_CHECKS) return [];
            return _.filter(this.getSelectedPlacements(), placement =>
                placement.isOverlapping()
            );
        },
        hasOverlappingPlacements() {
            if (!this.ENABLE_OVERLAP_CHECKS) return false;
            return this.getOverlappingPlacements().length > 0;
        },

        hoursLeft(placement_id) {
            const selected_placement_id =
                this.subjects_selected[placement_id] || false;
            if (!selected_placement_id)
                //return '<i><small class="muted" style="font-weight: normal">Bitte Fach wählen &rarr;</small></i>'
                return '';
            const placementsubject = this.placements[placement_id]
                .placementsubjects[selected_placement_id];
            return `${placementsubject.approved_hours -
                placementsubject.held_hours} / ${
                placementsubject.approved_hours
            } h`;
        },
        // return the base64 signature string if it is set for the given placement ID or otherwise return null
        getSignature(placement_id) {
            return _.get(
                this.placements,
                '[' +
                    placement_id +
                    ']._appointments_placement[' +
                    this.getAppointmentId() +
                    '].teacher_signature',
                null
            );
        },
        /*
         * gathers a list of phone numbers
         * - from the family (where mother and father phone numbers are set on the same row as the rest of the family information (like father/mother's name + address etc.))
         * - student phone numbers of all placements (conditioned by the fact that the student has to be >= 16 years)
         */
        getPhoneNumbers() {
            let numbers = {};

            _.each(this.getSelectedPlacements(), placement => {
                const student = placement.student;
                if (student.age >= 16) {
                    _.forEach(['phone1', 'phone2'], key => {
                        if (student[key] !== null) {
                            numbers[`s:${student.id}`] = {
                                name: student.first_name,
                                number: student[key],
                                source: 'student',
                                student_id: student.id,
                                selected:
                                    student[key] ===
                                    this.appointment
                                        .signature_request_target_number, // only for the usage within the select box
                            };
                        }
                    });
                }

                const is_family = this.$root.userInfo.is_family;
                const family = placement.family;
                _.forEach(
                    [
                        {
                            label: 'Vater',
                            phone_key: 'father_phone',
                            name_key: 'father_first_name',
                            enumKey: 'father',
                        },
                        {
                            label: 'Mutter',
                            phone_key: 'mother_phone',
                            name_key: 'mother_first_name',
                            enumKey: 'mother',
                        },
                    ],
                    obj => {
                        if (family[obj.phone_key] !== null || is_family) {
                            // is_family: this means we got a logged in family; in that case the phone number dropdown shall ALWAYS show "mother" & "father". So in case is_family is true: make sure we always have both entries, even if there is no number in the DB
                            numbers[`f:${family.id}:${obj.enumKey}`] = {
                                name: family[obj.name_key],
                                number: family[obj.phone_key],
                                source: 'family',
                                family_id: family.id,
                                parent_type: _.get(
                                    this.$root,
                                    `enums.byKey.parent_types.${obj.enumKey}`,
                                    null
                                ), // -1 = father; -2 = mother
                                parent_type_label: obj.label,
                                selected:
                                    family[obj.phone_key] ===
                                    this.appointment
                                        .signature_request_target_number,
                            };
                        }
                    }
                );
            });

            return numbers;
        },
        getFamily() {
            // get the family of the first selected placement (because all placements MUST belong to the same family by definition)
            if (_.get(this.placements_selected, '[0]', false)) {
                return this.placements[this.placements_selected[0]].family;
            }
            return false;
        },
        getAppointmentLocation() {
            switch (this.location_src_select) {
                case this.locationSourceEnums['at_students_home']:
                    return this.studentLocation;
                    break;
                case this.locationSourceEnums['freetext']:
                    return this.location_free_text;
                    break;
            }
            return '-';
        },
        sendLink() {
            if (!this.isTeacherEditingAppointmentMode) return;
            axios
                .post(
                    `${config.backend_APP_api_url}/appointments/send-signature-link/` +
                        this.getAppointmentId(),
                    {
                        //'phone_number_selected' : this.phone_number_selected,
                        phone_number_selected: null, // temp
                    }
                )
                .then(res => {
                    let appointment = res.data.appointment;
                    this.status = appointment.status;
                    this.signature_request_time =
                        appointment.signature_request_time;
                    this.signature_valid_until =
                        appointment.signature_valid_until;
                    this.appointment.signature_request_token =
                        appointment.signature_request_token;

                    //this.showSnackbar('Der Link wurde erfolgreich versendet!', 2)
                    this.showSnackbar(
                        'Der Link wurde erfolgreich generiert!',
                        2
                    );
                });
        },
        allSignaturesSet() {
            // returns true / false whether all signature fields are set
            let ass = true;
            _.forEach(this.$refs.signaturePads, (signaturePad, index) => {
                if (typeof signaturePad.sig !== 'function')
                    // the VueSignaturePad is a wrapper component for a none vue signature pad. The 'sig' variable within is the vue-component's internal js instance of the (none vue) signature pad. If this internal instance is not ready yet 'sig' will be a function - if it's ready it will be an instance of a vue component...
                    ass = ass && !signaturePad.isEmpty(); // if this causes "_this.sig.isEmpty is not a function" see: https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/4a4cb92069e6fa0f9b60668e9918ac4cf0e56b87/src/pages/termin.vue#L1641
            });
            return ass;
        },
        clearSignature(index = null) {
            if (index === null) this.$refs.signaturePads.clear();
            //Signature()
            else this.$refs.signaturePads[index].clear(); //Signature()
        },
        allowedDates(date) {
            // block dates/months that have already been invoiced
            const ym = moment(date).format('Y-M');
            this.currently_selected_month_invoiced =
                _.find(this.invoiced, { asKey: ym }) !== undefined;
            return !this.currently_selected_month_invoiced;
        },
        subjectsItemized(placement_id) {
            // the subjects that are approved for the placement (determined by the given id)
            let aSubjects = [];
            let currentSubjects = _.get(
                this.placements,
                '[' + placement_id + '].placementsubjects',
                false
            );
            const translation_map = {
                'Deutsch als Fremdsprache': 'DaF',
            };
            if (currentSubjects !== false) {
                _.forEach(currentSubjects, placementsubject => {
                    const subject_name = placementsubject.schoolsubject.name;
                    aSubjects.push({
                        text: _.get(
                            translation_map,
                            subject_name,
                            subject_name
                        ), // this will simply use the translation map where possible and just use the original (untranslated) value if no translation exists
                        value: placementsubject.id,
                    });
                });
            }
            return aSubjects;
        },
        // if currently editing and page is opened with an (appointment) ID - this method will return that ID or otherwise return null
        getAppointmentIdFromRequest() {
            return _.get(
                this.$router,
                'currentRoute.params.appointmentId',
                null
            );
        },
        getSignatureTokenFromRequest() {
            return _.get(
                this.$router,
                'currentRoute.params.signatureToken',
                null
            );
        },
        getSignatureSource() {
            return _.get(this.$router, 'currentRoute.meta.is', null);
        },
        getAppointmentId() {
            if (!this.appointment_found) return false;
            return this.appointment.id;
        },
        getSignatureToken() {
            if (!this.appointment_found) return false;
            return this.appointment.parent_signature_token;
        },
        closeDialog(id) {
            this.$set(this.dialogs, id, false);
        },
        timepickerAllowedHours() {
            const from_time = moment(
                this.time_start,
                this.moment_time_format_HM
            );
            return function(chosen_hour) {
                return chosen_hour >= from_time.format('h');
            };
        },
        timepickerAllowedMinutes(settings) {
            if (settings.is === 'start')
                return function(chosen_minute) {
                    return chosen_minute % settings.interval === 0;
                };
            else if (settings.is === 'end') {
                const from_time = moment(
                    settings.after,
                    this.moment_time_format_HM
                ); // from / start time (used as reference)
                const to_time = moment(
                    this.time_end,
                    this.moment_time_format_HM
                );

                return function(chosen_minute) {
                    // other versions: https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/df72ef935f21ab7326e5758cd527a41bb310988a/src/pages/termin.vue#L1309
                    return (
                        (chosen_minute - from_time.format('mm')) %
                            settings.interval ===
                        0
                    );
                };
            }
        },
        deletionDone(appointment) {
            if (appointment === -1)
                // physical deletion (real record deletion from db ((admins only))
                this.$router.push('/terminuebersicht');
            else this.status = appointment.status;
        },
        revokeCancellation() {
            this.$dialog
                .confirm(
                    'Sind Sie sicher, dass Sie die Absage zurücknehmen möchten?',
                    {
                        okText: 'Ja, zurücknehmen',
                        cancelText: 'Abbrechen',
                    }
                )
                .then(dialog => {
                    axios
                        .post(
                            `${config.backend_APP_api_url}/appointments/undelete/${this.appointment.id}`
                        )
                        .then(res => {
                            const appointment = res.data.appointment;
                            this.status = appointment.status;
                            this.appointment = appointment;
                            this.showSnackbar(
                                'Die Absage wurde zurückgenommen.',
                                2
                            );
                        });
                })
                .catch(() => {});
        },
        validate() {
            if (this.$refs.form.validate()) {
                if (this.isNewAppointmentMode) {
                    // this . mode = 1 // so after saving a fresh new appointment this will cause updates instead of fresh new creations when reclicking the button
                    this.disable_save = true;
                }

                let teacher_signatures = null;
                let parent_signature = null;

                // teacher signatures (mode 1)
                if (_.isArray(this.$refs.signaturePads)) {
                    // should use  && this.isTeacher but this will break stuff if we are admin
                    teacher_signatures = {};
                    _.forEach(
                        this.$refs.signaturePads,
                        function(signaturePad, index) {
                            if (!signaturePad._isVue)
                                // because this could also be an empty div (through an hack)
                                return;
                            if (signaturePad.isEmpty())
                                teacher_signatures[
                                    this.placements_selected[index]
                                ] = null;
                            else
                                teacher_signatures[
                                    this.placements_selected[index]
                                ] = signaturePad.save(); //Signature().data
                        }.bind(this)
                    );
                } else if (this.$refs.signaturePads) {
                    // for parent signature (because we use the same signature pad..) (mode 2)
                    if (this.$refs.signaturePads.isEmpty()) {
                        Vue.dialog.alert(
                            'Sie müssen unterschreiben bevor Sie speichern können.',
                            {
                                okText: 'OK',
                            }
                        );
                        return;
                    } else parent_signature = this.$refs.signaturePads.save();
                }

                let postData = _.merge(
                    {
                        placementsubjects_selected: this.subjects_selected,
                    },
                    _.pick(this.$data, [
                        'date',
                        'form_selected',
                        'location_free_text',
                        'location_selected',
                        'location_src_select',
                        //'mode',
                        'phone_number_selected',
                        'placements_selected',
                        'repetitive_selected',
                        'status',
                        //'subjects_selected',
                        //'teacher_signatures',
                        'time_end',
                        'time_start',
                        //'type_selected',
                        'use_as_std_signature',

                        'parent_comment',
                        'teacher_comment',
                    ]),
                    _.pickBy(
                        {
                            // pickby -> throw out null-data structs
                            teacher_signatures: teacher_signatures,
                            parent_signature: parent_signature,
                        },
                        _.identity
                    )
                    /*_.pickBy({
                        'parent_comment' : this.parent_comment,
                        'teacher_comment' : this.teacher_comment,
                    }, _.identity)*/
                );

                // enforce numeric array keys for the selected subjects
                /*_.mapKeys(postData['subjects_selected'], function(value, key) {
                    return parseInt(key)
                })*/

                let query = '';
                if (this.isNewAppointmentMode) query = '/save';
                if (this.isTeacherEditingAppointmentMode)
                    query = '/save/' + this.getAppointmentIdFromRequest();
                else if (
                    this.isParentSignatureMode ||
                    this.isParentEditingAppointmentMode
                ) {
                    //if(this.$root.userInfo.is_family)
                    if (this.getAppointmentId() !== null)
                        query =
                            '/save-parent-signature/-/' +
                            this.getAppointmentId();
                    else if (this.getSignatureToken() !== null)
                        query =
                            '/save-parent-signature/' +
                            this.getSignatureToken();
                } else if (this.isTeacherSignatureMode) {
                    query =
                        '/save-teacher-signature/' +
                        this.getSignatureTokenFromRequest();
                }

                axios
                    .post(
                        `${config.backend_APP_api_url}/appointments${query}`,
                        postData
                    )
                    .then(res => {
                        let appointment = res.data.appointment;

                        // if the server-side controller determines that the appointment has been moved to the future, it resets the teacher signatures due saving of the appointment
                        // -> so here we check for that said condition: if the updated appointment has null'ed teacher signatures: empty our teacher signature pads here as well
                        if (!this.isNewAppointmentMode) {
                            _.each(
                                appointment.placements,
                                (placement, index) => {
                                    const teacher_signature = _.get(
                                        placement,
                                        '_appointments_placement[' +
                                            this.getAppointmentId() +
                                            '].teacher_signature',
                                        null
                                    );
                                    if (teacher_signature === null) {
                                        if (_.isArray(this.$refs.signaturePads))
                                            // => means that we are in teacher signature mode
                                            this.$refs.signaturePads[
                                                index
                                            ].clear();
                                        // => means that we are in parent signature mode
                                        else this.$refs.signaturePads.clear();
                                    }
                                }
                            );
                        }

                        this.status = appointment.status;
                        //this.appointment.signature = appointment.signature
                        //this.signature = appointment.signature
                        this.appointment = appointment;

                        /*
                         * also update the placement data because it may be changed by the backend
                         * (for example when a teacher sets or revokes his signature the remaining hours are recalculated - so without re-fetching the placement data the UI won't update this information)
                         */

                        if (res.data.success) {
                            axios
                                .get(
                                    `${config.backend_APP_api_url}/appointments/placements`
                                )
                                .then(res => {
                                    this.placements = res.data;
                                });

                            /*
                             * show feedback & conditionally redirect
                             */
                            let msg = '';
                            if (
                                this.isNewAppointmentMode ||
                                this.isTeacherEditingAppointmentMode
                            )
                                msg =
                                    'Der Termin wurde erfolgreich ' +
                                    (this.isNewAppointmentMode
                                        ? 'angelegt'
                                        : 'bearbeitet') +
                                    '!';
                            else if (
                                this.isParentSignatureMode ||
                                this.isTeacherSignatureMode ||
                                this.isParentEditingAppointmentMode
                            )
                                msg =
                                    'Ihre Unterschrift wurde gespeichert. Danke!';
                            this.showSnackbar(msg, 2);

                            if (
                                this.isNewAppointmentMode &&
                                !this.isParentSignatureMode &&
                                !this.isDev()
                            )
                                this.$router.push('/terminuebersicht');
                        } else {
                            if (
                                res.data.reason === 'teacher_signature_revoked'
                            ) {
                                this.appointment_found = false;
                            }
                        }
                    })
                    .catch(function(error) {
                        console.info(error);
                        if (error.response) {
                            this.showSnackbar('Fehler beim speichern.', 2);
                        }
                    });
            }
        },
    },
};
</script>
