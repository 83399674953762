export default {
    backend_APP_api_url: {
        '192.168.110.88:8080': 'http://schuna.jbll/api/app',
        // 'localhost:8080' : 'http://schuna.lola74/api/app',
        'localhost:8080': 'http://localhost:8000/api/app',
        'schuna-app-dev.storms-media.de':
            'https://schuna-dev.storms-media.de/api/app',
        'app.schuna.de': 'https://schuna.storms-media.de/api/app',
    }[window.location.host],
    backend_api_url: {
        '192.168.110.88:8080': 'http://schuna.jbll/api',
        // 'localhost:8080': 'http://schuna.lola74/api',
        'localhost:8080': 'http://localhost:8000/api',
        'schuna-app-dev.storms-media.de':
            'https://schuna-dev.storms-media.de/api',
        'app.schuna.de': 'https://schuna.storms-media.de/api',
    }[window.location.host],

    // https://momentjs.com/docs/#/displaying/
    moment_time_format_full: 'HH:mm:ss',
    moment_time_format_HM: 'HH:mm',
    moment_date_format: 'dd. DD. MMM', // defined by schuna
};
