<template>
    <v-container>
        <v-row>
            <v-col>
                <lkrechnungsuebersicht></lkrechnungsuebersicht>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import lkrechnungsuebersicht from '@/components/lkrechnungsuebersicht.vue';

export default {
    components: {
        lkrechnungsuebersicht
    },
    data : () => ({ }),
    mounted () {}
}
</script>
