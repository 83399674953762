<!--
version with icons:
https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/737cf43d00fd69c5ed7dcb01ec5ea5174a87de77/src/components/partials/event-entry-views/entry-shrinked.vue
-->
<template>
    <div class="d-flex appointment-entry"
         @click="$router.push(`/termin/${appointment.id}`)"
         style="justify-content: space-between; cursor: pointer">
        <div class="entry-info">
            <b style="color: rgb(100, 100, 100)">
                {{getResolvedAppointmentTypeLabel(appointment.type)}} mit:
            </b>
            <h3>
                Fa. {{getFNameForFId(appointment.family_id)}}:
                <template v-if="appointment.student_ids.length === 1">Einzel</template>
                <template v-else>Gruppe</template>
                mit
                {{getSNamesForSIds(appointment.student_ids) | formatStudents}}
            </h3>
            <div>
                {{getAddress(appointment.address)}}
            </div>
            <div class="clock-time-container">
                {{moment(appointment.start, moment_time_format_full).format(moment_time_format_HM)}} &mdash; {{moment(appointment.end, moment_time_format_full).format(moment_time_format_HM)}} Uhr
                /
                {{getResolvedAppointmentRepetition(appointment.repetitivity).label}}
            </div>
            <div class="status-container d-flex" :style="{ color : getResolvedAppointmentStatus(appointment.status).color }">
                <v-icon class="pr-1 float-left">{{getResolvedAppointmentStatus(appointment.status).icon}}</v-icon>
                {{getResolvedAppointmentStatus(appointment.status).label}}
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .v-application.terminuebersicht,
    .v-application.dashboard
    {
        .appointment-entry {
            color: black;
            .v-icon {
                font-size: 15px;
                color: inherit;
            }
        }
        .v-card__text {
            padding: 5px !important;
        }
    }
</style>

<script>

import entryCommons from './entry-commons'

export default {
    mixins : [entryCommons]
};
</script>
