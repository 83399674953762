<template>
    <v-container grid-list-lg>
        <v-card height="100%">
            <v-card-title
                :style="{ 'background-color': colors.blue }"
                style="line-height: normal; font-size: 1em"
            >
                schuNa Termine & Vermittlungen
            </v-card-title>
            <v-form @submit.prevent="login" ref="form" v-model="valid">
                <v-card-text class="pt-0 pb-0">
                    <v-text-field
                        validate-on-blur
                        label="E-Mail-Adresse"
                        required
                        :rules="emailRules"
                        type="email"
                        v-model="email"
                    >
                    </v-text-field>
                    <v-text-field
                        validate-on-blur
                        label="Passwort"
                        required
                        :rules="[v => !!v || 'Bitte Ihr Passwort eingeben']"
                        type="password"
                        v-model="password"
                    >
                    </v-text-field>
                    <b
                        style="color: red"
                        v-if="$store.state.status === 'error'"
                    >
                        {{ $store.state.msg }}
                    </b>
                </v-card-text>
                <v-card-actions class="pb-3 pl-3 pt-0">
                    <v-btn
                        :loading="isSending"
                        color="primary"
                        type="submit"
                        mb-2
                        >einloggen</v-btn
                    >
                </v-card-actions>
            </v-form>
            <v-expand-transition>
                <v-card
                    v-if="show_password_reset"
                    class="transition-fast-in-fast-out v-card--reveal d-flex"
                    style="height: 100%;"
                >
                    <v-form
                        @submit.prevent="pwReset"
                        ref="pwResetForm"
                        v-model="pwResetValid"
                        v-if="!password_reset_success"
                        id="pwResetForm"
                    >
                        <v-card-text class="pb-0">
                            <h3 class="mb-2">Passwort vergessen?</h3>
                            <v-text-field
                                validate-on-blur
                                label="E-Mail-Adresse"
                                required
                                :rules="emailRules"
                                type="email"
                                v-model="pwResetEmail"
                            >
                            </v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                :loading="isSending"
                                color="primary"
                                type="submit"
                                mb-2
                                block
                                >Passwort zurücksetzen</v-btn
                            >
                        </v-card-actions>
                    </v-form>
                    <v-card-text
                        v-else
                        class="text-center"
                        style="align-self: center; font-weight: bold"
                    >
                        Ihre Anfrage zum Zurücksetzen des Passworts wurde
                        erfolgreich in Auftrag gegeben. Sie werden in Kürze eine
                        E-Mail mit Instruktionen und weiteren Schritten
                        erhalten.
                    </v-card-text>
                </v-card>
            </v-expand-transition>
        </v-card>
        <div class="mt-4 text-center">
            <small>
                <a
                    v-if="!show_password_reset"
                    @click.prevent="show_password_reset = true"
                    href="#"
                >
                    Passwort vergessen
                </a>
                <a
                    v-else
                    @click.prevent="
                        show_password_reset = false;
                        isSending = false;
                    "
                    href="#"
                >
                    Zurück zum Login
                </a>
            </small>
        </div>
        <!--<v-snackbar v-model="snackbar">
            {{snackbar_text}}
        </v-snackbar>-->
    </v-container>
</template>

<style lang="scss">
.v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
}

#pwResetForm {
    align-self: center;
    width: 100%;
}

.v-application.login {
    .v-main__wrap {
        display: flex;
        align-items: center;
        justify-content: center;

        .sm_router-view {
            width: 95%;
        }
    }

    .v-card__title {
        color: white;
        margin-bottom: 20px;
        .headline {
            //font-weight: bold;
            font-size: 1em !important;
        }
    }
}
</style>

<script>
import config from '@/config';
import colors from '@/misc/colors';
import axios from 'axios';

export default {
    data() {
        return {
            valid: false,
            email: this.isDev() ? 'ibrahim.machlah@schuna.de' : '',
            password: this.isDev() ? 'test' : '',
            isSending: false,
            emailRules: [
                v => !!v || 'Bitte geben Sie Ihre E-Mail-Adresse an',
                v =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    'Bitte geben Sie eine gültige E-Mail-Adresse an',
            ],

            show_password_reset: false,
            pwResetValid: false,
            pwResetEmail: this.isDev() ? 'ibrahim.machlah@schuna.de' : '',

            password_reset_success: false,
        };
    },
    methods: {
        login() {
            if (this.$refs.form.validate()) {
                this.valid = true;
                this.isSending = true;
                let email = this.email;
                let password = this.password;
                this.$store
                    .dispatch('login', { email, password })
                    .then(() => {
                        this.isSending = false;
                        this.$router.push('/');
                    })
                    .catch(() => (this.isSending = false));
            }
        },
        pwReset() {
            if (this.$refs.pwResetForm.validate()) {
                this.pwResetValid = true;
                this.isSending = true;
                const email = this.pwResetEmail;

                axios
                    .post(`${config.backend_APP_api_url}/password-reset`, {
                        email: email,
                    })
                    .then(res => {
                        /*
                         * reset success
                         */
                        this.password_reset_success = true;
                    })
                    .catch(error => {
                        /*
                         * reset error
                         */
                        this.isSending = false;
                        this.showSnackbar(
                            error.response.data.errors.email[0],
                            5
                        );
                    });
            }
        },
    },
    computed: {
        colors: () => colors.colors,
        //store : () => { this.$store }
    },
};
</script>
