<template>
    <div>
        <template v-if="isAppAdmin">
            <v-icon @click="admin_filter_dialog=true">mdi-cash-multiple</v-icon>

            <v-dialog v-model="admin_filter_dialog" persistent>
                <v-card>
                    <v-card-title class="d-block pb-0" style="line-height: normal; padding-left: 5px; padding-right: 5px">
                        <v-col class="pt-0">
                            Rechnungen filtern
                        </v-col>
                    </v-card-title>
                    <v-card-text class="pt-0">
                        <v-col class="pt-0">
                            <p>
                                Bitte wählen Sie für welchen Zeitraum die Rechnungen angezeigt werden sollen.
                            </p>

                            <v-form ref="mpumcform">
                                <div v-if="!invoice_time_frames">
                                    <v-progress-circular
                                        size="15"
                                        width="2"
                                        class="mr-2"
                                        indeterminate
                                        color="primary">
                                    </v-progress-circular>
                                    <b>Lade Rechnungszeitfenster</b>
                                </div>
                                <template v-else>
                                    <v-select
                                        v-model="timeframe_selected"
                                        hide-details="auto"
                                        :items="invoice_time_frames_itemized"
                                        label="Zeitfenster">
                                    </v-select>
                                    <a class="mt-2 d-inline-block" @click="resetFilter" href="javascript:;" v-if="is_admin_list">Filter zurücksetzen</a>
                                </template>
                            </v-form>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red darken-1"
                            text
                            @click="admin_filter_dialog = false ; timeframe_selected = null">
                            Abbrechen
                        </v-btn>
                        <v-btn
                            color="green lighten-1"
                            text
                            @click="setInvoiceFilter">
                            Filtern
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!--<v-dialog v-model="admin_filter_dialog" persistent>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="admin_filter_dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>LK Rechnungen</v-toolbar-title>
                </v-toolbar>
                <v-card tile>
                    <v-col>
                        ...
                    </v-col>
                </v-card>
            </v-dialog>-->

        </template>
        <generic-filter-nav></generic-filter-nav>
    </div>
</template>

<script>

import generic_filter_nav from './generic-filter-nav'
import _ from 'lodash';
import axios from 'axios';
import config from '../config';
import EventBus from '../main';

export default {
    data : () => {
        return {
            admin_filter_dialog : false,
            invoice_time_frames : null,
            timeframe_selected : null,

            is_admin_list : false,
        }
    },
    components: {
        'generic-filter-nav' : generic_filter_nav
    },
    computed : {
        isAppAdmin() {
            return _.get(this.$root, 'userInfo.is_app_admin', null)
        },
        invoice_time_frames_itemized () {
            if(this.invoice_time_frames) {

                let items = {}

                _.forEach(this.invoice_time_frames, (timeframe) => {
                    items[timeframe.asKey] = {
                        'text' : timeframe.formatted,
                        'value' : timeframe.asKey
                    }
                })

                return _.values(_.orderBy(items, ['text'], ['asc']))
            }
            return []
        }
    },
    methods : {
        setInvoiceFilter () {
            this.admin_filter_dialog = false
            EventBus.$emit('set-invoice-filter', this.timeframe_selected)
            this.is_admin_list = true
        },
        resetFilter () {
            this.timeframe_selected = null
            this.admin_filter_dialog = false
            EventBus.$emit('reset-invoice-filter', null)
            this.is_admin_list = false
        }
    },
    watch : {
        admin_filter_dialog () {
            if(!this.invoice_time_frames) {
                axios.get(`${config.backend_APP_api_url}/teachers/invoice-timeframes`).then(res => {
                    this.invoice_time_frames = res.data
                })
            }
        }
    }
}
</script>
