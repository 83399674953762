<template>
    <div>
        <v-alert v-if="false"
            class="loading-indicator"
            border="top"
            colored-border
            type="info"
            elevation="2">
            Lade Rechnungen ...
            <template v-slot:prepend>
                <v-progress-circular
                    size="15"
                    width="2"
                    class="mr-2"
                    indeterminate
                    color="primary">
                </v-progress-circular>
            </template>
        </v-alert>

        <template>
            <v-row justify="center">
                <v-dialog
                    v-model="filter_dialog"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition">
                    <v-card v-if="invoices !== null" outlined tile class="mb-4">

                        <v-toolbar dark color="primary" class="mb-4">
                            <v-btn icon dark @click="filter_dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn dark text @click="filter_dialog = false">
                                    Anwenden
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <!--<div class="d-flex">
                                <v-select
                                    label="Status"
                                    hide-details="auto"
                                    v-model="filters.status.val"
                                    :items="statusesItemized"
                                    @change="() => {filters.status.active = true}"
                                >
                                </v-select>
                                <v-btn fab x-small color="red" dark style="align-self: flex-end" class="ml-5"
                                       v-show="filters.status.active"
                                       @click="filters.status.active = false ; filters.status.val = -1">
                                    <v-icon>mdi-filter-remove-outline</v-icon>
                                </v-btn>
                            </div>-->
                            <div class="d-flex">
                                <v-select
                                    label="Jahr"
                                    hide-details="auto"
                                    v-model="filters.years.val"
                                    :items="yearsItemized"
                                    @change="() => {filters.years.active = true}"
                                >
                                </v-select>
                                <v-btn fab x-small color="red" dark style="align-self: flex-end" class="ml-5"
                                   v-show="filters.years.active"
                                   @click="filters.years.active = false ; filters.years.val = -1">
                                    <v-icon>mdi-filter-remove-outline</v-icon>
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>

        <v-row v-if="invoices !== null">
            <v-col>
                <small class="text-muted muted d-block mb-2 text-right">
                    &mdash; Rechnungen insgesamt: <b>{{Object.keys(invoices).length}}</b>
                    <div v-if="filtersActive.length !== 0 && invoices_filtered_count > 0">
                        &mdash; nach Filter: <b>{{invoices_filtered_count}}</b>
                    </div>
                </small>
            </v-col>
        </v-row>

        <div class="centered-floating-box">
            <v-alert v-if="invoices && filtersActive.length !== 0 && invoices_filtered_count === 0" border="top" color="red lighten-2" dark>
                Es gibt keine Rechnungen die auf die gewählten Filterkriterien passen.
            </v-alert>
            <v-alert v-else-if="invoices && invoices.length === 0" border="top" color="blue lighten-3" dark>
                Es gibt aktuell noch keine Rechnungen.
            </v-alert>
        </div>

        <div v-if="is_admin_list" id="floating-bar">
            <!--{{teacher.code}} {{teacher.first_name}} {{teacher.last_name}}-->
            Rechnungen für <b>{{moment(current_timeframe).format('MMM Y').replace('.', '')}}</b>
        </div>

        <div class="position-relative separated-boxes-list-group" v-for="(invoices_processed, date, index) in invoices_grouped_filtered(false)" :key="index">
            <!--<b>Rechnungen für {{moment().month(invoices_grouped[0].month).year(invoices_grouped[0].year).format('MMM Y')}}</b>-->
            <v-card v-for="(invoice, index2) in invoices_processed"
                @click="$router.push(`/lkrechnungen/${invoice.id}`)"
                :key="invoice.id"
                :class="{ 'mb-2' : index2 !== invoices_processed.length-1, 'mb-4' : index2 === invoices_processed.length-1 }"
                class="invoice-entry">
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="auto">
                            <h3>
                                <template v-if="is_admin_list">
                                    {{invoice.teacher.code}} {{invoice.teacher.first_name}} {{invoice.teacher.last_name}}
                                </template>
                                <template v-else>
                                    Rechnung für {{moment().month(invoice.month-1).year(invoice.year).format('MMM Y').replace('.', '')}}
                                </template>
                            </h3>
                            STATUS
                        </v-col>
                        <v-col class="text-right">
                            {{(Math.round(invoice.total * 100) / 100).toFixed(2)}} &euro;
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        <!--
        <v-footer app>
            <v-btn color="red lighten-2"
               fab small>
                <v-icon>
                    mdi-close-box-outline
                </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
        </v-footer>
        -->
    </div>
</template>

<style lang="scss">
    .invoice-entry * {
        color: black
    }

    // Mobile devices (landscape, portrait)
    @media (pointer:coarse) and (max-width: 575.98px) {
        #floating-bar {
            position: sticky;
            z-index: 4;
            background-color: rgba(255,255,255,0.6);
            backdrop-filter: blur(5px);
            top: 56px;
            padding-top: 8px;
            left: 15px;
            right: 15px;
            margin-bottom: 10px;
        }
    }
</style>

<script>
import config from '@/config'
import axios from 'axios'
import colors from '@/misc/colors'
import _ from 'lodash'

import EventBus from '../main';
import moment from 'moment';

export default {
    components: {},
    data : () => ({

        filter_dialog : false,

        invoices : null,

        filters : {
            status : {
                active : false,
                val : -1,
                filter_handler : function($instance, invoice) {
                    //return $instance.getFamily(placement).id === this.val
                    return true
                }
            },
            years : {
                active : false,
                val : -1,
                filter_handler : function($instance, invoice) {
                    return invoice.year === this.val
                }
            },
        },

        is_admin_list : false,

        invoices_filtered_count : null, // cache var which will be set by the filter/group function

        current_timeframe : null,

    }),
    computed : {

        teacher () {
            // we could also use teachersReady in order to access all teachers and use the current selected..

            return _.values(this.invoices_grouped_filtered())[0][0].teacher
        },

        isAppAdmin () {
            return _.get(this.$root, 'userInfo.is_app_admin', null)
        },

        statusesItemized () {
            let dataItemized = ['Not yet Implemented (NYI)']

            /*const avail_statuses = _.uniq(_.map(this.placements, 'status_id'))

            _.forEach(avail_statuses, (status_id) => {
                dataItemized.push({
                    'text' : this.getResolvedPlacementStatus(status_id).label,
                    'value' : status_id
                })
            })*/

            return dataItemized
        },

        yearsItemized () {
            let yearsItemized = {}
            _.forEach(this.invoices, (invoice) => {
                yearsItemized[invoice.id] = {
                    'text' : invoice.year,
                    'value' : invoice.year
                }
            })
            return _.values(_.orderBy(yearsItemized, ['text'], ['asc']))
        },

        /*
         * return all filters from the filter object that are set to active
         * TODO copied from terminuebersicht.vue
         */
        filtersActive () {
            return _.filter(this.filters, function (filter, filter_name) {
                return filter.active
            })
        },

    },
    created () {},
    mounted () {

        EventBus.$on('toggle-filters', () => {
            this.filter_dialog = true
        })

        EventBus.$on('set-invoice-filter', (timeframe) => {
            axios.get(`${config.backend_APP_api_url}/teachers/invoices/${timeframe}`).then(res => {
                this.invoices = res.data
                this.is_admin_list = true
                this.current_timeframe = timeframe
            })
        })

        const getInvoices = () => {
            /*const tf_year = _.get(this.$router, 'currentRoute.params.year', null)
            const tf_month = _.get(this.$router, 'currentRoute.params.month', null)

            let req_url = null

            if(tf_year && tf_month) {
                //const timeframe = `${tf_year}-${tf_month}`
                req_url = `${config.backend_APP_api_url}/teachers/invoices/${tf_year}-${tf_month}`
            }
            else*/
                let req_url = `${config.backend_APP_api_url}/teachers/invoices`

            axios.get(req_url).then(res => {
                this.invoices = res.data
                this.is_admin_list = false
                this.current_timeframe = null
            })
        };

        EventBus.$on('reset-invoice-filter', (timeframe) => {
            getInvoices()
        })

        getInvoices()

    },
    methods : {

        invoices_grouped_filtered (ignore_filters = false) {

            let active_filters = ignore_filters ? {} : this.filtersActive

            let count = 0

            let invoices_grouped_filtered = {}
            _.forEach(this.invoices, (invoice, invoice_id) => {

                let match = true // ... through active filters
                _.forEach(active_filters, filter => {
                    if(!filter.filter_handler(this, invoice))
                        match = false
                })

                if(match) {
                    count++
                    const group_key = invoice.year + '-' + invoice.month
                    if(!invoices_grouped_filtered[group_key])
                        invoices_grouped_filtered[group_key] = []
                    invoices_grouped_filtered[group_key].push(invoice)
                }

            })

            this.invoices_filtered_count = count // cache the filter result amount in order to spare massive recalculation every time we just need the count of filtered results

            return invoices_grouped_filtered

        },

    }
};
</script>
