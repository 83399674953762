<template>
    <v-container>
        <v-row>
            <v-col>
                <h3>Willkommen {{user.first_name}}!</h3>
                <!--<small class="muted">Lehrer für {{teacherSubjects}}</small>-->
                <div>Heute ist {{day}}, der {{date}}</div>
                <!--<div>Es ist {{time}} Uhr</div>-->
                <div v-if="user.is_app_admin && user.sudo_id !== null" class="mt-3" style="font-style: italic; color: red;">
                    Sie verwalten gerade Daten des Lehrers:
                    <br>
                    {{sudoTeacher.first_name}} {{sudoTeacher.last_name}}
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="mx-auto d-flex">
                    <v-icon x-large color="white" class="px-2" style="background-color: #9fc5f8">
                        mdi-calendar
                    </v-icon>
                    <div>
                        <v-card-text style="padding: 16px !important; padding-bottom: 0 !important;">
                            <!--<div>01</div>-->
                            <h3 class="text--primary">Termine</h3>
                            <!--<p>adjective</p>-->
                            <div class="text--primary">
                                <span v-if="appointments.count === 0">
                                    Heute stehen <b>keine</b> Termine an!
                                    <hr/>
                                    <template v-if="appointments.next !== -1">
                                        Der nächste Termin ist für
                                        {{moment(appointments.next.date).format(moment_date_format)}}
                                        um
                                        {{moment(appointments.next.start, moment_time_format_full).format(moment_time_format_HM)}} &mdash; {{moment(appointments.next.end, moment_time_format_full).format(moment_time_format_HM)}} Uhr
                                        <!--
                                        mit
                                        Fam. {{appointments.next.family.last_name}}-->
                                        datiert.
                                    </template>
                                    <template v-else>
                                        Es gibt aktuell keine bevorstehenden Termine.
                                    </template>
                                </span>
                                <span v-if="appointments.count === 1">
                                    Heute steht <b>ein</b> Termin an!
                                </span>
                                <span v-if="appointments.count > 1">
                                    Heute stehen {{appointments.count}} Termine an!
                                </span>
                            </div>
                            <div>
                                <ul v-for="(excerpt_group, family_name) in appointments.excerpts" :key="family_name">
                                    <li>Fam. {{family_name}}</li>
                                    <ul>
                                        <li v-for="(excerpt, i) in excerpt_group" :key="i" style="line-height: normal" @click="$router.push(`/termin/${excerpt.id}`)">
                                            {{moment(excerpt.start, moment_time_format_full).format(moment_time_format_HM)}} &mdash; {{moment(excerpt.end, moment_time_format_full).format(moment_time_format_HM)}} Uhr
                                            <br>
                                            <small class="muted"><v-icon x-small>mdi-map-marker</v-icon> {{excerpt.family.address}}</small>
                                        </li>
                                    </ul>
                                </ul>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn to="/terminuebersicht" small plain color="primary darken-2">
                                Zu den Terminen
                            </v-btn>
                        </v-card-actions>
                    </div>
                </v-card>
                <div class="mb-4"></div>
                <v-card class="mx-auto d-flex" v-if="user.is_teacher || user.is_app_admin">
                    <v-icon x-large color="white" class="px-2" style="background-color: #9fc5f8">
                        mdi-account-switch
                    </v-icon>
                    <div>
                        <v-card-text style="padding: 16px !important; padding-bottom: 0 !important;">
                            <h3 class="text--primary">Vermittlungen</h3>
                            <div class="text--primary">
                                Sie haben aktuell {{placements}} Vermittlungen.
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn to="/vermittlungen" small plain color="primary darken-2">
                                Zu den Vermittlungen
                            </v-btn>
                        </v-card-actions>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="scss">
    .v-application {
        ul {
            padding-left: 16px;
            > ul {
                //padding-left: 160px;
                list-style: square;
            }
        }
    }
</style>

<script>

//import terminuebersicht from '@/components/terminuebersicht.vue';

import config from '../config'
import _ from 'lodash'
import axios from 'axios';

export default {
    components: {
        //terminuebersicht
    },
    computed : {
        day () {
            return this.moment().format("dddd")
        },
        date () {
            return this.moment().format("Do MMMM YYYY")
        },
        time () {
            return this.moment().format("H:m:s")
        },
        //getUserInfo : () => getUserInfo
        user : function () {
            return this.$root.userInfo
        },
        teacherSubjects () {
            if(_.get(this.user, 'teacher.studyprograms', false))
                return _.map(this.user.teacher.studyprograms, 'name').join(', ')
            return ''
        },
        sudoTeacher () {
            const teachers = _.get(this.$root, 'teachers')
            if(teachers)
                return _.find(teachers, { 'user_id' : this.user.sudo_id})
            return ''
        }
    },
    data : () => ({
        appointments : 0,
        placements : 0
    }),
    mounted () {
        axios.get(`${config.backend_APP_api_url}/dashboard`).then(res => {
            this.appointments = res.data.appointments
            this.placements = res.data.placements
        })
    }
};
</script>
