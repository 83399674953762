import Vue from 'vue';
import './plugins/axios'
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import VuejsDialog from 'vuejs-dialog';
//import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; // only needed in custom components

var MobileDetectLib = require('mobile-detect'),
    mobileDetect = new MobileDetectLib(window.navigator.userAgent)
//export default mobileDetect

import config from '@/config'

Vue.config.productionTip = false;

import moment from 'moment'
Vue.prototype.moment = moment

// docs: https://github.com/Godofbrowser/vuejs-dialog#readme
Vue.use(VuejsDialog, {
    okText: 'Weiter',
    cancelText: 'Schließen',
    verification: 'Weiter',
    backdropClose: false,
    html : true
});
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import translators from './misc/translators';
import helpers from './misc/helpers';
import _ from 'lodash';
import axios from 'axios';

//import './registerServiceWorker'

Vue.mixin({
    methods: {
        ...translators,
        ...helpers,
        isDev () {
            return window.location.host === 'localhost:8080'
        },
        isPreview () {
            return window.location.host === 'schuna-app-dev.storms-media.de'
        },
        isMobileDevice () {
            return mobileDetect.phone() !== null
        },
        showSnackbar(message, duration = 3) {
            EventBus.$emit('showSnackbar', message, duration) // caught in App.vue
        },
        toClipboard(clipboard_content) {
            const el = document.createElement('textarea');
            el.value = clipboard_content;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            const selected = document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
        }
        /*getNavComponent () {
            return _.get(this.$route, 'matched[0].components.nav.components', false)
        }*/
    },
    data : () => {
        return {
            ... config,
        }
    },
    computed : {
        locationSourceEnums () {
            return _.get(this.$root, 'enums.byKey.location_types', {})
        }
    }
})

axios.interceptors.request.use((config) => {
    config.params = {...config.params, timestamp: moment().unix()}
    return config
})

moment.locale('de')

Vue.prototype.window_location = window.location

const EventBus = new Vue()
export default EventBus

var globalsReadyResolver = null
var teachersReadyResolver = null

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
    data: () => ({
        enums : {},
        userInfo : {}, // will be set by the login component
        globalsReady : new Promise((resolve, reject) => {
            globalsReadyResolver = resolve
        }),
        teachersReady : new Promise((resolve, reject) => {
            teachersReadyResolver = resolve
        }),
        teachers : null
    }),
    methods : {
        loadGlobals () {
            let enums_promise = axios.get(`${config.backend_APP_api_url}/enums`).then(res => this.enums = res.data) // because the parent signature page also needs the enums
            let user_promise = false
            if (store.getters.isLoggedIn)
                user_promise = new Promise((resolveUserPromise, reject) => {
                    axios.get(`${config.backend_api_url}/user`).then((res) => {
                        this.userInfo = res.data

                        if(this.userInfo.is_app_admin) {
                            axios.get(`${config.backend_APP_api_url}/teachers`).then((res) => {
                                this.teachers = res.data
                                resolveUserPromise()
                                teachersReadyResolver()
                            })
                        }
                        else
                            resolveUserPromise()
                    }).catch(error => {
                        const res = error.response
                        if(res.status === 401)
                            this.$store.dispatch('logout')
                    })
                })

            Promise.all([enums_promise, user_promise]).then(() => {
                globalsReadyResolver()
            })
        }
    },
    mounted () {
        this.loadGlobals()
        EventBus.$on('login-finished', async () => {
            this.loadGlobals()
        })
        EventBus.$on('logout-finished', () => {
            router.push('/login')
        })
        EventBus.$on('switch-user', (user_id) => {
            this.userInfo.sudo_id = user_id
            axios.post(`${config.backend_APP_api_url}/switch-user`, {user_id}).then((res) => {
                //window.location.reload()
                //router.push('/dashboard')
                window.location.href = '/'
            })
        })
    }
}).$mount('#app');
