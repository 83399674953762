<template>
    <v-container>
        <v-row>
            <v-col>
                <vermittlungsuebersicht></vermittlungsuebersicht>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import vermittlungsuebersicht from '@/components/vermittlungsuebersicht.vue';

export default {
    components: {
        vermittlungsuebersicht
    },
    data : () => ({ }),
    mounted () {}
}
</script>
