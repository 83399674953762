import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from '@/misc/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes : {
            light: {
                primary: colors.colors.main,
                //secondary: '#424242',
                //accent: '#FF5252',
                //error: '#FF5252',
                //info: '#FF5252',
                //success: '#4CAF50',
                //warning: '#FFC107',
            }
        }
    }
});
