<template>
    <div class="position-relative">
        <v-btn fab x-small color="red" dark
               v-show="filter_type !== -1 && filter_type !== null"
               @click="filter_type = -1 ; $emit('update', filter_type)"
               style="position: absolute; right: 0; top: 0; z-index: 4">
            <v-icon>mdi-filter-remove-outline</v-icon>
        </v-btn>
        <v-radio-group
            :class="css_class || ''"
            v-model="filter_type"
            hide-details
            label="Terminart"
            @change="$emit('update', filter_type)"
        >
            <!--<v-radio
                label="-"
                :value="-1"
            ></v-radio>-->
            <v-radio
                v-for="(val, key) in types"
                :key="key"
                :label="getResolvedAppointmentTypeLabel(key)"
                :value="key"
            ></v-radio>
        </v-radio-group>
    </div>
</template>

<script>

import _ from 'lodash'

export default {
    components: {},
    props : ['css_class'],
    data : () => ({
        filter_type : null,
    }),
    computed : {
        types () {
            if(_.get(this.$root, 'enums.byKey.appointment_types', false) !== false) {
                return this.$root.enums.byVal.appointment_types
            }
            else
                return {}
        }
    },
    created () {},
    mounted () {},
    methods : {
        reset () {
            this.filter_type = null
        }
    }
};
</script>
