import _ from 'lodash'

export default {
    getResolvedAppointmentTypeLabel(type, by='byKey', abbreviation=false) {
        if(_.get(this.$root, 'enums.'+by+'.appointment_types', false) !== false) {
            switch (parseInt(type)) {
                case (this.$root.enums.byKey.appointment_types.first):
                    return abbreviation ? 'ET' : 'Ersttermin'
                case(this.$root.enums.byKey.appointment_types.regular):
                    return abbreviation ? 'NT' : 'Nachhilfetermin'
                default:
                    return '?'
            }
        }
    },
    getResolvedAppointmentCancellationReasonLabel(reason) {
        let reasons = _.get(this.$root, 'enums.byKey.appointment_cancellation_reasons', false)
        if(reasons !== false) {
            switch (parseInt(reason)) {
                case (reasons.parents):
                    return 'Eltern Gründe'
                case (reasons.teacher):
                    return 'Lehrkraft Gründe'
                case (reasons.wrong_date):
                    return 'Termin falsch gesetzt'
                default:
                    return '?'
            }
        }
    },
    getResolvedAppointmentRepetition (repetitivity) {
        //if(typeof repetitivity === 'number') {
            if(_.get(this.$root, 'enums.byKey.appointment_repetitivities', false) !== false) {
                switch (parseInt(repetitivity)) {
                    case (this.$root.enums.byKey.appointment_repetitivities.once):
                        return {
                            'label': 'Einmalig',
                            'icon': 'mdi-numeric-1-circle-outline',
                        }
                    case(this.$root.enums.byKey.appointment_repetitivities.weekly):
                        return {
                            'label': 'Wöchentlich',
                            'icon': 'mdi-calendar-week-begin',
                        }
                    case(this.$root.enums.byKey.appointment_repetitivities.bi_monthly):
                        return {
                            'label': 'Alle zwei Wochen',
                            'icon': 'mdi-calendar-weekend-outline',
                        }
                    default:
                        return {
                            'label': '?',
                            'icon': 'mdi-help',
                        }
                }
            }
            else
                //throw "ENUMS STEHEN NICHT ZUR VERFÜGUNG";
                return {
                    'label': '?',
                    'icon': 'mdi-help',
                }
        //}
        /*else {
            if(_.get(this.$root, 'enums.byVal.appointment_repetitivities', false) !== false) {
                switch (repetitivity) {
                    case (this.$root.enums.byVal.appointment_repetitivities.once):
                        return 'Einmalig'
                    case(this.$root.enums.byVal.appointment_repetitivities.weekly):
                        return 'Wöchentlich'
                    case(this.$root.enums.byVal.appointment_repetitivities.bi_monthly):
                        return 'Alle zwei Wochen'
                }
            }
        }*/
    },
    getResolvedAppointmentPresence (presence) {
        if(_.get(this.$root, 'enums.byKey.appointment_presence_types', false) !== false) {
            switch (parseInt(presence)) {
                case (this.$root.enums.byKey.appointment_presence_types.presence):
                    return {
                        'label': 'Präsenzunterricht',
                        'icon': 'mdi-account-voice',
                    }
                case(this.$root.enums.byKey.appointment_presence_types.online):
                    return {
                        'label': 'Distanzunterricht',
                        'icon': 'mdi-webcam',
                    }
                default:
                    return {
                        'label': '?',
                        'icon': 'mdi-help',
                    }
            }
        }
    },
    getResolvedAppointmentLocation (location_type) {
        switch (location_type) {
            case (this.locationSourceEnums['freetext']):
                return {
                    'label': 'Freitext',
                    'icon': 'mdi-map-marker',
                }
            case(this.locationSourceEnums['at_students_home']):
                return {
                    'label': 'Zuhause beim Schüler',
                    'icon': 'mdi-home',
                }
            default:
                return {
                    'label': '?',
                    'icon': 'mdi-help',
                }
        }
    },
    getResolvedAppointmentStatus (status) {
        if(_.get(this.$root, 'enums.byKey.appointment_statuses', false) !== false) {
            let statuses = this.$root.enums.byKey.appointment_statuses

            let icon__cross = 'mdi-close-circle-outline'
            let icon__dots = 'mdi-dots-horizontal-circle-outline'
            let icon__check = 'mdi-check-circle-outline'

            switch (parseInt(status)) {
                case (statuses.deleted):
                    return {
                        'label': 'Gelöscht',
                        'icon': '',
                        'color': ''
                    };
                case (statuses.cancellation_through_teacher):
                    return {
                        'label': 'Lehrkraftabsage',
                        'icon': icon__cross,
                        'color': '#ff0000'
                    };
                case (statuses.cancellation_through_parents_declined):
                    return {
                        'label': 'Elternabsage abgelehnt',
                        'icon': icon__cross,
                        'color': '#ff0000'
                    };
                case (statuses.cancellation_through_parents_pending):
                    return {
                        'label': 'Elternabsage ausstehend',
                        'icon': icon__dots,
                        'color': '#fa9600'
                    };
                case (statuses.cancellation_through_parents_accepted):
                    return {
                        'label': 'Elternabsage akzeptiert',
                        'icon': icon__check,
                        'color': '#329f78'
                    };
                case (statuses.pending):
                    return {
                        'label': 'Ausstehend',
                        'icon': icon__dots,
                        'color': '#fa9600'
                    };
                case (statuses.teacher_signature_missing):
                    return {
                        'label': 'LK-Unterschrift fehlt',
                        'icon': icon__dots,
                        'color': '#fa9600'
                    };
                case (statuses.signature_missing):
                    return {
                        'label': 'Eltern-Unterschrift fehlt',
                        'icon': icon__dots,
                        'color': '#fa9600'
                    };
                case (statuses.accepted):
                    return {
                        'label': 'Bestätigt',
                        'icon': icon__check,
                        'color': '#329f78'
                    };
                case (statuses.internally_accepted):
                    return {
                        'label': 'Intern Bestätigt',
                        'icon': icon__check,
                        'color': '#329f78'
                    };
                case (statuses.internally_not_accepted):
                    return {
                        'label': 'Intern nicht bestätigt',
                        'icon': icon__cross,
                        'color': '#ff0000'
                    };
                case (statuses.cancellation_through_parents):
                    return {
                        'label': 'Elternabsage',
                        'icon': icon__cross,
                        'color': '#ff0000'
                    };
                default:
                    return {
                        'label': `*STATUS ${status} UNTRANSLATED*`,
                        'icon': 'mdi-help',
                        'color' : '#ff0000'
                    }
            }
        }
        else
            //throw "ENUMS STEHEN NICHT ZUR VERFÜGUNG";
            return {
                'label': '?',
                'icon': 'mdi-help',
            }
    },
    getResolvedPlacementStatus (status) {
        let statuses = null
        if( (statuses = _.get(this.$root, 'enums.byKey.placement_statuses', false)) !== false) {

            let icon__cross = 'mdi-close-circle-outline'
            let icon__dots = 'mdi-dots-horizontal-circle-outline'
            let icon__check = 'mdi-check-circle-outline'
            let icon__door = 'mdi-door-open'

            switch (parseInt(status)) {
                case (statuses.potential_app):
                    return {
                        'label': 'Potenziell',
                        'icon': icon__dots,
                        'color': 'rgb(250,150,0)'
                    }
                case (statuses.expression_of_interest):
                    return {
                        'label': 'Anfrage abgeschickt!',
                        'icon': icon__check,
                        'color': 'rgb(50,159,120)'
                    }
                case (statuses.active):
                    return {
                        'label': 'Aktiv',
                        'icon': icon__check,
                        'color': 'rgb(50,159,120)'
                    }
                case (statuses.interim_phase):
                    return {
                        'label': 'Übergangsphase',
                        'icon': icon__door,
                        'color': 'rgb(255,153,153)'
                    }
                case (statuses.rejected_by_teacher_app):
                    return {
                        'label': 'Abgelehnt',
                        'icon': icon__cross,
                        'color': 'rgb(150,150,150)'
                    }
                case (statuses.terminated_by_teacher):
                case (statuses.terminated_by_schuna):
                    return {
                        'label': 'Beendet',
                        'icon': icon__cross,
                        'color': 'rgb(150,150,150)'
                    }
                default:
                    return {
                        'label': `*STATUS ${status} UNTRANSLATED*`,
                        'icon': 'mdi-help',
                        'color' : '#ff0000'
                    }
            }
        }
        else
            //throw "ENUMS STEHEN NICHT ZUR VERFÜGUNG";
            return {
                'label': '?',
                'icon': 'mdi-help',
            }
    },
};
