<template>
    <div class="centered-floating-box">
        <v-alert border="top" color="red lighten-2" dark>
            <b>404</b>
            <br>
            Seite nicht gefunden
        </v-alert>
    </div>
</template>

<script>
export default {
    components: {},
    data : () => ({}),
};
</script>
