/*
 * ROUTER
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

import dashboard from '../pages/dashboard.vue'

import terminuebersicht from '../pages/terminuebersicht.vue'
import terminuebersicht_nav from '../views/terminuebersicht-nav.vue'
import termin from '../pages/termin.vue'

import vermittlungsuebersicht from '../pages/vermittlungsuebersicht.vue'
import vermittlungsuebersicht_nav from '../views/vermittlungsuebersicht-nav.vue'

import lkrechnungsuebersicht from '../pages/lkrechnungsuebersicht.vue'
import lkrechnungsuebersicht_nav from '../views/lkrechnungsuebersicht-nav'
import lkrechnung from '../pages/lkrechnung.vue'

import Login from "../pages/Login.vue"
import PasswordReset from "../pages/PasswordReset.vue"

import fourOfour from "../pages/404.vue"

import store from '../store'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: dashboard,
        meta: {
            title: {
                'appBar' : '<span>Dash</span><span class="font-weight-light">board</span>',
                'browser' : 'Dashboard',
                'nav' : 'Dashboard'
            },
            'icon' : 'mdi-view-dashboard-outline',
            'bodyClass' : 'dashboard'
        }
    },
    {
        path: '/index.html',
        name: 'Dashboard PWA',
        component: dashboard,
        meta: {
            title: {
                'appBar' : '<span>Dash</span><span class="font-weight-light">board</span>',
                'browser' : 'Dashboard',
                'nav' : null
            },
            'icon' : null,
            'bodyClass' : 'dashboard'
        }
    },
    {
        path: '/terminuebersicht', // better name: "termine"
        name: 'Terminübersicht',
        //component: terminuebersicht,
        components: {
            content : terminuebersicht,
            nav : terminuebersicht_nav
        },
        meta: {
            title: {
                'appBar' : 'Termine',
                'browser' : 'Termine',
                'nav' : 'Termine',
            },
            'icon' : 'mdi-calendar-range',
            'bodyClass' : 'terminuebersicht'
        }
    },
    {
        path: '/termin/neu', // /:baseId?
        name: 'Nachhilfetermin anlegen',
        component: termin,
        meta: {
            disabled : ['family'],
            title: {
                'appBar' : '<span>Neuer</span> <span class="font-weight-light">Termin</span>',
                'browser' : 'Neuer Termin',
                'nav' : 'Neuer Termin'
            },
            'icon' : 'mdi-plus',
            'bodyClass' : 'termin-neu'
        }
    },
    {
        path: '/vermittlungen',
        name: 'Vermittlungsübersicht',
        //component: vermittlungsuebersicht,
        components: {
            content : vermittlungsuebersicht,
            nav : vermittlungsuebersicht_nav
        },
        meta: {
            disabled : ['family'],
            title: {
                'appBar' : 'Vermittlungen',
                'browser' : 'Vermittlungen',
                'nav' : 'Vermittlungen'
            },
            'icon' : 'mdi-account-supervisor',
            'bodyClass' : 'vermittlungsuebersicht'
        }
    },
    {
        path: '/lkrechnungen',
        name: 'Rechnungen',
        //component: ...,
        components: {
            content : lkrechnungsuebersicht,
            nav : lkrechnungsuebersicht_nav
        },
        meta: {
            disabled : ['family'],
            sub : 'Noch in Entwicklung',
            title: {
                'appBar' : 'Rechnungen',
                'browser' : 'Rechnungen',
                'nav' : 'Rechnungen'
            },
            'icon' : 'mdi-file-document-outline',
            'bodyClass' : 'lkrechnungsuebersicht'
        }
    },
    {
        path: '/lkrechnungen/:year-:month',
        name: 'Rechnungen',
        components: {
            content : lkrechnungsuebersicht,
            nav : lkrechnungsuebersicht_nav
        },
        meta: {
            disabled : ['family'],
            title: {
                'appBar' : 'Rechnungen',
                'browser' : 'Rechnungen',
                'nav' : null
            },
            'icon' : 'mdi-file-document-outline',
            'bodyClass' : 'lkrechnungsuebersicht'
        }
    },
    {
        path: '/lkrechnungen/:invoiceId',
        name: 'Rechnung',
        component: lkrechnung,
        meta: {
            disabled : ['family'],
            title: {
                'appBar' : 'Rechnung',
                'browser' : 'Rechnung',
                'nav' : null
            },
            'icon' : 'mdi-file-document-outline',
            'bodyClass' : 'lkrechnung'
        }
    },
    {
        path: '/termin/:appointmentId',
        name: 'Nachhilfetermin bearbeiten',
        component: termin,
        //props: true,
        meta: {
            title: {
                'appBar' : '<span>Nachhilfetermin</span> <span class="font-weight-light">bearbeiten</span>',
                'browser' : 'Nachhilfetermin bearbeiten',
                'nav' : null
            },
            'bodyClass' : 'termin-bearbeiten'
        }
    },
    {
        path: '/termin/elternunterschrift/:signatureToken',
        name: 'Terminunterschrift',
        component: termin,
        meta: {
            allowAnonymous: true,
            title: {
                'appBar' : '<span>Termin</span> <span class="font-weight-light">unterschrift</span>',
                'browser' : 'Terminunterschrift',
                'nav' : null
            },
            'bodyClass' : 'elternunterschrift',
            is : 'parent'
        }
    },
    {
        path: '/termin/lkunterschrift/:signatureToken',
        name: 'Terminunterschrift',
        component: termin,
        meta: {
            allowAnonymous: true,
            title: {
                'appBar' : '<span>Termin</span> <span class="font-weight-light">unterschrift</span>',
                'browser' : 'Terminunterschrift',
                'nav' : null
            },
            'bodyClass' : 'lkunterschrift',
            is : 'teacher'
        }
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            allowAnonymous: true,
            title: {
                'appBar' : '',
                'browser' : 'Login',
                'nav' : null
            },
            'bodyClass' : 'login'
        }
    },
    {
        path: "/logout",
        name: "logout",
        meta: {
            //allowAnonymous: true,
            title: {
                'appBar' : '',
                'browser' : 'Logout',
                'nav' : 'Logout'
            },
            icon : 'mdi-logout',
            class : 'mt-10'
        },
        component: {
            beforeRouteEnter(to, from, next) {
                //logoutUser()
                //this.$store.dispatch('logout')

                Vue.dialog
                    .confirm('Sind Sie sicher, dass Sie sich ausloggen wollen?', {
                        okText: 'Ausloggen',
                        cancelText: 'Abbrechen',
                    })
                    .then(function(dialog) {
                        router.app.$store.dispatch('logout')
                        next('/login')
                    }).catch(()=>{})

            }
        }
    },
    {
        path: "/new-password/:pwResetToken",
        name: "password-reset",
        component: PasswordReset,
        meta: {
            allowAnonymous: true,
            title: {
                'appBar' : '',
                'browser' : 'Passwort zurücksetzen',
                'nav' : null
            },
            'bodyClass' : 'pw-reset'
        }
    },
    {
        path: '*',
        name : '404',
        component: fourOfour ,
        meta: {
            allowAnonymous: true,
            title: {
                'appBar' : '',
                'browser' : '404',
                'nav' : null
            },
            'bodyClass' : '404'
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

//import { isLoggedIn } from '../services/auth'

/*
router.beforeEach((to, from, next) => {
    //console.info(to)
    //console.info(next)
    if (!to.meta.allowAnonymous && !isLoggedIn()) {
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        })
    }
    else if(isLoggedIn() && to.name === 'login') {
        next({
            path: '/',
        })
    }
    else
        next()
})
*/

router.beforeEach((to, from, next) => {
    //if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!to.matched.some(record => record.meta.allowAnonymous)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router;
