import _ from 'lodash';

export default {
    props : [
        'appointment',
        'students',
        'families',
        'addresses'
    ],
    filters : {
        /*
         * convert student names array to an format like this:
         * - two names: Name1 & Name2
         * - more then two names: Name1, Name2 & Name3
         * - (of course single names do not have any separators)
         */
        formatStudents (names) {
            if(names.length > 1) {
                let c_separated = names.join(', ')
                const last_index = c_separated.lastIndexOf(',')
                c_separated = c_separated.substr(0, last_index) + ' & ' + c_separated.substr(last_index+1)
                return c_separated
            }
            else
                return names[0]
        }
    },
    methods : {

        /*
         * @see parent (terminuebersicht.vue)
         */
        getAddress (address) {
            return this.$parent.$parent.getAddress(address) // why the heck $parent.$parent and not just $parent ?!
        },

        // get an array with student names for given student ids
        getSNamesForSIds (student_ids) {
            return _.filter(this.students, (o, id) => {
                return student_ids.indexOf(parseInt(id)) !== -1
            })
        },
        // get the family name for a given id
        getFNameForFId (family_id) {
            return this.families[family_id]
        },
    }
};
