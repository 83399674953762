var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isNewAppointmentMode && _vm.isFamily)?_c('v-container',[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Sie sind "),_c('b',[_vm._v("nicht berechtigt")]),_vm._v(" neue Termine anzulegen. ")])],1):(_vm.appointment_found !== true)?_c('v-container',[(_vm.signature_expired)?_c('div',{staticClass:"text-center mt-5 text-customer-red"},[_c('b',{staticStyle:{"font-size":"2em"}},[_vm._v(" Der von Ihnen aufgerufene Link für die Unterschrift ist leider abgelaufen. ")])]):(_vm.appointment_found === null)?_c('div',{staticClass:"text-center mt-5"},[_vm._v(" Lade Daten für die Bearbeitung... ")]):(!_vm.hasTeacherSignatures)?_c('v-alert',{attrs:{"type":"error"}},[_c('b',[_vm._v("Die Unterschrift kann momentan nicht gespeichert werden.")]),_c('br'),_vm._v(" Der Status des Termins wurde zwischenzeitlich geändert. Bitte erfragen Sie einen neuen Link um Ihre Unterschrift abzuleisten. ")]):(!_vm.appointment_found)?_c('v-alert',{attrs:{"type":"error"}},[_c('b',[_vm._v("Die Bearbeitung dieses Termin ist nicht möglich.")]),_c('br'),_vm._v(" Entweder fehlt Ihnen die entsprechende Berechtigung oder der Termin existiert nicht. ")]):_vm._e()],1):_c('v-form',{ref:"form",class:{
        isNew: _vm.isNewAppointmentMode,
        isEdit: _vm.isTeacherEditingAppointmentMode,
        isSignature: _vm.isGenericSignatureMode,
    },attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"mt-3 position-relative"},[_c('div',{attrs:{"id":"status-action-bar"}},[(_vm.isTeacherEditingAppointmentMode)?[_vm._v(" "+_vm._s(_vm.getResolvedAppointmentTypeLabel(_vm.appointment.type))+" mit Familie "+_vm._s(_vm.getFamily().last_name)+" ")]:(
                    _vm.isParentSignatureMode || _vm.isParentEditingAppointmentMode
                )?[_vm._v(" "+_vm._s(_vm.getResolvedAppointmentTypeLabel(_vm.appointment.type))+" mit "+_vm._s({ W: 'Frau', M: 'Herrn' }[_vm.appointment.teacher.gender])+" "+_vm._s(_vm.appointment.teacher.last_name)+" ")]:_vm._e(),(
                    _vm.isTeacherEditingAppointmentMode &&
                        _vm.cancellationOrDeletionAllowed
                )?_c('delete-appointment-modal',{on:{"deletion-done":_vm.deletionDone}}):_vm._e(),(
                    (_vm.isTeacherEditingAppointmentMode ||
                        _vm.isGenericSignatureMode) &&
                        _vm.status
                )?_c('div',{staticClass:"mb-2",style:({
                    color: _vm.getResolvedAppointmentStatus(_vm.status).color,
                })},[_vm._v(" Terminstatus: "),_c('v-icon',{staticClass:"pr-1 float-left",staticStyle:{"color":"inherit"}},[_vm._v(_vm._s(_vm.getResolvedAppointmentStatus(_vm.status).icon))]),_vm._v(" "+_vm._s(_vm.getResolvedAppointmentStatus(_vm.status).label)+" ")],1):_vm._e()],2),_c('v-card',{staticClass:"mx-auto mb-4 px-2 py-3",attrs:{"outlined":""}},[_c('v-select',{attrs:{"dense":"","label":"Rhythmus","hide-details":"","items":_vm.repetitiveItemized,"mandatory":"","prepend-icon":"mdi-remote-desktop","required":"","disabled":_vm.isTeacherEditingAppointmentMode ||
                        _vm.isGenericSignatureMode ||
                        _vm.isFamily,"rules":[function (v) { return !!v || 'Bitte einen Rhythmus wählen'; }]},model:{value:(_vm.repetitive_selected),callback:function ($$v) {_vm.repetitive_selected=$$v},expression:"repetitive_selected"}}),_c('v-dialog',{ref:"dialog0",attrs:{"return-value":_vm.date,"persistent":""},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":_vm.isGenericSignatureMode ||
                                !_vm.dateTimeChangeAllowed ||
                                _vm.isFamily,"label":_vm.repetitive_selected === 1 ||
                            _vm.repetitive_selected === null
                                ? 'Datum'
                                : 'Startdatum der Terminserie',"prepend-icon":"mdi-calendar","readonly":"","hide-details":"auto","value":_vm.dateFormatted,"required":"","rules":[function (v) { return !!v || 'Bitte ein Datum wählen'; }]}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialogs[0]),callback:function ($$v) {_vm.$set(_vm.dialogs, 0, $$v)},expression:"dialogs[0]"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates,"locale":"de-de","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('div',{staticStyle:{"width":"100%"}},[(_vm.currently_selected_month_invoiced)?_c('div',{staticClass:"centered-floating-box text-center",staticStyle:{"background-color":"white","border":"1px solid #ccc","padding-top":"10px"}},[_c('b',{staticStyle:{"color":"red"}},[_vm._v(" Dieser Monat wurde "),_c('br'),_vm._v(" bereits abgerechnet. ")])]):_vm._e(),(_vm.isTimeframeOverlappingOtherAppointments())?_c('div',{staticClass:"text-center"},[_c('b',{staticStyle:{"color":"red"}},[_vm._v(" Für dieses Datum gibt es angesichts der gewählten Uhrzeiten "+_vm._s(_vm.overlapping_appointments.length)+" Kollision(en). ")])]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.closeDialog(0)}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog0.save(_vm.date)}}},[_vm._v(" Speichern ")])],1)])])],1),(
                    !(
                        _vm.repetitive_selected === 1 ||
                        _vm.repetitive_selected === null
                    )
                )?[_c('small',{staticClass:"muted repetitive-appointment-note"},[(_vm.placements_selected[0] === undefined)?_c('i',[_vm._v(" Das End-Datum der Serie erscheint hier nachdem Sie einen Teilnehmer gewählt haben. ")]):(!_vm.isParentSignatureMode)?_c('span',[_vm._v(" Ende der Termin-Serie: "),_c('u',[_vm._v(_vm._s(_vm.moment( _vm.placements[_vm.placements_selected[0]].approval .end_date ).format(_vm.moment_date_format + ' YYYY')))])]):_vm._e()])]:_vm._e(),_c('v-row',[_c('v-col',[_c('v-dialog',{ref:"dialog1",attrs:{"return-value":_vm.time_start,"persistent":""},on:{"update:returnValue":function($event){_vm.time_start=$event},"update:return-value":function($event){_vm.time_start=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":_vm.isGenericSignatureMode ||
                                        !_vm.dateTimeChangeAllowed ||
                                        _vm.isFamily,"hide-details":"auto","label":"Beginn","prepend-icon":"mdi-clock-time-four-outline","readonly":"","required":"","rules":[
                                    function (v) { return !!v ||
                                        'Bitte wählen Sie die Startzeit'; } ]},model:{value:(_vm.time_start),callback:function ($$v) {_vm.time_start=$$v},expression:"time_start"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialogs[1]),callback:function ($$v) {_vm.$set(_vm.dialogs, 1, $$v)},expression:"dialogs[1]"}},[(_vm.dialogs[1])?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","allowed-minutes":_vm.timepickerAllowedMinutes({
                                    interval: 5,
                                    after: null,
                                    is: 'start',
                                })},model:{value:(_vm.time_start),callback:function ($$v) {_vm.time_start=$$v},expression:"time_start"}},[_c('div',{staticStyle:{"width":"100%"}},[(_vm.time_end !== null)?_c('div',{staticClass:"text-right"},[_c('b',{staticStyle:{"color":"red"}},[_vm._v(" Bei Änderung wird die Endzeit zurückgesetzt. ")])]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.closeDialog(1)}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog1.save(_vm.time_start);
                                            _vm.time_end = null;}}},[_vm._v(" Speichern ")])],1)])]):_vm._e()],1)],1),_c('v-col',[_c('v-dialog',{ref:"dialog2",attrs:{"return-value":_vm.time_end,"persistent":""},on:{"update:returnValue":function($event){_vm.time_end=$event},"update:return-value":function($event){_vm.time_end=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                            var on = ref.on;
                                            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":_vm.isGenericSignatureMode ||
                                        !_vm.dateTimeChangeAllowed ||
                                        _vm.isFamily,"hide-details":"auto","label":"Ende","prepend-icon":"mdi-history","readonly":"","required":"","rules":[
                                    function (v) { return !!v ||
                                        'Bitte wählen Sie die Startzeit'; } ]},model:{value:(_vm.time_end),callback:function ($$v) {_vm.time_end=$$v},expression:"time_end"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialogs[2]),callback:function ($$v) {_vm.$set(_vm.dialogs, 2, $$v)},expression:"dialogs[2]"}},[(_vm.dialogs[2])?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","allowed-hours":_vm.timepickerAllowedHours(),"allowed-minutes":_vm.timepickerAllowedMinutes({
                                    interval: 15,
                                    after: _vm.time_start,
                                    is: 'end',
                                })},model:{value:(_vm.time_end),callback:function ($$v) {_vm.time_end=$$v},expression:"time_end"}},[_c('div',{staticStyle:{"width":"100%"}},[(
                                        _vm.moment(
                                            _vm.time_start,
                                            _vm.moment_time_format_HM
                                        ).isAfter(
                                            _vm.moment(
                                                _vm.time_end,
                                                _vm.moment_time_format_HM
                                            )
                                        )
                                    )?_c('div',{staticClass:"text-right"},[_c('b',{staticStyle:{"color":"red"}},[_vm._v(" Die gewählte End-Zeit liegt vor der Startzeit ("+_vm._s(_vm.moment( _vm.time_start, _vm.moment_time_format_HM ).format(_vm.moment_time_format_HM))+" Uhr) ")])]):_vm._e(),(
                                        _vm.moment(
                                            _vm.time_start,
                                            _vm.moment_time_format_HM
                                        ).isSame(
                                            _vm.moment(
                                                _vm.time_end,
                                                _vm.moment_time_format_HM
                                            )
                                        )
                                    )?_c('div',{staticClass:"text-right"},[_c('b',{staticStyle:{"color":"red"}},[_vm._v(" Die End-Zeit kann nicht gleich der Startzeit sein. ")])]):_vm._e(),(
                                        _vm.isTimeframeOverlappingOtherAppointments()
                                    )?_c('div',{staticClass:"text-center"},[_c('b',{staticStyle:{"color":"red"}},[_vm._v(" Durch das gerade gewählte Zeitfenster entstehen/entsteht "+_vm._s(_vm.overlapping_appointments.length)+" Kollision(en). ")])]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.closeDialog(2)}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"disabled":_vm.moment(
                                                _vm.time_start,
                                                _vm.moment_time_format_HM
                                            ).isAfter(
                                                _vm.moment(
                                                    _vm.time_end,
                                                    _vm.moment_time_format_HM
                                                )
                                            ) ||
                                                _vm.moment(
                                                    _vm.time_start,
                                                    _vm.moment_time_format_HM
                                                ).isSame(
                                                    _vm.moment(
                                                        _vm.time_end,
                                                        _vm.moment_time_format_HM
                                                    )
                                                ),"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog2.save(_vm.time_end)}}},[_vm._v(" Speichern ")])],1)])]):_vm._e()],1)],1)],1),(_vm.isTimeframeOverlappingOtherAppointments())?_c('v-row',{staticClass:"mb-5"},[_c('v-col',{staticStyle:{"width":"32px"},attrs:{"cols":"auto"}},[_c('v-icon',{staticStyle:{"color":"red"}},[_vm._v("mdi-alert-outline")])],1),_c('v-col',{staticStyle:{"color":"red"},attrs:{"cols":"auto"}},[_c('b',[_vm._v("Dieser Termin überlagert "+_vm._s(_vm.overlapping_appointments.length)+" "+_vm._s(_vm.overlapping_appointments.length === 1 ? 'Termin' : 'Termine')+":")]),_c('small',[_c('ul',_vm._l((_vm.overlapping_appointments),function(appointment){return _c('li',{key:appointment.id},[_vm._v(" "+_vm._s(_vm.moment( appointment.start, 'h:mm' ).format(_vm.moment_time_format_HM))+" — "+_vm._s(_vm.moment(appointment.end, 'h:mm').format( _vm.moment_time_format_HM ))+" Uhr ")])}),0)])])],1):_vm._e(),_c('v-select',{staticClass:"mt-5",attrs:{"dense":"","label":"Form","hide-details":"auto","items":_vm.formsItemized,"prepend-icon":"mdi-remote-desktop","required":"","disabled":_vm.isGenericSignatureMode ||
                        !_vm.presenceChangeAllowed ||
                        _vm.isFamily,"rules":[
                    function (v) { return v === null ? 'Bitte Unterrichtsform wählen' : true; } ]},model:{value:(_vm.form_selected),callback:function ($$v) {_vm.form_selected=$$v},expression:"form_selected"}}),(_vm.isNewAppointmentMode)?_c('v-radio-group',{attrs:{"mandatory":"","hide-details":"","label":"Ort / Adresse"},model:{value:(_vm.location_src_select),callback:function ($$v) {_vm.location_src_select=$$v},expression:"location_src_select"}},[_c('v-radio',{attrs:{"label":"Zuhause beim Schüler","value":_vm.locationSourceEnums['at_students_home']}}),(
                        _vm.location_src_select ===
                            _vm.locationSourceEnums['at_students_home']
                    )?_c('div',{staticClass:"mb-2"},[(_vm.studentLocation)?_c('span',[_c('v-icon',[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(_vm.studentLocation)+" ")],1):_c('small',{staticClass:"muted d-block mb-1",staticStyle:{"line-height":"normal"}},[_c('i',[_vm._v("Die Adresse erscheint hier sobald Sie eine Vermittlung gewählt haben.")])])]):_vm._e(),_c('v-radio',{attrs:{"label":"Freitext Adresse","value":_vm.locationSourceEnums['freetext']}}),(
                        _vm.location_src_select ===
                            _vm.locationSourceEnums['freetext']
                    )?_c('div',[_c('v-text-field',{attrs:{"solo":"","label":"Präzise Adresse","clearable":"","hide-detailsrequired":"","rules":[
                            function (v) { return !!v || 'Bitte hier die Adresse angeben.'; } ]},model:{value:(_vm.location_free_text),callback:function ($$v) {_vm.location_free_text=$$v},expression:"location_free_text"}})],1):_vm._e()],1):_c('div',[_c('v-textarea',{staticClass:"no-min-height",attrs:{"hide-details":"","rows":"1","auto-grow":"","full-width":"","label":"Ort / Adresse","prepend-icon":_vm.getResolvedAppointmentLocation(_vm.location_src_select)
                            .icon,"disabled":"","value":_vm.getAppointmentLocation()}})],1),(_vm.isTeacherEditingAppointmentMode)?_c('small',{staticClass:"pl-8",domProps:{"innerHTML":_vm._s(
                    _vm.getLocation(_vm.appointment, '&raquo; Auf GMaps anzeigen')
                )}}):_vm._e()],2),_c('span',{staticClass:"v-card-caption"},[_vm._v(" Teilnehmer "),(_vm.placements_selected.length > 1)?_c('i',[_vm._v("(Gruppenunterricht)")]):(_vm.placements_selected.length === 1)?_c('i',[_vm._v("(Einzelunterricht)")]):_vm._e()]),_c('v-card',{staticClass:"mx-auto py-5 px-2",attrs:{"outlined":""}},[_c('ul',{staticClass:"mb-2",staticStyle:{"list-style":"none","padding-left":"10px"}},_vm._l((_vm.placements_selected),function(placement_id){return _c('li',{key:'list-' + placement_id},[_c('div',{staticClass:"row",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"col",staticStyle:{"padding-left":"2px","padding-right":"2px","font-weight":"bold"}},[(
                                    placement_id && _vm.placements[placement_id]
                                )?_c('span',{staticClass:"dev-only",staticStyle:{"position":"absolute","margin-top":"-12px","opacity":"0.5","font-size":"0.6em"}},[_vm._v(" *DEV* Placement ID "+_vm._s(placement_id || '?')+" | Student ID "+_vm._s(_vm.placements[placement_id].student.id)+" | Subject ID "+_vm._s(_vm.subjects_selected[placement_id] || '-')+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.placements[placement_id].student.first_name)+", "+_vm._s(_vm.placements[placement_id].application_type .title)+": "),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.hoursLeft(placement_id))}}),(
                                    _vm.isPlacementOverlapping(
                                        _vm.placements[placement_id]
                                    )
                                )?_c('div',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" BELEGT ")]):_vm._e()]),(
                                _vm.isTeacherEditingAppointmentMode ||
                                    _vm.isGenericSignatureMode ||
                                    _vm.isParentEditingAppointmentMode
                            )?_c('div',{staticClass:"col-6 position-relative"},[_c('v-select',{class:{ 'required-marker': !_vm.isPending },attrs:{"items":_vm.subjectsItemized(placement_id),"dense":"","hide-details":"auto","required":!_vm.isPending,"rules":[
                                    function (v) { return _vm.isPending
                                            ? true
                                            : !!v ||
                                              'Bitte wählen Sie ein Fach.'; } ],"disabled":_vm.isGenericSignatureMode ||
                                        !_vm.subjectsChangeAllowed ||
                                        _vm.isParentEditingAppointmentMode},model:{value:(_vm.subjects_selected[placement_id]),callback:function ($$v) {_vm.$set(_vm.subjects_selected, placement_id, $$v)},expression:"subjects_selected[placement_id]"}}),(
                                    _vm.subjects_selected[placement_id] !==
                                        null &&
                                        !_vm.isGenericSignatureMode &&
                                        _vm.subjectsChangeAllowed &&
                                        !_vm.isParentEditingAppointmentMode
                                )?_c('v-btn',{staticStyle:{"position":"absolute","right":"-8px","top":"50%","transform":"translateY(-50%)","height":"20px","width":"20px"},attrs:{"color":"red","dark":"","fab":"","x-small":""},on:{"click":function($event){_vm.subjects_selected[placement_id] = null}}},[_vm._v(" x ")]):_vm._e()],1):_vm._e()])])}),0),(
                    _vm.placements !== null &&
                        !_vm.isGenericSignatureMode &&
                        !_vm.isParentEditingAppointmentMode
                )?_c('v-select',{attrs:{"items":_vm.placementsItemizedFiltered,"disabled":!_vm.participantsChangeAllowed,"dense":"","multiple":"","hide-details":"auto","required":"","no-data-text":"Keine Vermittlungen gefunden","rules":[
                    function (v) { return v.length > 0
                            ? true
                            : 'Bitte wählen Sie eine Vermittlung.'; } ]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_c('div',{staticClass:"d-flex"},[(index === 0)?_c('span',[_vm._v(" "+_vm._s(item.student_name)+", "),_c('small',{staticClass:"muted"},[_vm._v(_vm._s(item.school_subject))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption pl-2",staticStyle:{"float":"right"}},[_vm._v(" (+"+_vm._s(_vm.placements_selected.length - 1)+" weitere) ")]):_vm._e()])]}},{key:"item",fn:function(ref){
                var active = ref.active;
                var item = ref.item;
                var attrs = ref.attrs;
                var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_c('span',{class:{
                                        'muted text-decoration-line-through':
                                            item.mark_disabled,
                                    }},[_vm._v(_vm._s(item.text))]),_c('v-spacer'),(item.mark_disabled)?_c('i',{staticStyle:{"padding-right":"4px","color":"red"}},[_vm._v(" BELEGT ")]):_vm._e()],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,4095764875),model:{value:(_vm.placements_selected),callback:function ($$v) {_vm.placements_selected=$$v},expression:"placements_selected"}}):_vm._e()],1),(
                (_vm.isTeacherEditingAppointmentMode ||
                    _vm.isTeacherSignatureMode) &&
                    _vm.placements_selected.length > 0
            )?[_c('div',{staticClass:"mt-4"}),_c('span',{staticClass:"v-card-caption"},[_vm._v("Unterschriften")]),_c('v-card',{staticClass:"mx-auto pt-2 pb-3 px-2",attrs:{"outlined":""}},[_vm._l((_vm.placements_selected),function(placement_id,index){return _c('div',{key:placement_id,staticClass:"position-relative teacher-signatures-container"},[(
                            _vm.getSignatureSource() === null ||
                                _vm.placements[placement_id]
                                    ._appointments_placement[
                                    _vm.getAppointmentId()
                                ].signature_request_token ===
                                    _vm.getSignatureTokenFromRequest()
                        )?[_c('span',{staticClass:"v-card-caption"},[_vm._v("LK-Unterschrift für "+_vm._s(_vm.placements[placement_id].student.first_name))]),_c('v-card',{staticClass:"mx-auto pt-3",class:{
                                'signature-disabled': !_vm.teacherSignatureAllowed,
                            },attrs:{"outlined":""}},[(!_vm.teacherSignatureAllowed)?_c('v-icon',{staticClass:"signature-disabled-indicator"},[_vm._v("mdi-marker-cancel")]):_vm._e(),(_vm.getSignature(placement_id) !== null)?_c('vueSignature',{ref:"signaturePads",refInFor:true,attrs:{"w":"100%","h":"110px","disabled":!_vm.teacherSignatureAllowed ||
                                        (!_vm.hasTeacherSignatureRequest &&
                                            _vm.isTeacherSignatureMode),"defaultUrl":_vm.getSignature(placement_id)}}):_c('vueSignature',{ref:"signaturePads",refInFor:true,attrs:{"w":"100%","h":"110px","disabled":!_vm.teacherSignatureAllowed ||
                                        (!_vm.hasTeacherSignatureRequest &&
                                            _vm.isTeacherSignatureMode)}})],1),(
                                _vm.teacherSignatureAllowed ||
                                    (_vm.hasTeacherSignatureRequest &&
                                        _vm.isTeacherSignatureMode)
                            )?_c('v-icon',{staticClass:"undo-refresh-icon",on:{"click":function($event){return _vm.clearSignature(index)}}},[_vm._v(" mdi-refresh ")]):_vm._e()]:_c('div',{ref:"signaturePads",refInFor:true})],2)}),(_vm.teacherSignatureAllowed)?_c('v-alert',{staticClass:"mt-5",attrs:{"dense":"","outlined":"","type":"warning"}},[_c('ul',[_c('li',[_vm._v(" Bitte achten Sie drauf bei Ihrer Unterschrift nicht über den Rand des Unterschriftenfelds hinaus zu zeichnen ")]),_c('li',[_vm._v(" Bitte füllen Sie möglichst das gesamte Unterschriftenfeld mit Ihrer Unterschrift ")])])]):_vm._e(),(_vm.sendingLinkAllowed)?[_c('v-btn',{staticClass:"text-btn",attrs:{"text":"","disabled":!_vm.allSignaturesSet(),"href":"#","color":_vm.colors.blue},on:{"click":function($event){$event.preventDefault();return _vm.sendLink.apply(null, arguments)}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-email-send-outline")]),_vm._v(" Link zur Elternunterschrift generieren ")],1),(!_vm.allSignaturesSet())?_c('small',{staticClass:"d-flex muted",staticStyle:{"font-style":"italic"}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-information-outline")]),_c('span',{staticClass:"d-inline-block",staticStyle:{"line-height":"normal"}},[_vm._v(" * Versenden des Links ist erst möglich nachdem für alle Schüler unterschrieben worden ist. ")])],1):_vm._e(),(_vm.signature_request_time !== null)?_c('small',{staticClass:"d-flex muted",class:{ 'mt-3': !_vm.allSignaturesSet() },staticStyle:{"font-style":"italic"}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-information-outline")]),_c('span',{staticClass:"d-inline-block",staticStyle:{"line-height":"normal"}},[_vm._v(" Es wurde bereits ein Link am "),_c('u',[_vm._v(_vm._s(_vm.moment(_vm.signature_request_time).format( _vm.moment_date_format + ' ' + _vm.moment_time_format_HM )))]),_vm._v(" versendet. "),_c('br'),(
                                    _vm.moment(_vm.signature_valid_until).isAfter()
                                )?_c('span',[_vm._v(" Dieser Link ist noch gültig bis: ")]):_c('span',{staticStyle:{"color":"red"}},[_vm._v(" Dieser Link war gültig bis: ")]),_c('u',[_vm._v(_vm._s(_vm.moment(_vm.signature_valid_until).format( _vm.moment_date_format + ' ' + _vm.moment_time_format_HM )))]),_vm._v(". ")])],1):_vm._e(),(
                            _vm.appointment.signature_request_token && _vm.isAdmin
                        )?_c('div',{staticClass:"d-block mt-2 muted"},[_c('div',{staticClass:"d-flex"},[_vm._v(" Link für Elternunterschrift: "),_c('v-spacer'),_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){_vm.toClipboard(
                                        ((_vm.window_location.origin) + "/termin/elternunterschrift/" + (_vm.appointment.signature_request_token))
                                    );
                                    _vm.showSnackbar(
                                        'Der Link wurde in die Zwischenablage kopiert.',
                                        2
                                    );}}},[_vm._v(" kopieren ")])],1),_c('v-text-field',{staticClass:"signature-link-preview-box",attrs:{"readonly":"","outlined":"","value":((_vm.window_location.origin) + "/termin/elternunterschrift/" + (_vm.appointment.signature_request_token)),"hide-details":"","dense":""}})],1):_vm._e()]:_vm._e()],2)]:_vm._e(),(
                (_vm.isParentSignatureMode &&
                    _vm.parentSignatureAllowedThroughStatus &&
                    _vm.getSignatureSource() === 'parent') ||
                    _vm.isParentEditingAppointmentMode
            )?[_c('div',{staticClass:"mt-4"}),_c('span',{staticClass:"v-card-caption"},[_vm._v("Unterschrift")]),_c('v-card',{staticClass:"mx-auto pt-4 pb-3 px-2",attrs:{"outlined":""}},[_c('v-alert',{staticClass:"lighten-2 mb-5",attrs:{"dense":"","dark":"","color":_vm.hasTeacherSignatures ? 'green' : 'red'}},[_vm._v(" "+_vm._s(_vm.hasTeacherSignatures ? 'Lehrerunterschriften vorhanden.' : 'Lehrerunterschriften fehlen.')+" ")]),(_vm.hasTeacherSignatures)?_c('v-select',{staticClass:"mt-5",attrs:{"dense":"","label":_vm.hasParentSignature &&
                        !(_vm.getSignatureSource() === 'parent')
                            ? 'Es wurde unterschrieben für:'
                            : 'Wer sind Sie?',"hide-details":"auto","disabled":(_vm.hasParentSignature &&
                            _vm.isParentEditingAppointmentMode) ||
                            (!_vm.hasParentSignatureRequestToken &&
                                _vm.isParentSignatureMode),"items":_vm.phoneNumbersItemized},model:{value:(_vm.phone_number_selected),callback:function ($$v) {_vm.phone_number_selected=$$v},expression:"phone_number_selected"}}):_vm._e(),(_vm.phone_number_selected)?[_c('div',{staticClass:"position-relative mt-5"},[_c('span',{staticClass:"v-card-caption"},[_vm._v(_vm._s(_vm.hasParentSignature ? 'Ihre vorherige Unterschrift' : 'Bitte hier unterzeichnen'))]),_c('v-card',{staticClass:"mx-auto pt-3",attrs:{"outlined":""}},[(_vm.appointment.signature)?_c('vueSignature',{ref:"signaturePads",attrs:{"w":"100%","h":"110px","disabled":!_vm.parentSignatureAllowedThroughStatus,"defaultUrl":_vm.appointment.signature}}):_c('vueSignature',{ref:"signaturePads",attrs:{"w":"100%","h":"110px","disabled":!_vm.parentSignatureAllowedThroughStatus}})],1),(
                                !(
                                    (_vm.hasParentSignature &&
                                        _vm.isParentEditingAppointmentMode) ||
                                    (!_vm.hasParentSignatureRequestToken &&
                                        _vm.isParentSignatureMode)
                                )
                            )?_c('v-icon',{staticClass:"undo-refresh-icon",on:{"click":function($event){return _vm.clearSignature()}}},[_vm._v(" mdi-refresh ")]):_vm._e()],1),(!_vm.hasParentSignature)?_c('v-alert',{staticClass:"mt-2",attrs:{"dense":"","outlined":"","type":"warning"}},[_c('ul',[_c('li',[_vm._v(" Bitte achten Sie drauf bei Ihrer Unterschrift nicht über den Rand des Unterschriftenfelds hinaus zu zeichnen ")]),_c('li',[_vm._v(" Bitte füllen Sie möglichst das gesamte Unterschriftenfeld mit Ihrer Unterschrift ")])])]):_vm._e(),(
                            (!_vm.hasStandardSignature &&
                                !_vm.hasParentSignature) ||
                                (_vm.isParentSignatureMode &&
                                    !_vm.hasStandardSignature)
                        )?[_c('v-checkbox',{attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('small',{staticClass:"d-block",staticStyle:{"color":"red","line-height":"normal"}},[_vm._v(" Da diese Ihre erste Unterschrift ist, wird sie zum Abgleichzwecken als Standardunterschrift gespeichert. "),_c('br'),_c('b',[_vm._v("Bitte bestätigen Sie die Richtigkeit der Unterschrift.")])])]},proxy:true}],null,false,3419224019),model:{value:(_vm.use_as_std_signature),callback:function ($$v) {_vm.use_as_std_signature=$$v},expression:"use_as_std_signature"}})]:_vm._e()]:_vm._e()],2)]:_vm._e(),(
                _vm.isTeacherEditingAppointmentMode ||
                    _vm.isNewAppointmentMode ||
                    (_vm.isParentSignatureMode && !_vm.hasParentSignature)
            )?_c('div',{staticClass:"mt-5"},[_c('span',{staticClass:"v-card-caption"},[_vm._v("Kommentar / Anmerkung")]),_c('v-card',{staticClass:"mx-auto pt-2 pb-3 px-2",attrs:{"outlined":""}},[_c('v-textarea',{attrs:{"placeholder":"Anmerkung hier eingeben (optional)","hide-details":"","disabled":_vm.isAccepted},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1):_vm._e(),(_vm.isParentSignatureMode || _vm.isParentEditingAppointmentMode)?[(
                    !_vm.parentSignatureAllowedThroughStatus &&
                        !_vm.hasParentSignature
                )?_c('v-alert',{staticClass:"lighten-2 mt-5",attrs:{"dense":"","dark":"","color":"red"}},[(!_vm.hasTeacherSignatures)?[_vm._v(" Es kann momentan keine Unterschrift geleistet werden, da die Lehrkraft-Unterschrift für den Termin fehlt. ")]:[_vm._v(" Es kann momentan keine Unterschrift geleistet werden, da sich der Status des Termins geändert hat. ")]],2):_vm._e(),(_vm.hasParentSignature && !_vm.hasParentSignatureRequestToken)?_c('v-alert',{staticClass:"lighten-2 mt-5",attrs:{"dense":"","dark":"","color":"green"}},[_vm._v(" Vielen Dank für Ihre Unterschrift, der Termin wurde bestätigt. ")]):_vm._e()]:_vm._e(),(_vm.revokeCancellationAllowed)?[_c('v-btn',{staticClass:"d-block mt-5",staticStyle:{"width":"100%"},attrs:{"dark":"","color":"orange"},on:{"click":_vm.revokeCancellation}},[_vm._v(" Absage zurücknehmen ")])]:_vm._e()],2),_c('v-footer',{attrs:{"app":""}},[_c('v-btn',{attrs:{"color":"red lighten-2","dark":"","fab":"","small":"","to":"/terminuebersicht"}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),(!_vm.isAccepted || _vm.isGenericSignatureMode)?_c('v-btn',{attrs:{"disabled":!_vm.canSave(),"dark":_vm.canSave(),"color":"green lighten-2","fab":"","small":""},on:{"click":_vm.validate}},[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e(),(_vm.isAccepted && !_vm.isGenericSignatureMode && !_vm.isFamily)?_c('v-btn',{attrs:{"color":"green","dark":"","fab":"","small":"","to":"/termin/neu"}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }