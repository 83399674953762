<template>
    <div>
        <v-alert v-if="placements === null"
            class="loading-indicator"
            border="top"
            colored-border
            type="info"
            elevation="2">
            Lade Vermittlungen ...
            <template v-slot:prepend>
                <v-progress-circular
                    size="15"
                    width="2"
                    class="mr-2"
                    indeterminate
                    color="primary">
                </v-progress-circular>
            </template>
        </v-alert>

        <template v-if="isTeacher || isAppAdmin">
            <v-row justify="center">
                <v-dialog
                    v-model="filter_dialog"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition">
                    <v-card v-if="placements !== null" outlined tile class="mb-4">

                        <v-toolbar dark color="primary" class="mb-4">
                            <v-btn icon dark @click="filter_dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn dark text @click="filter_dialog = false">
                                    Anwenden
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <div class="d-flex">
                                <v-select
                                    label="Vermittlungsstatus"
                                    hide-details="auto"
                                    v-model="filters.status.val"
                                    :items="statusesItemized"
                                    @change="() => {filters.status.active = true}"
                                >
                                </v-select>
                                <v-btn fab x-small color="red" dark style="align-self: flex-end" class="ml-5"
                                       v-show="filters.status.active"
                                       @click="filters.status.active = false ; filters.status.val = -1">
                                    <v-icon>mdi-filter-remove-outline</v-icon>
                                </v-btn>
                            </div>
                            <div style="display: grid; grid-template-columns: minmax(0, 1fr) auto;">
                                <v-select
                                    label="Familie"
                                    hide-details="auto"
                                    v-model="filters.family.val"
                                    :items="familiesItemized"
                                    @change="() => {filters.family.active = true}"
                                >
                                </v-select>
                                <v-btn fab x-small color="red" dark style="align-self: flex-end" class="ml-5"
                                       v-show="filters.family.active"
                                       @click="filters.family.active = false ; filters.family.val = -1">
                                    <v-icon>mdi-filter-remove-outline</v-icon>
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>

        <v-row v-if="placements !== null">
            <v-col>
                <small class="text-muted muted d-block mb-2 text-right">
                    &mdash; Vermittlungen insgesamt: <b>{{Object.keys(placements).length}}</b>
                    <div v-if="filtersActive.length !== 0 && placement_filtered_count > 0">
                        &mdash; nach Filter: <b>{{placement_filtered_count}}</b>
                    </div>
                </small>
            </v-col>
        </v-row>

        <div class="centered-floating-box">
            <v-alert v-if="placements && filtersActive.length !== 0 && placement_filtered_count === 0" border="top" color="red lighten-2" dark>
                Es gibt keine Vermittlungen die auf die gewählten Filterkriterien passen.
            </v-alert>
            <v-alert v-else-if="placements && placements.length === 0" border="top" color="blue lighten-3" dark>
                Sie haben aktuell keine ausstehenden Vermittlungen.
            </v-alert>
        </div>

        <div class="position-relative separated-boxes-list-group" v-for="(placements, family_name, index) in placements_grouped_filtered(false)" :key="index">
            <b class="d-block" style="line-height: normal" v-if="isTeacher || isAppAdmin">
                Fam. {{family_name}}
            </b>
            <small style="display: block; line-height: normal" v-if="isTeacher || isAppAdmin">
                {{getFamily(placements[0]).address}}
            </small>
            <v-card v-for="(placement, index2) in placements" :key="placement.id" :class="{ 'mb-2' : index2 !== placements.length-1, 'mb-4' : index2 === placements.length-1 }" class="placement-entry">
                <v-card-text>
                    <div v-if="isDev()" style="position: absolute; right: 0; top:0; opacity: 0.2">
                        <small style="display: block; line-height: normal">
                            [DEV] Placement-ID {{placement.id}}<br>
                            <!--[DEV] Approval-ID {{placement.approval.id}} <br>-->
                            [DEV] Student-ID {{placement.approval.student.id}}
                        </small>
                    </div>
                    <div class="d-flex">
                        <div style="align-self: center" v-if="isTeacher || isAppAdmin">
                            <v-icon class="placement-check mt-0 pt-0 pr-2" v-if="!canCheck(placement)">
                                mdi-checkbox-blank-off-outline
                            </v-icon>
                            <v-checkbox v-else class="placement-check mt-0 pt-0" large hide-details
                                v-model="placement.selected">
                            </v-checkbox>
                        </div>
                        <div @click="detail_id = placement.id" class="flex-grow-1" style="cursor: pointer">
                            <h3>
                                {{getStudent(placement).first_name}}, {{placement.approval.application.type.title}}
                            </h3>
                            <div v-if="isTeacher || isAppAdmin">
                                Alter: {{getStudent(placement).age}} / Klasse: {{getStudent(placement).school_class}}
                            </div>
                            <!-- placement.approval.remaining_hours -> hier sollten besser die übrigen (berechneten) zeiten für das placement, nicht das approval angezeigt werden; diese berechnung ist aber prio 2 @ yannic -->
                            <!--Reststunden: {{placement.approval.remaining_hours}} für-->
                            Reststunden:
                            <div class="subject-container">
                                <span v-for="subject in placement.placementsubjects" :key="subject.id">
                                    <!--{{subject._open_hours}} UE-->
                                    {{subject.schoolsubject.name}} {{subject.remaining_hours}} UE<span>,</span>
                                </span>
                            </div>
                            <div :style="{ color : getResolvedPlacementStatus(placement.status.id).color }" class="d-flex">
                                <!--<b>[DB-Status]:</b> {{placement.status.title}}
                                <br>-->
                                <v-icon class="pr-1">{{getResolvedPlacementStatus(placement.status.id).icon}}</v-icon>
                                {{getResolvedPlacementStatus(placement.status.id).label}}
                            </div>
                            <template v-if="placement.comment">
                                <i style="color: #727272">{{placement.comment}}</i>
                            </template>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
            <!--
            <template v-if="isDev()">
                [DEV] Placement IDs:
                {{Object.keys(placements).map(f=>placements[f].id)}}
                <br>
                <i style="font-size: 12px; font-family: monospace, monospace;" class="muted">
                    UPDATE `placements` SET status_id=12, timeframe=null  WHERE `id` IN ({{Object.keys(placements).map(f=>placements[f].id).join(',')}})
                </i>
                <br>
                <i style="font-size: 12px; font-family: monospace, monospace;" class="muted">
                    SELECT * FROM `placements` WHERE `id` IN ({{Object.keys(placements).map(f=>placements[f].id).join(',')}})
                </i>
            </template>
            -->
        </div>

        <!--=========================
        -- Placement DETAIL modal
        ==========================-->
        <template>
            <v-dialog
                v-if="(isTeacher || isAppAdmin) && detail_id !== null"
                v-model="detail_id"
                fullscreen
                transition="dialog-bottom-transition">
                <v-card outlined tile class="mb-4">
                    <v-toolbar dark color="primary" class="mb-4">
                        <v-btn icon dark @click="detail_id = null">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="detail_id = null">
                                Schließen
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text class="placement-detail">
                        <v-col>
                            <h3>
                                {{getStudent(placements[detail_id]).first_name}} {{getStudent(placements[detail_id]).last_name}}
                                <v-icon :style="{ color : getResolvedPlacementStatus(placements[detail_id].status.id).color }" style="float: right">
                                    {{getResolvedPlacementStatus(placements[detail_id].status.id).icon}}
                                </v-icon>
                            </h3>
                            <small class="d-block muted">{{getFamily(placements[detail_id]).address}}</small>
                            <hr class="mb-2">
                            Alter: {{getStudent(placements[detail_id]).age}}
                            <br>
                            Klasse: {{getStudent(placements[detail_id]).school_class}}
                            <br>
                            <b>Vermittlungszeitraum:</b>
                            <br>
                            {{moment(placements[detail_id].start_date).format(moment_date_format + ' Y')}} &mdash; {{moment(placements[detail_id].end_date).format(moment_date_format + ' Y')}}
                            <br>
                            <!--<b>Reststunden:</b>
                            <br>-->
                            <!--{{placements[detail_id].approval.remaining_hours}}
                            <br>-->
                            <b>{{placements[detail_id].placementsubjects.length===1 ? 'Vermittlungsfach' : 'Vermittlungsfächer'}}:</b>
                            <br>
                            <ul v-for="subject in placements[detail_id].placementsubjects" :key="subject.id">
                                <li>
                                    {{subject.schoolsubject.name}} <!--{{subject._open_hours}}UE-->
                                    <ul>
                                        <li>Std-Kontingent: {{subject.approved_hours}} UE</li>
                                        <li>Erteilte Stunden: {{subject.held_hours}} UE</li>
                                        <li>Reststunden: {{subject.remaining_hours}} UE</li>
                                    </ul>
                                </li>
                            </ul>
                            <template v-id="isAccepted(placements[detail_id])">
                                <hr class="mt-5">
                                <v-textarea class="mt-5"
                                    label="Zeitfenster"
                                    v-model="placements[detail_id].timeframe">
                                </v-textarea>
                                <div class="d-flex">
                                <v-spacer></v-spacer>
                                <v-btn color="green lighten-2" fab small
                                       @click="save(detail_id)">
                                    <v-icon style="color: white">mdi-check</v-icon>
                                </v-btn>
                                </div>
                            </template>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>

        <!--=======================================
        -- "Do Batch Action" Confirmation Modal
        -- In-Component Version: https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/5c58ed40783a81454bec0f70da862337c98128aa/src/components/vermittlungsuebersicht.vue#L203
        ========================================-->
        <mass-placement-update-modal
            v-if="isTeacher || isAppAdmin"
            @save="doUpdateSelected"
            ref="mpumc">
        </mass-placement-update-modal>

        <v-footer app v-if="isTeacher || isAppAdmin">
            <v-btn color="red lighten-2"
                   :disabled="getCheckedPlacements().length === 0"
                   :dark="getCheckedPlacements().length > 0"
                   fab small
                   @click="confirmUpdateSelected(0)">
                <v-icon :class="{multiple : getCheckedPlacements().length > 1}">
                    {{getCheckedPlacements().length === 1 || getCheckedPlacements().length === 0 ? 'mdi-close-box-outline' : 'mdi-close-box-multiple-outline'}}
                </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green lighten-2"
                   :disabled="getCheckedPlacements().length === 0"
                   :dark="getCheckedPlacements().length > 0"
                   fab small
                   @click="confirmUpdateSelected(1)">
                <v-icon>
                    {{getCheckedPlacements().length === 1 || getCheckedPlacements().length === 0 ? 'mdi-check' : 'mdi-check-all'}}
                </v-icon>
            </v-btn>
        </v-footer>
    </div>
</template>

<style lang="scss">
    .subject-container {
        display: inline;
        span:last-of-type > span {
            display: none;
        }
    }

    // C&P (entry shrinked)
    .v-application {
        .placement-entry, .placement-detail {
            * {
                color: black;
            }
            .v-icon {
                font-size: 15px;
                color: inherit;
            }
            .placement-check {
                .v-icon {
                    font-size: 26px;
                }
                &.v-icon {
                    font-size: 24px;
                    opacity: 0.5;
                    //color: red;
                }
            }
        }
        .v-card__text {
            padding: 5px !important;
        }

        /*.v-icon.mdi-cancel.multiple {
            text-shadow: 1px -1px 0px #E57373;
            position: relative;
            z-index: 5;
        }
        .v-icon.cancel-clone {
            position: absolute;
            top: 3px;
            margin-right: -5px;
            z-index: 0;
        }*/

    }

</style>

<script>
import config from '@/config'
import axios from 'axios'
import colors from '@/misc/colors'
import _ from 'lodash'

import mass_placement_update_modal from '@/components/partials/mass-placement-update-modal'

import EventBus from '../main';

export default {
    components: {
        'mass-placement-update-modal' : mass_placement_update_modal
    },
    data : () => ({

        filter_dialog : false,
        detail_id : null, // for the placement detail modal ;; should be an placement id OR null (null=modal closed)

        filters : {
            family : {
                active : false,
                val : -1,
                filter_handler : function($instance, placement) {
                    return $instance.getFamily(placement).id === this.val
                }
            },
            status : {
                active : false,
                val : -1,
                filter_handler : function($instance, placement) {
                    return placement.status_id === this.val
                }
            },
        },

        colors : colors,

        placements : null,
        placement_filtered_count : null, // cache var which will be set by the filter/group function

        //confirmation_dialog : false,

    }),
    computed : {
        isFamily () {
            return _.get(this.$root, 'userInfo.is_family', null)
        },
        isTeacher () {
            return _.get(this.$root, 'userInfo.is_teacher', null)
        },
        isAppAdmin () {
            return _.get(this.$root, 'userInfo.is_app_admin', null)
        },
        /*
         * return all filters from the filter object that are set to active
         * TODO copied from terminuebersicht.vue
         */
        filtersActive () {
            return _.filter(this.filters, function (filter, filter_name) {
                return filter.active
            })
        },
        /*
         * itemization for the filter selects
         */
        familiesItemized () {
            let aFamilies = {}

            _.forEach(this.placements, (placement) => {
                const family = this.getFamily(placement)
                aFamilies[family.last_name + '-' + family.id] = {
                    'text' : family.last_name + ' (' + family.address + ')',
                    'value' : family.id
                }
            })

            return _.values(_.orderBy(aFamilies, ['text'], ['asc']))
        },
        statusesItemized () {
            let dataItemized = []

            const avail_statuses = _.uniq(_.map(this.placements, 'status_id'))

            _.forEach(avail_statuses, (status_id) => {
                dataItemized.push({
                    'text' : this.getResolvedPlacementStatus(status_id).label,
                    'value' : status_id
                })
            })

            return dataItemized
        },
    },
    created () {},
    mounted () {

        EventBus.$on('toggle-filters', () => {
            this.filter_dialog = true
        })

        axios.get(`${config.backend_APP_api_url}/placements/my`).then(res => {
            this.placements = res.data.placements
        })

    },
    methods : {
        canCheck(placement) {
            return placement.status_id === _.get(this.$root, 'enums.byKey.placement_statuses.potential_app', false)
        },
        isAccepted(placement) {
            return placement.status_id === _.get(this.$root, 'enums.byKey.placement_statuses.expression_of_interest', false)
        },
        isDeclined(placement) {
            return placement.status_id === _.get(this.$root, 'enums.byKey.placement_statuses.rejected_by_teacher_app', false)
        },
        getCheckedPlacements () {
            return _.filter(this.placements, (placement) => {
                return placement.selected || false
            })
        },
        save (placement_id) {
            axios.post(`${config.backend_APP_api_url}/placements/${placement_id}`, this.placements[placement_id]).then(res => {
                _.merge(this.placements[placement_id], res.data) // however this is throwing an error if we are not using merge but just assign the result to the according placement
                this.showSnackbar('Die Vermittlung wurde aktualisiert.')
            })
        },
        doUpdateSelected (data) {

            const placement_ids = _.map(this.getCheckedPlacements(), 'id')
            const confirmation_data = data.data

            axios.post(`${config.backend_APP_api_url}/placements/mass-status-update`, _.merge({
                'placement_ids' : placement_ids,
            }, confirmation_data)).then(res => {
                this.placements = res.data.placements
                this.showSnackbar('Die gewählte' + (placement_ids.length > 1 ? 'n' : '') + ' Vermittlung' + (placement_ids.length > 1 ? 'en' : '') + ' wurden ' + ['ABGELEHNT', 'ANGENOMMEN'][confirmation_data.action] + '.')
            })

        },
        confirmUpdateSelected (action) { // decline => 0 ; accept => 1
            const data = {
                action : action,
                count : this.getCheckedPlacements().length
            }

            if(action===0) {
                this.$refs['mpumc'].setData(data)
                this.$refs['mpumc'].validateAndSubmit(true)
            }
            else if(action === 1) {
                this.$refs['mpumc'].show(data)
            }
        },
        placements_grouped_filtered (ignore_filters = false) {

            let active_filters = ignore_filters ? {} : this.filtersActive

            let count = 0

            let placements_grouped_filtered = {}
            _.forEach(this.placements, (placement, placement_id) => {

                let match = true // ... through active filters
                _.forEach(active_filters, filter => {
                    if(!filter.filter_handler(this, placement))
                        match = false
                })
                if(match) {
                    count++
                    //const group_key = placement.approval.student.last_name
                    const group_key = this.getFamily(placement).last_name
                    if(!placements_grouped_filtered[group_key])
                        placements_grouped_filtered[group_key] = []
                    placements_grouped_filtered[group_key].push(placement)
                }

            })

            this.placement_filtered_count = count // cache the filter result amount in order to spare massive recalculation every time we just need the count of filtered results

            return placements_grouped_filtered

        },
        getFamily(placement) {
            /*return _.extend(placement.approval.student.family, {
                'last_name' : '!' + placement.approval.student.last_name
            })*/
            return placement.approval.student.family
        },
        getStudent(placement) {
            return placement.approval.student
        }

    }
};
</script>
