<!--
-- Vermittlungsübersicht für Familien
-->
<template>
    <div>
        <v-alert v-if="approvals === null"
            class="loading-indicator"
            border="top"
            colored-border
            type="info"
            elevation="2">
            Lade Bewilligungen ...
            <template v-slot:prepend>
                <v-progress-circular
                    size="15"
                    width="2"
                    class="mr-2"
                    indeterminate
                    color="primary">
                </v-progress-circular>
            </template>
        </v-alert>
        <v-row v-else>
            <v-col>
                <small class="text-muted muted d-block mb-2 text-right">
                    &mdash; Bewilligungen insgesamt: <b>{{Object.keys(approvals).length}}</b>
                </small>
            </v-col>
        </v-row>

        <div class="centered-floating-box">
            <v-alert v-if="approvals && approvals.length === 0" border="top" color="blue lighten-3" dark>
                Sie haben aktuell keine Bewilligungen.
            </v-alert>
        </div>

        <v-card v-for="(approval, index) in approvals" :key="approval.id" class="approval-entry" :class="{ 'mb-2' : index !== approvals.length-1, 'mb-4' : index === approvals.length-1 }">
            <v-card-text>
                <div class="d-flex">
                    <div class="flex-grow-1">
                        <h3>
                            {{approval.student.first_name}}, {{approval.application.type.title}}
                        </h3>
                        Reststunden: {{approval.remaining_hours}} UE
                    </div>
                </div>
            </v-card-text>
        </v-card>

    </div>
</template>

<script>
import config from '@/config'
import axios from 'axios'

export default {
    components: {},
    data : () => ({
        approvals : null,
    }),
    mounted () {
        axios.get(`${config.backend_APP_api_url}/approvals/my`).then(res => {
            this.approvals = res.data
        })
    },
};
</script>

<style lang="scss">
.v-application {
    .approval-entry {
        * {
            color: black;
        }
    }
}
</style>
