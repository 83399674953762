<!--
version before manual caching (which is perhaps unnecessary
https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/f78da439f9c58f8777daf9834fa30882b67d7221/src/components/terminuebersicht.vue)
-->
<template>
    <div>
        <v-alert v-if="appointments === null"
            class="loading-indicator"
            border="top"
            colored-border
            type="info"
            elevation="2">
            Lade Termine ...
            <template v-slot:prepend>
                <v-progress-circular
                    size="15"
                    width="2"
                    class="mr-2"
                    indeterminate
                    color="primary">
                </v-progress-circular>
            </template>
        </v-alert>

        <!--<v-alert v-if="still_rendering"
            style="position: fixed; z-index: 100"
            class="loading-indicator"
            border="top"
            colored-border
            type="info"
            elevation="2">
            Erstelle Einträge ...
            <br>
            Bitte warten.
            <template v-slot:prepend>
                <v-progress-circular
                    size="15"
                    width="2"
                    class="mr-2"
                    indeterminate
                    color="primary">
                </v-progress-circular>
            </template>
        </v-alert>-->

        <!--
        -- FILTERS
        -->
        <template>
            <v-row justify="center">
                <v-dialog
                    v-model="filter_dialog"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition">
                    <v-card v-if="appointments !== null" outlined tile class="mb-4">

                        <v-toolbar dark color="primary" class="mb-4">
                            <v-btn icon dark @click="filter_dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <!--<v-toolbar-title>Filter</v-toolbar-title>-->
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn dark text @click="filter_dialog = false">
                                    Anwenden
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>

                        <v-card-text>
                            <repetivity-selection ref="repetivity-selection"
                                @update="(val) => {filters.repetitive.val = parseInt(val) ; filters.repetitive.active = val !== -1}" css_class="mt-0 pt-0">
                            </repetivity-selection>
                            <type-selection ref="type-selection"
                                @update="(val) => {filters.type.val = parseInt(val) ; filters.type.active = val !== -1}">
                            </type-selection>
                            <presence-selection ref="presence-selection"
                                @update="(val) => {filters.presence.val = parseInt(val) ; filters.presence.active = val !== -1}">
                            </presence-selection>

                            <!--
                            TODO also create components for the following filters...
                            -->

                            <div class="d-flex">
                                <v-select
                                    label="Datum"
                                    hide-details="auto"
                                    v-model="filters.date.val"
                                    :items="datesItemized"
                                    @change="() => {filters.date.active = true}"
                                >
                                </v-select>
                                <v-btn fab x-small color="red" dark style="align-self: flex-end" class="ml-5"
                                       v-show="filters.date.active"
                                       @click="filters.date.active = false ; filters.date.val = -1">
                                    <v-icon>mdi-filter-remove-outline</v-icon>
                                </v-btn>
                            </div>
                            <div class="d-flex">
                                <v-select
                                    label="Kalenderwoche"
                                    hide-details="auto"
                                    v-model="filters.calendarWeek.val"
                                    :items="calendarWeeksProcessedItemized"
                                    @change="() => {filters.calendarWeek.active = true}"
                                >
                                </v-select>
                                <v-btn fab x-small color="red" dark style="align-self: flex-end" class="ml-5"
                                       v-show="filters.calendarWeek.active"
                                       @click="filters.calendarWeek.active = false ; filters.calendarWeek.val = -1">
                                    <v-icon>mdi-filter-remove-outline</v-icon>
                                </v-btn>
                            </div>
                            <div style="display: grid; grid-template-columns: minmax(0, 1fr) auto;">
                                <v-select
                                    label="Familie"
                                    hide-details="auto"
                                    v-model="filters.family.val"
                                    :items="familiesItemized"
                                    @change="() => {filters.family.active = true}"
                                >
                                </v-select>
                                <v-btn fab x-small color="red" dark style="align-self: flex-end" class="ml-5"
                                       v-show="filters.family.active"
                                       @click="filters.family.active = false ; filters.family.val = -1">
                                    <v-icon>mdi-filter-remove-outline</v-icon>
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <!-- -- FILTERS -->

        <v-alert v-if="show_signable_auto_filter_note" border="top" color="blue lighten-2 mt-5" dark style="font-size: 12px;">
            Ihre Termin-Ansicht wurde automatisch nach Terminen gefiltert, die unterschrieben werden können.
            <br>
            Bitte verwenden Sie das <v-icon>mdi-fountain-pen-tip</v-icon> oder das <v-icon>mdi-filter-outline</v-icon> Symbol um den Filter zu entfernen.
        </v-alert>

        <v-row v-if="appointments !== null && cache__appointments_filtered_amount > 0">
            <v-col>
                <v-btn @click="scrollToTodayOrFirstDayAfterToday" small color="primary">Heute</v-btn>
            </v-col>
            <v-col>
                <small class="text-muted muted d-block mb-2 text-right">
                    <small>&mdash; Einträge insgesamt:</small>
                    <br>
                    <b>{{days}}</b> Tag(e) mit <b>{{appointments.length}}</b> Termin(e)
                    <div v-if="filtersActive.length !== 0 && cache__appointments_filtered_amount > 0">
                        <small>&mdash; nach Filter:</small> <br>
                        <b>{{cache__appointments_days_filtered_amount}}</b> Tag(e) mit <b>{{cache__appointments_filtered_amount}}</b> Termin(e)
                    </div>
                </small>
            </v-col>
        </v-row>

        <div class="centered-floating-box">
            <v-alert v-if="appointments && filtersActive.length !== 0 && cache__appointments_filtered_amount === 0" border="top" color="red lighten-2" dark>
                Es gibt keine Termine die auf die gewählten Filterkriterien passen.
            </v-alert>
            <v-alert v-if="appointments && appointments.length === 0" border="top" color="blue lighten-3" dark>
                Sie haben noch keine Termine angelegt.
                <br>
                <small style="display: block; line-height: normal">
                    Bitte verwenden Sie den PLUS-Button in der rechten unteren Ecke um Termine hinzuzufügen.
                </small>
            </v-alert>
        </div>

        <!--
        Old / first beautiful version of the list:
        https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/9b1e4f31bfe53c4ee525079807b0bb8c85d99254/src/components/terminuebersicht.vue#L90
        -->
        <div class="position-relative separated-boxes-list-group" v-for="(entries, date, index) in getAppointmentsGroupedAndFiltered().appointments" :key="date"
             :ref="moment(date).isSame(new Date(), 'day') ? 'today' : (moment(date).isAfter(new Date(), 'day') ? 'after-today' : null)"
             :id="moment(date).isSame(new Date(), 'day') ? 'today' : `appointment-group-${index}`">
            <span class="today-marker" style="color: #F44336" v-if="moment(date).isSame(new Date(), 'day')">
                <span>HEUTE</span>
                <v-icon style="color: #F44336">mdi-calendar-today</v-icon>
            </span>
            <b>{{moment(date).format(moment_date_format)}}</b> <small>- {{moment(date).week()}}. Kalenderwoche</small>
            <template v-if="!((entries.special || null) === 'ISTODAY')">
                <v-card v-for="(appointment, index2) in entries" :key="appointment.id" :class="{ 'mb-2' : index2 !== entries.length-1, 'mb-4' : index2 === entries.length-1, 'today-box' : moment(date).isSame(new Date(), 'day') }">
                    <v-card-text>
                        <entry-shrinked-teachers
                            v-if="getUser().is_teacher || getUser().is_app_admin"
                            :appointment="appointment" :students="students" :addresses="addresses" :families="families">
                        </entry-shrinked-teachers>
                        <entry-shrinked-parents
                            v-if="getUser().is_family"
                            :appointment="appointment" :students="students" :addresses="addresses" :families="families">
                        </entry-shrinked-parents>
                    </v-card-text>
                </v-card>
            </template>
            <v-alert v-else color="info" border="left" elevation="2" colored-border icon="mdi-calendar-check-outline" class="mt-1">
                Sie haben heute keine Termine.
            </v-alert>
        </div>

        <v-footer app v-if="getUser().is_teacher || getUser().is_app_admin">
            <v-spacer></v-spacer>
            <!--<small style="color: #949494" class="pr-2 muted">Termin erstellen</small>-->
            <v-btn color="green lighten-2" dark fab small to="/termin/neu"><v-icon>mdi-plus</v-icon></v-btn>
        </v-footer>
    </div>
</template>

<style lang="scss">
    .separated-boxes-list-group {
        span.today-marker {
            position: absolute;
            right: 0;
            top : 0;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;

            > span {
                //opacity: 0.1;
                font-size: 15px;
                line-height: normal;
                font-weight: bold;
            }
            > .v-icon {
                right: -6px;
            }
        }
    }
    .v-card.today-box {
        //border-top-right-radius: 0;
        //border-bottom-right-radius: 0;
        .v-card__text {
            border-right: 3px solid #F44336;
        }
    }
</style>

<script>
import config from '@/config'
import axios from 'axios'
import colors from '@/misc/colors'
import _ from 'lodash'
import moment from 'moment'

import repetivity_selection from '@/components/partials/repetivity-selection';
import type_selection from '@/components/partials/type-selection';
import presence_selection from '@/components/partials/presence-selection';

import entry_shrinked_teachers from '@/components/partials/appointment-entry-views/entry-shrinked-teachers'
import entry_shrinked_parents from '@/components/partials/appointment-entry-views/entry-shrinked-parents'


import EventBus from '../main';

export default {
    name: 'terminuebersicht',
    components: {
        'repetivity-selection' : repetivity_selection,
        'type-selection' : type_selection,
        'presence-selection' : presence_selection,

        'entry-shrinked-parents' : entry_shrinked_parents,
        'entry-shrinked-teachers' : entry_shrinked_teachers,
    },
    data : () => ({

        filter_dialog : false,

        filters : {
            repetitive : {
                active : false,
                val : -1,
                filter_handler : function (appointment) {
                    return appointment.repetitivity === this.val
                }
            },
            type : {
                active : false,
                val : -1,
                filter_handler : function (appointment) {
                    return appointment.type === this.val
                }
            },
            presence : {
                active : false,
                val : -1,
                filter_handler : function (appointment) {
                    return appointment.presence === this.val
                }
            },
            date : {
                active : false,
                val : -1,
                filter_handler : function (appointment) {
                    return appointment.date === this.val
                }
            },
            calendarWeek : {
                active : false,
                val : -1,
                filter_handler : function (appointment) {
                    return moment(appointment.date).week() === this.val
                }
            },
            family : {
                active : false,
                val : -1,
                filter_handler : function (appointment) { // , students
                    //return students[appointment.student_ids[0]].family.id === this.val
                    return appointment.family_id === this.val
                }
            },
            signable : { // hidden filter (can only be accessed through a special nav for parents)
                name : 'signable',
                active : false,
                //val : -1,
                filter_handler : null // will be set by an promise within the mount method
            },
        },

        appointments : null,
        appointment_key_map : null,
        students : null,
        families : null,
        addresses : null,
        days  : 0, // just a stat var that indicates the days we are shown (unfiltered data) - this is directly returned from the backend in order to spare frontend performance
        colors : colors,

        cache__appointments_filtered_amount : 0,
        cache__appointments_unfiltered_amount : 0,
        cache__appointments_days_filtered_amount : 0,

        //still_rendering : false,

        //max_to_show : 10,

        show_signable_auto_filter_note : false
    }),
    computed : {
        /*
         * return all filters from the filter object that are set to active
         */
        filtersActive () {
            return _.filter(this.filters, function (filter, filter_name) {
                return filter.active
            })
        },
        /*
         * itemization functions for the filters
         */
        familiesItemized () {
            let aFamilies = {}
            _.forEach(this.appointments, function (appointment) {
                aFamilies[appointment.family_name + '-' + appointment.family_id] = {
                    'text' : appointment.family_name + ' (' + this.getAddress(appointment.address) + ')',
                    'value' : appointment.family_id
                }
            }.bind(this))
            return _.values(_.orderBy(aFamilies, ['text'], ['asc']))
        },
        datesItemized () {
            let aDates = {}
            _.forEach(this.appointments, function (appointment, strAppointmentId) {
                aDates[strAppointmentId] = {
                    'text' : moment(appointment.date).format('dddd, Do MMMM YYYY'),
                    'value' : appointment.date
                }
            }.bind(this))

            //return _.values(_.orderBy(aDates, [function(foo) { return 1; }, 'value'], ['asc']))
            return _.values(aDates)
        },
        calendarWeeksProcessedItemized () {
            let aKWs = {}
            _.forEach(this.appointments, function (appointment, strAppointmentId) {
                aKWs[strAppointmentId] = {
                    'text' : 'KW ' + moment(appointment.date).week(),
                    'value' : moment(appointment.date).week()
                }
            }.bind(this))

            return _.values(_.orderBy(aKWs, ['text'], ['asc']))
        }
    },
    mounted () {

        EventBus.$on('toggle-filters', () => {
            this.filter_dialog = true
            this.filters.signable.active = false
            this.show_signable_auto_filter_note = false
            EventBus.$emit('resetFilterSignableAppointments')
        })
        EventBus.$on('toggle-signable-filter', (active) => {
            _.map(this.filters, (filter) => {
                filter.active = false
                filter.val = -1
                //this.$set(filter, 'active', false)
            })
            if(_.get(this.$refs, 'repetivity-selection'))
                this.$refs['repetivity-selection'].reset()
            if(_.get(this.$refs, 'type-selection'))
                this.$refs['type-selection'].reset()
            if(_.get(this.$refs, 'presence-selection'))
                this.$refs['presence-selection'].reset()

            this.filters.signable.active = active

            if(!active)
                this.show_signable_auto_filter_note = false
        })

        // if there are any signable appointments: active the filter that causes only those to be shown (only relevant for parents - not for teachers)
        this.$root.globalsReady.then(() => {
            if(this.$root.userInfo.is_family) {
                EventBus.$on('setSignableAppointments', (signable_appointments_count) => {
                    if(signable_appointments_count > 0) {
                        this.show_signable_auto_filter_note = true
                        EventBus.$emit('toggle-signable-filter', true)
                    }
                })
            }
        })

        let appointment_promise = axios.get(`${config.backend_APP_api_url}/appointments`).then(res => {

            /*EventBus.$on('getSignableAppointments', () => {

                this.$root.globalsReady.then(() => {
                    const check_status = _.get(this.$root, 'enums.byKey.appointment_statuses.signature_missing', false)
                    this.filters.signable.filter_handler = function(appointment) { // assign the filter handler now that we have access to the enums
                        return appointment.status === check_status
                    }
                    // count the signable appointments (signable by status)
                    const signable_appointments = _.filter(this.appointments, (appointment) => {
                        return this.filters.signable.filter_handler(appointment)
                    }).length

                    // send that information back to the component which asked for it
                    EventBus.$emit('setSignableAppointments', signable_appointments)
                })

            })*/

            // remap index based data with keys from the passed map again
            /*this.appointments = _.map(res.data.appointments, (appointment) => {
                return _.mapKeys(appointment, (value, key) => {
                    return res.data.appointment_key_map[key]
                })
            })*/
            this.appointments = res.data.appointments

            this.students = res.data.students
            this.addresses = res.data.addresses
            this.families = res.data.families

            this.days = res.data.days

            /*
             * rendering the big list in little chunks seems to increase speed
             * @see max_to_show @ data
             */
            /*if(this.appointments.length > 80) {
                this.still_rendering = true
                const bufferdListRenderer =
                    setInterval(() => {
                        this.max_to_show = this.max_to_show * 2
                        //console.info('rendering', this.max_to_show)
                        if(this.max_to_show > this.appointments.length) {
                            clearInterval(bufferdListRenderer)
                            //console.info('done rendering at least ', this.max_to_show,  'elements')
                            this.scrollToTodayOrFirstDayAfterToday()
                            this.still_rendering = false
                        }
                    }, 200)
            }*/
            // ----

            // try to scroll to "today" until it finally worked
            var initalDayScroller = setInterval(() => {
                if(this.scrollToTodayOrFirstDayAfterToday())
                    clearInterval(initalDayScroller)
            }, 200)

        })

        //EventBus.$on('getSignableAppointments', () => {
            Promise.all([appointment_promise, this.$root.globalsReady]).then((promises) => {
                const check_status = _.get(this.$root, 'enums.byKey.appointment_statuses.signature_missing', false)
                this.filters.signable.filter_handler = function(appointment) { // assign the filter handler now that we have access to the enums
                    return appointment.status === check_status
                }
                // count the signable appointments (signable by status)
                const signable_appointments = _.filter(this.appointments, (appointment) => {
                    return this.filters.signable.filter_handler(appointment)
                }).length

                // send that information back to the component which asked for it
                EventBus.$emit('setSignableAppointments', signable_appointments)
            })
        //})

    },
    methods : {
        /*
         * generic subcomponent functions:
         */
        getAddress(address) { // the API may return a string (for freetext locations) or an famliy ID (because addresses are returned indexed by family id)
            // note: address is actually the family id (see comment above)
            return typeof address === 'string' ? address : `${this.addresses[address].street}, ${this.addresses[address].postal_code} ${this.addresses[address].city}`
        },
        // --- generic subcomponent functions
        getUser () {
            return this.$root.userInfo
        },
        getAppointmentsGroupedAndFiltered () {

            const ignore_filters = false // obsoleted

            /*let max = null
            if(_.get(this.appointments, 'length', 0) > 80) // only use max if we got more then 80 appointments
                max = this.max_to_show // 'max_to_show' gets incremented by an interval configured in the mounted() function
            */

            // TEST (for increasing speed) - does this go much faster without all the logic down there??
            const speedTest = () => {
                let appointments_grouped_filtered_TEST = {}
                _.forEach(this.appointments, function (appointment, i) {
                    appointments_grouped_filtered_TEST[appointment.date] = []
                    appointments_grouped_filtered_TEST[appointment.date].push(appointment)
                })
                return {
                    appointments : appointments_grouped_filtered_TEST,
                    count_unfiltered : 0,
                    count_filtered : 0,
                    days : 0,
                }
            }
            //return speedTest()
            // -- TEST

            const insertTodayEntry = () => {
                appointments_grouped_filtered[moment().format('YYYY-MM-DD')] = {
                    special : 'ISTODAY',
                    date : moment().format('YYYY-MM-DD')
                }
            }

            const hasTodayAppointment = _.find(this.appointments, appointment => appointment.date === moment().format('YYYY-MM-DD') ) !== undefined

            const active_filters = ignore_filters ? {} : this.filtersActive

            let count_filtered = 0
            let count_unfiltered = 0

            let appointments_grouped_filtered = {}
            _.forEach(this.appointments, function (appointment, i) {

                /*if(_.isInteger(max) && i > max)
                    return*/

                count_unfiltered++

                if(!hasTodayAppointment && i === 0 && moment(appointment.date).isAfter(moment()))
                    insertTodayEntry()

                let match = true // ... through active filters
                _.forEach(active_filters, function (filter, i) {
                    if(!filter.filter_handler(appointment))
                        match = false
                }.bind(this))
                if(match) {
                    if(!appointments_grouped_filtered[appointment.date])
                        appointments_grouped_filtered[appointment.date] = []
                    appointments_grouped_filtered[appointment.date].push(appointment)
                    count_filtered++
                }

                // if the list does not contain entries for "today" -> inject an special entry between a day that lays before today and the next day after today
                if(
                    !hasTodayAppointment
                    &&
                    moment(appointment.date)
                        .isBefore(moment())
                    &&
                    // if there are no more items to iterate after the current one  (and so we reached the end of the list with the current iteration):
                    // return a 'on the fly' generated date (always tomorrow) so the if-criteria for adding an injected special-entry matches
                    moment(_.get(this.appointments, '['+(i+1)+']', {date : moment().add(1, 'M').format('YYYY-MM-DD')}).date)
                        .isAfter(moment())
                ) { insertTodayEntry() }

            }.bind(this))

            const days = Object.keys(appointments_grouped_filtered).length

            // cache the results
            this.cache__appointments_filtered_amount = count_filtered
            this.cache__appointments_unfiltered_amount = count_unfiltered
            this.cache__appointments_days_filtered_amount = days -1
            //

            return {
                appointments : appointments_grouped_filtered,
                count_unfiltered : count_unfiltered,
                count_filtered : count_filtered,
                //days : hasTodayAppointment ? days : days-1,
            }

        },
        scrollToTodayOrFirstDayAfterToday() {
            const yOffset = -60;
            const element = _.get(this.$refs, 'today[0]', _.get(this.$refs, '["after-today"][0]', false))
            if(element !== false) {
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'})
                return true
            }
            return false
        }

    },
    watch : {
        // NOTE: the computed prop does not return true/false but returns a list of currently active filters
        filtersActive : (n,o) => {
            EventBus.$emit('filtersActiveChanged', n.length > 0, n)
        }
    }
};
</script>
