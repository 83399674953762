
/*
ss_orange : '#ef7b00',
sm_green : '#96d20a',

gentle_red : '#ea9999',
baby_blue : '#9fc5f8',
 */

export default {
    colors : {
        orange : '#ef7b00',
        green : '#96d20a',

        red : '#ea9999',
        blue : '#9fc5f8',

        appointments : {
            first : '#ea9999',
            regular : '#9fc5f8'
        },
        main : '#9fc5f8'
    },
};
