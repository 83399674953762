
import colors from '@/misc/colors'

export default {
    getColorByAppointmentType(type) {
        return colors.colors.appointments[this.$root.enums.byVal.appointment_types[type]]
    },
    getLocation(appointment, label=null) {
        let address = null
        switch (appointment.location_type) {
            case (this.locationSourceEnums['at_students_home']):
                address = appointment.family.address
            break
            case (this.locationSourceEnums['freetext']):
                address = appointment.location_freetext
            break
        }
        return `<a target="_blank" href="https://maps.google.com/?q=${address}">${label || address}</a>`
    }
}
