<template>
    <generic-filter-nav></generic-filter-nav>
</template>

<script>

import generic_filter_nav from './generic-filter-nav'

export default {
    components: {
        'generic-filter-nav' : generic_filter_nav
    }
}
</script>
