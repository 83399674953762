<template>
    <v-btn icon @click="toggle_filters">
        <v-badge v-if="filters_active" dot color="green">
            <v-icon>mdi-filter</v-icon>
        </v-badge>
        <v-icon v-else>mdi-filter-outline</v-icon>
    </v-btn>
</template>

<script>
import _ from 'lodash'
import EventBus from '../main';

export default {
    data : () => ({
        filters_active : false
    }),
    mounted () {
        EventBus.$on('filtersActiveChanged', (newVal, activeFilters) => {
            const is_signable_filter_active = _.find(activeFilters, { 'name': 'signable'}) !== undefined
            if(!is_signable_filter_active)
                this.filters_active = newVal
            else
                this.filters_active = false
        })
    },
    methods : {
        toggle_filters () {
            EventBus.$emit('toggle-filters')
        }
    },
    computed : {}
}
</script>
