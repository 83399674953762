
<template>
    <v-app :class="dynClass">

        <v-app-bar app
            v-if="isLoggedIn()" :color="user.is_app_admin ? 'red' : 'primary'" dark>

            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <span v-html="title"></span>

            <v-spacer></v-spacer>

            <!--<v-btn icon @click="$store.dispatch('logout')">
                <v-icon>mdi-logout</v-icon>
            </v-btn>-->
            <router-view name="nav" v-if="hasRouterNav"/>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" fixed left temporary touchless>
            <div class="pl-4 mb-3">
                <h4 v-if="isDev() || isPreview ()" class="mb-5">
                    <hr>
                    <b style="color: red">DEV-VERSION</b>
                    <hr>
                    {{user.first_name}} {{user.last_name}}
                    <br>
                    UID : {{user.id}}
                    <hr>
                </h4>
                <div v-if="isDev()" style="margin-top: -22px;">
                    <b style="display: inline-block; width: 80px">Teacher:</b> {{user.is_teacher}}
                    <br>
                    <b style="display: inline-block; width: 80px">Family:</b> {{user.is_family}}
                    <hr>
                </div>
                <h3>
                    <span class="font-weight-light">schu</span>
                    <span>N</span>
                    <span class="font-weight-light">a</span>
                </h3>
                <small class="muted d-block" style="line-height: normal">Termin- & Vermittlungs- <br> verwaltung.</small>
            </div>
            <v-autocomplete
                v-if="user.is_app_admin"
                class="pt-4 pb-2 pl-3 pr-3"
                label="Lehrer wählen"
                hide-details
                dense
                :value="teachersSelectVal()"
                clearable
                @change="switchUser"
                :items="teachersItemized"
                prepend-icon="mdi-account-convert">
            </v-autocomplete>
            <!--<img src="@/assets/images/logo_schuna.png"/>-->
            <v-list nav dense>
                <v-list-item-group>
                    <v-list-item v-for="route in routesFiltered" :key="route.title" :class="route.meta.class">
                        <v-list-item-title :class="{'rl-disabled' : $router.currentRoute.fullPath === route.path}">
                            <router-link class="d-flex router-link" :to="route.path">
                                {{route.meta.title.nav}}
                                <v-spacer></v-spacer>
                                <v-icon v-if="route.meta.icon" color="light">{{route.meta.icon}}</v-icon>
                            </router-link>
                            <small class="muted" v-if="route.meta.sub"><i>{{route.meta.sub}}</i></small>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <v-container v-if="isLoggedIn() && app_already_installed === false && !isDev()" id="install-banner-wrapper">
                <v-banner color="info" dark>
                    Installieren Sie die schuNa App jetzt direkt auf Ihrem Smartphone um eine Verknüpfung auf Ihrem Home-Screen zu erhalten!
                    <template v-slot:actions>
                        <v-btn text @click="install">Installieren</v-btn>
                    </template>
                </v-banner>
            </v-container>
            <div class="sm_router-view" :key="$route.fullPath">
                <router-view name="content" v-if="hasRouterContent"/>
                <router-view v-else/>
            </div>
            <v-snackbar v-model="snackbar">
                {{snackbar_text}}
            </v-snackbar>
        </v-main>

    </v-app>
</template>


<script>
//import AuthService from '@/services/AuthService.js';

//import { isLoggedIn } from '@/services/auth'
//import axios from 'axios';
//import config from '@/config'

import _ from 'lodash'
import store from './store'
import EventBus from './main';
import Vue from 'vue';

//import {router} from "./main.js"

export default {
    name: 'App',
    components: {},
    data: () => ({
        drawer: false,
        title : 'SchuNa',
        body_class : '',
        //enums : {}

        deferredPrompt : null,

        //snackbar_timer_seconds : 2,
        snackbar : false,
        snackbar_text : '',

        teachers : [],

        app_already_installed : null
    }),
    methods: {
        teachersSelectVal () {
            return this.user.sudo_id || -1
        },
        switchUser (user_id) {
            if(!_.isNull(user_id) && !_.isNumber(user_id) && user_id.indexOf('-')===0)
                this.$dialog.alert('Mit der gewählten Lehrkraft ist kein Benutzer verknüpft, daher kann nicht in die Admin-Ansicht für diese Lehrkraft gewechselt werden.', {
                    okText: 'OK',
                })
            else
                EventBus.$emit('switch-user', user_id)
        },
        isLoggedIn() {
            //return isLoggedIn()
            //return true
            return store.getters.isLoggedIn
        },
        async install() {
            this.deferredPrompt.prompt()
        },
        showSnackbar(message, duration = 3) {
            this.snackbar_text = message
            this.snackbar = true
            //const orig_snackbar_timer_seconds = this.snackbar_timer_seconds
            let timer =
                setInterval(() => {
                    //this.snackbar_timer_seconds--
                    duration--
                    //if(this.snackbar_timer_seconds === 0) {
                    if(duration === 0) {
                        clearInterval(timer)
                        //this.snackbar_timer_seconds = orig_snackbar_timer_seconds
                        this.snackbar = false
                    }
                }, 1000)
        }
    },
    mounted () {

        EventBus.$on('showSnackbar', (message, duration = 3) => {
            this.showSnackbar(message, duration)
        })

        //this.$root.globalsReady.then(() => {
        this.$root.teachersReady.then(() => {
            this.teachers = this.$root.teachers
            //this.$set(this, 'teachers', this.$root.teachers)
        })

    },
    created () {

        // Detects if device is on iOS
        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test( userAgent );
        }
        // Detects if device is in standalone mode
        const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode()) {
            this.setState({showInstallMessage: true});
        }

        this.app_already_installed = window.matchMedia('(display-mode: standalone)').matches
        //this.app_already_installed = isInStandaloneMode()


        navigator.serviceWorker.register('/ServiceWorker.js').then(() => {
        //navigator.serviceWorker.register('/google-service-worker.js').then(() => {
            //console.log("Service Worker Registered!")
        })

        window.addEventListener("beforeinstallprompt", (e) => {
            e.preventDefault()
            this.deferredPrompt = e // Stash the event so it can be triggered later.
        })

        window.addEventListener("appinstalled", () => {
            this.deferredPrompt = null
            this.app_already_installed = true
        })

    },
    computed: {
        teachersItemized () {
            let itemizedData = []

            _.each(this.teachers, (teacher) => {
                itemizedData.push({
                    'text' : (teacher.user_id ? '' : '⚠ ' ) + `[${teacher.code}] ${teacher.first_name} ${teacher.last_name}`, //  ${teacher.user_id} ${teacher.id}
                    'value' : teacher.user_id || `-${teacher.id}-${teacher.first_name}-${teacher.last_name}`
                })
            })

            return itemizedData;
        },
        // throw out routes that do not have a 'nav' field (this is used for the dynamic nav generation)
        routesFiltered() {
            return this.$router.options.routes.filter(obj => {
                const disabled = typeof obj.meta.disabled === 'object' && obj.meta.disabled.indexOf('family') !== -1 && this.$root.userInfo.is_family
                return obj.meta.title.nav !== null && !disabled
            })
        },
        dynClass () {
            let classes = {
                dev : this.isDev(),
                'is-mobile' : this.isMobileDevice(),
                'is-desktop' : !this.isMobileDevice()
            }
            classes[this.body_class] = true
            return classes
        },
        hasMultipleRouterViews () {
            return this.$route.matched[0].components.length > 1
        },
        hasRouterNav () {
            return this.$route.matched[0].components.nav !== undefined
        },
        hasRouterContent () {
            return this.$route.matched[0].components.content !== undefined
        },
        user : function () {
            return this.$root.userInfo
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = _.get(to, 'meta.title.browser', '') + ' | SchuNa' || '/';
                this.title = _.get(to,'meta.title.appBar', '')
                this.body_class = to.meta.bodyClass
            }
        },
    }
};
</script>

<style lang="scss">
    @import "assets/scss/cuSTORMS.scss";

    .rl-disabled {
        //pointer-events: none !important;
    }
</style>
