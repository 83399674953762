<template>
    <v-dialog v-model="main_dialog" persistent>
        <v-card>
            <v-card-title class="d-block pb-0" style="line-height: normal; padding-left: 5px; padding-right: 5px">
                <v-col class="pt-0 pb-0">
                    Gewählte Vermittlungen
                    <br>
                    {{['ablehnen', 'annehmen'][action]}}
                </v-col>
            </v-card-title>
            <v-card-text class="pt-0">
                <v-col class="pt-0">
                    <p>
                        Bitte geben Sie das Zeitfenster für den Ersttermin ein.
                    </p>

                    <v-form ref="mpumcform">
                        <!--
                        Version with date, start-time & end-time:
                        https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/7a3c4ded244d4f5c5dec502a9e17ac82f02aa37f/src/components/partials/mass-placement-update-modal.vue#L14
                        -->
                        <v-textarea
                            required
                            :rules="[v => !!v || 'Bitte beschreiben Sie ein Zeitfenster.']"
                            hide-details
                            outlined
                            label="Zeitfenster"
                            placeholder="zB. Montag bis Freitag 12:00 - 16:00 Uhr"
                            v-model="timeframe">
                        </v-textarea>
                    </v-form>
                </v-col>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red darken-1"
                    text
                    @click="main_dialog = false">
                    Abbrechen
                </v-btn>
                <v-btn
                    color="green lighten-1"
                    text
                    @click="validateAndSubmit()">
                    {{count}} {{['ablehnen', 'annehmen'][action]}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
    data () {
        return {
            main_dialog: false,

            timeframe : '',

            action : null, // 0 => ablehnen ; 1 => annehmen
            count : 0 // will be passed - this is the amount of checked placements
        }
    },
    computed : {},
    methods : {
        valid () {
            return this.$refs['mpumcform'].validate()
        },
        getData () {
            return _.pick(this.$data, [
                ['action'], // fields for action 0 (ablehnen)
                ['timeframe', 'action'] // " 1 (annehmen)
            ][this.action])
        },
        setAction(action) {
            this.action = action
        },
        getAction() {
            return this.action
        },
        setData(data) {
            _.merge(this.$data, data)
        },
        show(data) { // ... the main dialog
            this.setData(data)
            this.timeframe = ''
            this.main_dialog = true
        },
        validateAndSubmit (byPassValidation = false) {

            if(byPassValidation || this.$refs['mpumcform'].validate()) {
                this.$dialog
                    .confirm('Sind Sie sicher dass Sie die ' + this.count + ' gewählte(n) Vermittlung(en) <u>' + ['ablehnen', 'annehmen'][this.action] + '</u> wollen?',
                        {
                            okText: 'Ja, ' + ['ablehnen', 'annehmen'][this.action],
                            cancelText: 'Abbrechen',
                        }
                    )
                    .then(dialog => {
                        this.$emit('save', {
                            data : this.getData()
                        })
                        this.main_dialog = false
                    })
            }
            else
                return false
        }
    }
}
</script>
