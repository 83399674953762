<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition">
        <template v-slot:activator="{ on, attrs }">
            <v-btn fab x-small color="red" dark class="float-right mt-1"
               v-bind="attrs"
               v-on="on">
                <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-form ref="delete-form">
                <v-toolbar dark color="red" class="mb-2">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Nachhilfetermin entfernen</v-toolbar-title>
                </v-toolbar>

                <v-container>
                    <v-select
                        label="Löschgrund"
                        hide-details
                        v-model="reason_selected"
                        :items="reasonsItemized"
                        mandatory
                        prepend-icon="mdi-help-circle-outline"
                        required
                        :rules="[v => !!v || 'Bitte einen Löschgrund wählen']">
                    </v-select>

                    <!-- tried to use just one TA for wrong_date & teacher ... lost round about half an hour through the rules and required.. those can not be dynamic -->
                    <v-textarea
                        v-if="reason_selected === cancellationReasons['wrong_date']"
                        v-model="reason_description"
                        hide-details="auto"
                        rows="4"
                        auto-grow
                        full-width
                        label="Anmerkung"
                        prepend-icon="mdi-playlist-edit">
                    </v-textarea>
                    <v-textarea
                        v-if="reason_selected === cancellationReasons['teacher']"
                        v-model="reason_description"
                        hide-details="auto"
                        rows="4"
                        auto-grow
                        full-width
                        label="Anmerkung *"
                        required
                        :rules="[v => !!v || 'Bitte geben Sie einen Grund an']"
                        prepend-icon="mdi-playlist-edit">
                    </v-textarea>

                    <template v-if="(reason_selected === cancellationReasons['wrong_date'] || reason_selected === -1) && isAppointmentSeries">
                        <v-select
                            class="mt-5"
                            dense
                            hide-details
                            placeholder="Vom löschen betroffen"
                            v-model="cancellation_scope"
                            :items="[{'text' : 'Nur dieser', 'value' : 1}, {'text' : 'Alle zukünftigen im gleichen Rhythmus', 'value' : 2}]"
                            mandatory
                            prepend-icon="mdi-counter"
                            required
                            :rules="[v => !!v || 'Bitte wählen']">
                        </v-select>
                    </template>

                    <template v-if="reason_selected === cancellationReasons['teacher']">
                        <div class="d-flex">
                            <v-icon color="red">mdi-alert-octagram-outline</v-icon>
                            <b style="padding-left: 10px;" class="text-customer-red mt-5 d-block">Bitte vergessen Sie nicht die Eltern <br> über die Absage zu informieren!</b>
                        </div>
                    </template>

                    <template v-if="reason_selected === cancellationReasons['parents']">
                        <div class="mt-2"></div>

                        <span class="v-card-caption">Ist die Absage fristgerecht? *</span>
                        <v-card class="mx-auto px-2" outlined>
                            <v-radio-group v-model="cancellation_on_time" row>
                                <v-radio label="Ja"
                                    :value="1">
                                </v-radio>
                                <v-radio label="Nein"
                                    :value="0">
                                </v-radio>
                            </v-radio-group>
                        </v-card>

                        <template v-if="cancellation_on_time === 0">
                            <span class="v-card-caption">Wann wurde der Termin abgesagt? *</span>
                            <v-card class="mx-auto py-2 px-2" outlined>
                                <v-dialog
                                    ref="dialog_cancellation_date"
                                    v-model="dialog_cancellation_date"
                                    :return-value.sync="cancellation_date"
                                    persistent>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            hide-details="auto"
                                            label="Datum"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            required
                                            :value="dateFormatted"
                                            :rules="[v => !!v || 'Bitte Datum wählen.']">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        locale="de-de"
                                        v-model="cancellation_date"
                                        scrollable>
                                        <div style="width: 100%">
                                            <div class="d-flex">
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="dialog_cancellation_date = false">
                                                    Abbrechen
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.dialog_cancellation_date.save(cancellation_date)">
                                                    Speichern
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-date-picker>
                                </v-dialog>

                                <v-dialog
                                    ref="dialog_cancellation_time"
                                    v-model="dialog_cancellation_time"
                                    :return-value.sync="cancellation_time"
                                    persistent>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            hide-details="auto"
                                            v-model="cancellation_time"
                                            label="Zeit"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            required
                                            :rules="[v => !!v || 'Bitte Zeit wählen.']">
                                        </v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="dialog_cancellation_time"
                                        v-model="cancellation_time"
                                        full-width
                                        format="24hr">
                                        <div style="width: 100%">
                                            <div class="d-flex">
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="dialog_cancellation_time = false">
                                                    Abbrechen
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.dialog_cancellation_time.save(cancellation_time)">
                                                    Speichern
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-time-picker>
                                </v-dialog>

                                <div class="d-flex mt-5">
                                    <v-spacer></v-spacer>
                                    <v-btn x-small @click="setCancellationDateTimeToNow()">Jetzt</v-btn>
                                </div>
                            </v-card>

                            <v-checkbox
                                required
                                color="primary"
                                v-model="schuna_informed"
                                label="schuNa wurde informiert">
                            </v-checkbox>

                            <v-select
                                dense
                                hide-details
                                placeholder="Absageform"
                                v-model="cancellation_form_selected"
                                :items="[{'text' : 'Schriftlich', 'value' : 1}, {'text' : 'Mündlich/Telefonisch', 'value' : 4}, {'text' : 'Keine Rückmeldung', 'value' : 2}, {'text' : 'Sonstige', 'value' : 3}]"
                                mandatory
                                prepend-icon="mdi-file-cancel-outline"
                                required
                                :rules="[v => !!v || 'Bitte wählen']">
                            </v-select>
                            <v-textarea
                                v-if="cancellation_form_selected === 3"
                                v-model="cancellation_form_description"
                                hide-details
                                rows="4"
                                auto-grow
                                full-width
                                label="Grund für die Absage *"
                                prepend-icon="mdi-playlist-edit"
                                required
                                :rules="[v => !!v || 'Bitte Grund angeben']">
                            </v-textarea>

                            <v-file-input
                                v-model="proof_files"
                                accept="image/jpeg, application/pdf"
                                label="Nachweis"
                                placeholder="Nachweis (*.pdf / *.jpg)"
                                counter
                                multiple
                                show-size
                                prepend-icon="mdi-file-upload-outline">
                            </v-file-input>
                        </template>
                    </template>

                    <v-btn :dark="canProceed && !deletion_running" color="red" :disabled="!canProceed || deletion_running" block class="mt-5" large @click="validate">
                        <template v-if="deletion_running">
                            <v-progress-circular
                                size="15"
                                width="2"
                                class="mr-2"
                                indeterminate
                                color="primary">
                            </v-progress-circular>
                            Lösche ...
                        </template>
                        <template v-else>
                            {{reason_selected === cancellationReasons['parents'] && cancellation_on_time === 0 ? 'Absageantrag senden' : 'Termin entfernen'}}
                        </template>
                    </v-btn>
                </v-container>
            </v-form>
        </v-card>
        <v-snackbar v-model="success_snackbar">
            <div class="d-flex">
                Der Termin wurde gelöscht.
                <v-spacer></v-spacer>
                {{timer_seconds}}
            </div>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import config from '@/config'
import moment from 'moment';

export default {
    data () {
        return {
            dialog: false, // the main dialog window its self

            valid : false,

            dialog_cancellation_date : false,
            dialog_cancellation_time : false,

            reason_selected : null,
            reason_description : '',

            cancellation_scope : null,

            cancellation_date : null,
            cancellation_time : null,

            cancellation_on_time : null,

            schuna_informed: false,

            cancellation_form_selected : null,
            cancellation_form_description : '', // ?? nicht sicher was dieses feld angeht...

            proof_files : [],

            timer_seconds : 3,
            success_snackbar : false,

            deletion_running : false
        }
    },
    computed : {
        appointment () {
            return this.$parent.$parent.$data.appointment
        },
        canProceed () {
            switch(this.reason_selected) {
                case (this.cancellationReasons['wrong_date']):
                    // internal note: the textfield is OPTIONAL with the current implementation!
                    if(!this.isAppointmentSeries)
                        return true
                    else
                        return this.cancellation_scope !== null
                case (this.cancellationReasons['teacher']):
                    return this.reason_description.trim() !== ''
                case (this.cancellationReasons['parents']):
                    if(this.cancellation_on_time === 0) {
                        var cancellation_form_okay = this.cancellation_form_selected !== null
                        if(this.cancellation_form_selected === 3)
                            cancellation_form_okay = cancellation_form_okay && (this.cancellation_form_description.trim() !== '')
                        return this.cancellation_time !== null && this.schuna_informed && cancellation_form_okay && this.proof_files !== null
                    }
                    else if(this.cancellation_on_time === 1) {
                        return true
                    }
                    else
                        return false
                case (-1): // app admin
                    return true
                default:
                    return false
            }
        },
        cancellationReasons () {
            return _.get(this.$root, 'enums.byKey.appointment_cancellation_reasons', {})
        },
        repetitivities () {
            return _.get(this.$root, 'enums.byKey.appointment_repetitivities', {})
        },
        isAppointmentSeries () {
            return this.appointment.repetitivity !== this.repetitivities['once']
        },
        reasonsItemized () {
            let aData = []

            if(this.$root.userInfo.is_app_admin) {
                aData.push({
                    'text': 'Termin endgültig löschen',
                    'value': -1
                })
            }

            if(!_.isEmpty(this.cancellationReasons)) {
                _.forEach(this.cancellationReasons, (key, label) => {
                    aData.push({
                        'text': this.getResolvedAppointmentCancellationReasonLabel(key),
                        'value': key
                    })
                })
            }
            return aData
        },
        dateFormatted () { // TODO c&p from termin.vue
            return this.cancellation_date ? moment(this.cancellation_date).format(this.moment_date_format) : ''
        },
    },
    methods : {
        getAppointmentId () { return this.$parent.$parent.getAppointmentId() },
        setCancellationDateTimeToNow () {
            this.cancellation_date = moment().format('YYYY-MM-DD')
            this.cancellation_time = moment().format(this.moment_time_format_HM)
        },
        validate () {

            const checkAndSubmit = () => {
                if(this.$refs['delete-form'].validate()) {

                    this.deletion_running = true

                    let postData = new FormData();

                    // add all files for the upload
                    for (let file of this.proof_files)
                        postData.append('proof_files', file, file.name)

                    // add simple fields from the form
                    _.forEach(_.pick(this.$data, [
                        'dialog_cancellation_time',
                        'reason_selected',
                        'reason_description',
                        'cancellation_scope',
                        'cancellation_date',
                        'cancellation_time',
                        'cancellation_on_time',
                        'schuna_informed',
                        'cancellation_form_selected',
                        'cancellation_form_description'
                    ]), (field_val, field_name) => postData.append(field_name, field_val))

                    axios.post(`${config.backend_APP_api_url}/appointments/delete/` + this.getAppointmentId(), postData).then((res) => {

                        let appointment = res.data.appointment

                        this.$emit('deletion-done', appointment)
                        this.dialog = false
                        this.deletion_running = false
                        this.showSnackbar('Der Termin wurde entfernt.', 2)

                    })
                }
            }

            /*
             * Version with modal depending on the cancellation_scope:
             * https://github.com/STORMS-SOFTWARE/schuna-mobile-app-vue/blob/db72d17afeff54195ed36f58ad7872feb03518e8/src/components/partials/delete-appointment-modal.vue#L326
             */
            // "calculate" the correct cancellation form through different factors
            const cancellation_scope = (this.reason_selected === this.cancellationReasons['wrong_date'] && this.cancellation_scope === 2) ? 1 : 0

            this.$dialog
                .confirm(
                    [
                        'Sind Sie sicher, dass Sie diesen Termine löschen möchten?',
                        'Sind Sie sicher, dass Sie alle zukünftigen Termine der Serie löschen möchten?'
                    ][cancellation_scope],
                    {
                        okText: [
                            'Ja, Termin löschen',
                            'Ja, alle löschen'
                        ][cancellation_scope],
                        cancelText: 'Abbrechen',
                    }
                )
                .then(dialog => {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.dialog = true // restore the vuetify dialog because however the actual confirm dialog keeps closing the vuetify dialog as we use it's buttons
                        }, 100)
                    })
                    checkAndSubmit()
                })
                .catch(() => {
                    this.$nextTick(() => {
                        this.dialog = true
                    })
                })

        },
    }
}
</script>
